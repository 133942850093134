<div style="margin-bottom:10px;">
  <h1>Federal and State Topic Analysis</h1>
</div>

<div class="search-well row" >
  <div class="row" >
    <div class="col-md-2 search-col">
      <a class="btn-default btn btn-block hero-green" (click)="toggleAllCategories()">{{toggleCategoryButtonLabel}} Categories</a> &nbsp;
    </div>
    <div class="col-md-2 search-col" >
      <a class="btn-default btn btn-block hero-blue" (click)="toggleAtoZ()">{{toggleAtoZButtonLabel}} Topics A to Z</a>
    </div>
    <div class="col-md-5 search-col" style="vertical-align:top;">
      <div class="tag-control">
        <tag-input [(ngModel)]='tags' theme="minimal" secondaryPlaceholder="Search Topics" placeholder="Search Topics" id="tagSelector" [(inputText)]="searchInput"
                   (onRemove)="itemUpdated()"
                   (onAdd)="itemUpdated()">
 
        </tag-input>
      </div>
    </div>
    <div class="col-md-3 search-col" >
      <div class="row" >
        <div class="col-md-6" >
          <a class="btn-default btn btn-block hero-orange" (click)="search()">Search&nbsp;&nbsp;</a>
        </div>
        <div class="col-md-6">
          <a class="btn-default btn btn-block hero-grey" (click)="clearSearch()">Clear Search</a>
        </div>
      </div>
    </div>
  </div>
</div>

<br />

<div class="row" id="aToZPanel" *ngIf="aToZcategoryTopics != null">
  <div>
    <h3>{{searchResults}}</h3>
  </div>
  <div class="col-lg-3">
    <div *ngFor="let item of aToZcategoryTopics | sortBy : 'TopicName'; let i = index">
      <div *ngIf="aToZcategoryTopics.length < 20 || i < (aToZcategoryTopics.length / 4)">
        <div *ngIf="displayAtoZLabel(aToZcategoryTopics[i].TopicName.charAt(0))" class="a-to-z-char-label">{{aToZLabel}}</div>
        <a class="link-no-color" (click)="goToAnalysisURL(aToZcategoryTopics[i])">{{aToZcategoryTopics[i].TopicName}}</a>
      </div>
    </div>
  </div>
  <div class="col-lg-3">
    <div *ngFor="let item of aToZcategoryTopics | sortBy : 'TopicName'; let i = index">
      <div *ngIf="aToZcategoryTopics.length >= 20 && i >= (aToZcategoryTopics.length / 4) && i < ((aToZcategoryTopics.length / 4) * 2)">
        <div *ngIf="displayAtoZLabel(aToZcategoryTopics[i].TopicName.charAt(0))" class="a-to-z-char-label">{{aToZLabel}}</div>
        <a class="link-no-color" (click)="goToAnalysisURL(aToZcategoryTopics[i])">{{aToZcategoryTopics[i].TopicName}}</a>
      </div>
    </div>
  </div>
  <div class="col-lg-3">
    <div *ngFor="let item of aToZcategoryTopics | sortBy : 'TopicName'; let i = index">
      <div *ngIf="aToZcategoryTopics.length >= 20 && i >= ((aToZcategoryTopics.length / 4) * 2) && i < ((aToZcategoryTopics.length / 4) * 3)">
        <div *ngIf="displayAtoZLabel(aToZcategoryTopics[i].TopicName.charAt(0))" class="a-to-z-char-label">{{aToZLabel}}</div>
        <a class="link-no-color" (click)="goToAnalysisURL(aToZcategoryTopics[i])">{{aToZcategoryTopics[i].TopicName}}</a>
      </div>
    </div>
  </div>
  <div class="col-lg-3">
    <div *ngFor="let item of aToZcategoryTopics | sortBy : 'TopicName'; let i = index">
      <div *ngIf="aToZcategoryTopics.length >= 20 && i >= ((aToZcategoryTopics.length / 4) * 3) && i < aToZcategoryTopics.length">
        <div *ngIf="displayAtoZLabel(aToZcategoryTopics[i].TopicName.charAt(0))" class="a-to-z-char-label">{{aToZLabel}}</div>
        <a class="link-no-color" (click)="goToAnalysisURL(aToZcategoryTopics[i])">{{aToZcategoryTopics[i].TopicName}}</a>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="selectedAnalysisURL != null" style="padding-bottom:20px;">
  <div class="col-md-12">
    <div class="text-center">
      <a class="btn-default btn hero-green" style="width:100px;" (click)="next()">Next</a>
    </div>
  </div>
</div>


<div *ngIf="categoryTopics != null" >
  <div class="panel-group" id="accordion">
    <div *ngFor="let item of categoryTopics | groupBy:'CategoryName'; let c = index">

      <a href="#collapse{{c}}" data-toggle="collapse" data-parent="#accordion" (click)="toggleClass(c)" class="link toggle-header">
        <div class="new-panel body-content-label">
          <span class="fa fa-plus plus-minus" id="plusMinus_{{c}}"></span> {{item.key}}
        </div>
      </a>

      <div id="collapse{{c}}" class="panel-collapse collapse collapsable-content toggle" style="height:100% !important;">
        <div *ngFor="let subItem of item.value | sortBy : 'key'; let i = index" style="text-align:left; margin-left:10px;">
          <div *ngIf="i % 3 == 0" class="row">
            <div class="col-lg-4">
              <a class="link-no-color" (click)="goToAnalysisURL(item.value[i])"> {{item.value[i].TopicName}}</a>
            </div>
            <div *ngIf="item.value.length > i + 1" class="col-lg-4">
              <a class="link-no-color" (click)="goToAnalysisURL(item.value[i+1])"> {{item.value[i+1].TopicName}}</a>
            </div>
            <div *ngIf="item.value.length > i + 2" class="col-lg-4">
              <a class="link-no-color" (click)="goToAnalysisURL(item.value[i+2])"> {{item.value[i+2].TopicName}}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="toggle-seperator">
        &nbsp;
      </div>

    </div>
  </div>

  <div class="row" *ngIf="selectedAnalysisURL != null">
    <div class="col-md-12">
      <div class="text-center">
        <a class="btn-default btn hero-green" style="width:100px;" (click)="next()">Next</a>
      </div>
    </div>
  </div>

</div>
