import { Component, Provider, ElementRef, OnInit, Input, Compiler, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { HrHeroService } from '../../shared/services/hr-hero.service'
import { Information } from '../../shared/types/information.type'

import { SearchTerms } from '../../shared/types/search-terms'
import { CategoryTopic } from '../../shared/types/categoryTopic.type'

import { MembershipDataService } from '../../shared/services/membership.data.service'

import  'jquery';


@Component({
  templateUrl: './compliance-topics.html',
  styleUrls: ['./compliance-topics.css']
})

export class ComplianceTopicsComponent {

  tags: SearchTerms[];
  categoryTopics: CategoryTopic[];
  aToZcategoryTopics: CategoryTopic[];

  toggleCategoryButtonLabel: string = "Expand";
  toggleAtoZButtonLabel: string = "Show";
  aToZLabel = "";
  searchResultsaToZLabel = "";
  searchResults: string = "";
  selectedAnalysisURL: string = null;
  searchInput: string = "";

  constructor(

    public _route: ActivatedRoute,
    public _router: Router,
    private _heroService: HrHeroService,
    private _location: Location,
    private _mbService: MembershipDataService

  ) { }

  ngOnInit() {
    this.getCategoryTopics();
    $('#aToZPanel').hide();
  }

  toggleClass(index) {
    if ($('#plusMinus_' + index).hasClass('fa-plus')) {
      $('#plusMinus_' + index).removeClass('fa-plus');
      $('#plusMinus_' + index).addClass('fa-minus');
    } else if ($('#plusMinus_' + index).hasClass('fa-minus')) {
      $('#plusMinus_' + index).removeClass('fa-minus');
      $('#plusMinus_' + index).addClass('fa-plus');
    }
  }

  toggleAllCategories() {

    if (this.toggleCategoryButtonLabel == "Expand") {
      this.toggleCategoryButtonLabel = "Collapse";
      $('.panel-collapse').addClass('in');
    
      $('.plus-minus').addClass('fa-minus');
      $('.plus-minus').removeClass('fa-plus');

    } else if (this.toggleCategoryButtonLabel == "Collapse") {
      this.toggleCategoryButtonLabel = "Expand";
      $('.panel-collapse').removeClass('in');
   
      $('.plus-minus').addClass('fa-plus');
      $('.plus-minus').removeClass('fa-minus');

    }
  }

  toggleAtoZ() {
    if (this.toggleAtoZButtonLabel == "Show") {

      this.toggleAtoZButtonLabel = "Hide";
      this.aToZcategoryTopics = this.categoryTopics;
      this.searchResults = "";
      this.tags = null;
      $('#aToZPanel').show(500);

    } else {

      this.toggleAtoZButtonLabel = "Show";
      this.aToZcategoryTopics = null;
      this.searchResults = "";
      this.tags = null;
      $('#aToZPanel').hide(500);
      window.scrollTo(0, 0);

    }
  }

  toggle(categoryName) {
    $('.toggle').hide(500).not('#' + categoryName);
    $('#' + categoryName).toggle(500);
  }

  displayAtoZLabel(char) {

    if (char != this.aToZLabel) {
      this.aToZLabel = char;

      return true;
    }

    return false;
  }

  getCategoryTopics() {

    this._heroService.getCategoryTopics(16).then(ct => {

      if (ct != null) {

        this.categoryTopics = ct;
      } 

    });
  }

  search() {

    //tag any text that has been typed but not entered and thus not tagged
    if ($(".tag-class").val() != null &&
      $(".tag-class").val() != "" &&
      $(".tag-class").val() != undefined)
    {
      if (this.tags == null || this.tags == undefined) {
        this.tags = new Array<SearchTerms>();
      }

      let model = new SearchTerms();

      model.display = $(".tag-class").val().toString();
      model.value = $(".tag-class").val().toString();

      this.tags.push(model);

      $(".tag-class").val("")

      this.itemUpdated();

    }

  }

  itemUpdated() {

    let searcText = '';

    searcText = this.tags.map(function (e) {
      return e.value;
    }).join(', ');

    if (searcText == '') searcText = 'null';

    this._heroService.getTopicsBySearchTerm(null, 16, searcText).then(searchItems => {

      if (searchItems != null && searchItems.length > 0) {

        this.aToZLabel = "";
        this.aToZcategoryTopics = searchItems;
        this.searchResults = "Search Results";
        this.toggleAtoZButtonLabel = "Show";

      } else {
        this.aToZLabel = "";
        this.aToZcategoryTopics = searchItems;
        this.searchResults = "No Results";
        this.toggleAtoZButtonLabel = "Show";

        if (searcText == 'null') {
          this.aToZcategoryTopics = null;
          this.searchResults = "";
        }
      }

    });

  }

  setAnalysisURL(item: CategoryTopic) {
    this.selectedAnalysisURL = "/analysis/" + item.CategoryURL + "/" + item.TopicURL;
  }

  goToAnalysisURL(item: CategoryTopic) {
    this._router.navigate(["/analysis/" + item.CategoryURL + "/" + item.TopicURL]);
  }

  next() {
    this._router.navigate([this.selectedAnalysisURL]);
  }

  clearSearch() {
    this.aToZLabel = "";
    this.aToZcategoryTopics = null;
    this.searchResults = "";
    this.toggleAtoZButtonLabel = "Show";
    this.tags = null;
    $('#aToZPanel').hide(500);

    this.searchInput = null;
  }

}
