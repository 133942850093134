<div *ngIf="loading" class="row" style="text-align:center;">
  <img src="../assets/images/loader.gif" />
</div>

<div class="row" style="padding-bottom:10px;">
  <div class="col-md-10" style="text-align:left;">
    <h1 *ngIf="!loading">Templates & Guidance</h1>
  </div>
</div>

<div class="row" [class.is-hidden]="loading">
  <div class="col-lg-3" *ngIf="resources != null" style="margin-bottom:20px;">
    <div class="clear-all"><a (click)="clearAll()">Clear all filters</a></div>
    <div class="outer-filter-panel">

      <div class="filter-panel  body-content-label" data-target="#category">
        Categories<span data-toggle="collapse" (click)="categoryCollapsed = !categoryCollapsed" data-target="#category" style="float: right;cursor: pointer;">{{ categoryCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="category" class="collapse in filterSegment" >
        <table class="table">
          <tbody>
            <tr *ngFor="let item of resources.Categories">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterCategory(item.CategoryId, $event.target.checked, item.CategoryName, true, true)" [checked]="item.Checked">{{item.CategoryName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>          
        </table>
      </div>

      <div class="filter-panel  body-content-label" data-target="#topic">
        Topics<span data-toggle="collapse" (click)="topicsCollapsed = !topicsCollapsed" data-target="#topic" style="float: right;cursor: pointer;">{{ topicsCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="topic" class="collapse in filterSegment" >
        <table class="table">
          <tbody>
            <tr *ngFor="let item of resources.Topics">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterTopic(item.TopicId, $event.target.checked, item.TopicName, true, true)" [checked]="item.Checked">{{item.TopicName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
        </tbody>          
        </table>
      </div>

      <div class="filter-panel  body-content-label" data-target="#docTypes">
        Document Types<span data-toggle="collapse" (click)="docsCollapsed = !docsCollapsed" data-target="#docTypes" style="float: right;cursor: pointer;">{{ docsCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="docTypes" class="collapse in filterSegment" >
        <table class="table">
          <tbody>
            <tr *ngFor="let item of resources.DocumentTypes">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterDocumentType(item.InformationTypeId, $event.target.checked, item.InformationTypeName,true, true, true)" [checked]="item.Checked">{{item.InformationTypeName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>          
        </table>
      </div>

      <div class="filter-panel  body-content-label" data-target="#states">
        States<span data-toggle="collapse" (click)="statesCollapsed = !statesCollapsed" data-target="#states" style="float: right;cursor: pointer;">{{ statesCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="states" class="collapse in filterSegment" >
        <table class="table">
          <tbody>
            <tr *ngFor="let item of resources.Jurisdictions">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterState(item.JurisdictionId, $event.target.checked, item.JurisdictionName,true, true)" [checked]="item.Checked">{{item.JurisdictionName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>          
        </table>
      </div>
    </div>
  </div>

  <div class="col-lg-9">
    <div class="ar-tab-container">
      <div class="tag" *ngFor="let tag of tags" (click)="clearTag(tag)">
        <div class="inline-text">{{tag.Name}}</div>
        <div class="delete-button" style="height: 100%">
          <svg _ngcontent-c17="" height="16px" viewBox="0 0 32 32" width="16px">
            <path _ngcontent-c17="" d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313"></path>
          </svg>
        </div>
      </div>
    </div>

    <div>
      <div class="white-well">
          <table datatable class="table table-striped" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
          <thead class="thead">
            <tr> 
              <th>
                <a class="text-nowrap" style="cursor:pointer">Document Type <span></span></a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Topic <span></span></a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer" style="min-width:500px;">Title <span></span></a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">State <span></span></a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of resourceInformation">
              <td>{{item.InformationTypeName}}</td>
              <td>{{item.TopicDesc}}</td>
              <td><a (click)="openDetails(item)" style="cursor:pointer;">{{item.Title}}</a></td>
              <td>{{item.JurisdictionName}}</td>
            </tr>
          </tbody>
        </table> 
      </div>
    </div>
  </div>
</div>
