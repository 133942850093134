import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MembershipDataService } from '../../shared/services/membership.data.service';
import { HrHeroService } from '../../shared/services/hr-hero.service';
import { Subject} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { CustomWidget } from '../../shared/types/custom-widget.type';

@Component({
  selector: 'custom-settings',
  templateUrl: './custom-settings.component.html',
  styles: ['.thead { background-color: #1a8cd8; color: white;}']
})

export class CustomSettingsComponent implements OnDestroy, OnInit{
 
  useParent: boolean = false;
  customWidgets: CustomWidget[]=[];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  hasAccess: boolean = false;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(
    private _mbService: MembershipDataService,
    private _heroService: HrHeroService
  ) {
      
    
  }

  ngOnInit() {
   
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      ordering: false,
      info: false,
      language : {
        lengthMenu: '_MENU_',
        emptyTable: "No data to display",
        zeroRecords:"No data to display",
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
      dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
    };
    
    this.hasAccess = this._mbService.getVisibility('Custom Widgets');
    let parentUserProfile = this._mbService.parentUserProfile;
    if(parentUserProfile != null && parentUserProfile != undefined && parentUserProfile.Tools != null && parentUserProfile.Tools != undefined){
      
      if(parentUserProfile.DashboardLock){
        this.useParent = true;   
      }
    }

    this._heroService.getMyCustomWidgets().then(result => {
      if (result.length > 0) {
        this.customWidgets = result;
        this.customWidgets.sort(
          (t1,t2) => 
            (t1.Name.toLowerCase() > t2.Name.toLowerCase() ? 
              1 : 
              (t2.Name.toLowerCase() > t1.Name.toLowerCase() ? 
                -1 : 
                0
              )
            )
        );
      } else {
        this.customWidgets = null;
      }



    this.rerender();

    });
    
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    if(this.dtElement && this.dtElement.dtInstance)
    {           
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });  
        
      }  
      else if (this.dtElement)
      {      
          this.dtTrigger.next();     
      }
    }
}
