<div class="tool-image-container"><img class="tool-image" src="../../../assets/images/slcb.jpg" /></div>

<div class="tool-content">
  <div class="nav-sub-link">State Law Chart Builder</div>
  <div class="blue-tool-line-container">
    <div class="blue-tool-line"></div>
  </div>
  <div class="body-content-italic">Compare and contrast employer rights and obligations under state laws and regulations</div>
</div>

<div style="padding-top:25px;">
  <a class="btn-blue-sm" [routerLink]="['/state-law-chart-builder']" *ngIf="hasApplication">Get Started</a>
  <a class="btn-blue-sm" (click)="openModal(modal)" *ngIf=" !hasApplication"><i class="fa fa-lock"></i>&nbsp;&nbsp;Get Started</a>
</div>

<!--bookmark modal-->
<ng-template cssClass="modal-xl" #modal>
  <div>
    <div class="modal-header modal-header-hr">
      Limited Access
    </div>
    <div class="modal-body">
      <div class="body-content">
        <div class="row">
          <div class="col-lg-4">
            <div class="wistia_responsive_padding" style="padding:79% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/p3aq4uyxo4?videoFoam=true" title="Wistia video player" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div>
            <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
          </div>
          <div class="col-lg-8">
            <h1>
              Full access to the State Law Chart Builder is reserved for HR Hero subscribers.
            </h1>

            <div>
              <a href="http://store.blr.com/hrhero" target="_blank">Upgrade now</a> or <a href="http://interactive.blr.com/hrhero-demo-request" target="_blank">request a demo</a> for immediate access to clear side-by-side federal and state comparison charts in over 75 areas of employee management.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!hasApplication">
    <div class="modal-header modal-header-hr">
      State Law Chart Builder
    </div>
    <div class="modal-body">
      <div class="body-content">
        <div>
          We apologize, it appears your account does not have access to this tool. Please contact technical support at (800) 727-5257 or techsupport@blr.com for assistance.
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="closeModal()">Close</button>
  </div>
</ng-template>
