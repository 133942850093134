
<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="type == '404'">
        <h1>
          404 Not Found
        </h1>
        <div class="body-content">
          The  page or file you requested could not be found in hero.blr.com. It may have been moved to a new location or possibly removed from  the site.
        </div>
        <h2>Contact Us</h2>
        <div class="body-content">If  you are still having problems locating the information that you need after  trying the above options, you can visit our <a href="http://www.blr.com/about/contact/sitefeedback">site feedback</a> page, or even <a href="http://www.blr.com/About/Contact">contact us</a> and let us know how we can help you.</div>

        <h2>Report a Page Error</h2>
        <div class="body-content">To  report a page error or a bad or missing link, please use our <a href="http://www.blr.com/about/contact/sitefeedback">site feedback</a> page.</div>

      </div>
      <div *ngIf="type == '500'">
        <h1>
          This product is temporarily unavailable
        </h1>
        <div class="body-content">
          We apologize for the inconvenience, but HR Hero is experiencing a brief interruption in service. Our team is actively working to restore normal operations as quickly as possible. We apologize for any inconvenience this may cause and thank you for your patience!
        </div>
        <div class="body-content">
          If you have any urgent queries or concerns, please do not hesitate to reach out to our dedicated support team at <a href="mailto: TechSupport@BLR.com"> TechSupport@BLR.com.</a>
        </div>
      </div>
    </div>
  </div>
</div>

