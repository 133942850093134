<div style="text-align:center;" *ngIf="newsLoading" class="imgloader">
  <span><img style="margin-left:5px; height:60px;width:60px;" src="/assets/images/loading-dots.gif"/></span>
</div>
<div *ngIf="!details && newsArticles != null">
  <h1>News</h1>
  <div class="white-well">
    <div *ngFor="let article of newsArticles">

      <a (click)="changeArticle(article)" class="link" style="font-weight:bold;">{{article.Title}}</a>
      <div>{{article.PubDate  | date: 'EEEE, MMMM d, y'}}</div><br />
      <div  [innerHTML]="article.Teaser"></div>

      <div style="margin-top:10px; margin-bottom:15px;">
        <a (click)="changeArticle(article)" class="link">Read More</a>
      </div>

      <hr />
    </div>
  </div>
</div>

<div class="row" *ngIf="details && selectedArticle != null">
  <div class="col-lg-8">
    <h1>{{selectedArticle.Title}}</h1>
    <div style="font-size: 12px;">{{selectedArticle.PubDate  | date: 'EEEE, MMMM d, y'}}
      <div class="pull-right" style="margin-top:10px;">
        <bookmarks [informationId]="selectedArticle.InformationId" [informationTypeId]="selectedArticle.InformationTypeId" [categoryId]="selectedArticle.CategoryId" [topicId]="selectedArticle.TopicId" [bookmarkTitle]="selectedArticle.Title"></bookmarks>
      </div>
    </div>
    <br />
    <div style="font-weight:bold;" *ngIf="selectedArticle.Author != null">By {{selectedArticle.Author}}<br /><br /></div>
  </div>
  <div class="col-lg-4">

  </div>
</div>

<div class="row" *ngIf="details && selectedArticle != null">
  <div class="col-lg-8">
    <div [innerHTML]="selectedArticle.Body | safeHtml"></div>

    <div style="margin-bottom:10px;padding-top:20px">
      <a (click)="previous()" class="btn-white" style="margin-right:10px;">Previous</a>
      <a (click)="next()" class="btn-white">Next</a>
    </div>

  </div>
  <div class="col-lg-4">

    <div id="articleScroll">
      <span *ngFor="let item of newsArticles" [id]="item.InformationId" class="list-item">
        <a (click)="changeArticle(item)" class="link">
          <table>
            <tr>
              <td *ngIf="selectedArticle != null && selectedArticle.InformationId == item.InformationId"><img src="../../assets/images/arrow-left-selected.png" /></td>
              <td style="width:30px; padding-left:4px; padding-right:6px;" *ngIf="selectedArticle != null && selectedArticle.InformationId != item.InformationId"><img src="../../assets/images/arrow-left-gray.png" /></td>
              <td style="width:30px; padding-left:4px; padding-right:6px;" *ngIf="selectedArticle == null"><img src="../../assets/images/arrow-left-gray.png" /></td>
              <td style="width:230px;" class="body-content link-no-color">{{item.Title}}</td>
              <td><img [src]="item.Thumbnail" height="90" width="90" style="float:right; width:90px;" /></td>
            </tr>
          </table>
        </a>
      </span>
    </div>

  </div>
</div>
<div *ngIf="selectedArticle != null" style="display:none;">Unified ID: {{selectedArticle.SourceInfoId}}</div>
