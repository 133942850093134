<h1>New Training Materials</h1>
<div *ngIf="information != null" class="outer-panel">
  <div *ngFor="let item of information | groupBy:'InformationTypeName'">

    <div class="new-panel body-content-label">
      {{item.key}}
    </div>

    <table class="table">
      <tbody>
        <tr *ngFor="let subItem of item.value">
          <td><a style="cursor:pointer;" (click)="selectInformation(subItem)" class="link">{{subItem.Title}}</a></td>
        </tr>
      </tbody>
    </table>

  </div>
</div>
