
<div class="container" >
  <div class="row">
    <div class="col-sm-12">
      <div >
        <h1>
          <i  class="fa fa-ban" style="color:red"></i> Unauthorized
        </h1>
        <div class="body-content">
          We’re sorry, your account does not have access to this page.  This may be due to subscription level or configuration of your account.
        </div>
        <h3>If you feel this is in error, please contact us:</h3>
        <div class="body-content">

          <label class="body-content-label">Email: </label><a href="mailto:service@blr.com">service@blr.com</a><br />
          <label class="body-content-label">Phone: </label><a href="tel:1-800-727-5257">1-800-727-5257 ext. 2301</a>

        </div>
      </div>
    </div>
  </div>
</div>
