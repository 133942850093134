import { Component, TemplateRef, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
//import { BsModalComponent } from 'ng2-bs3-modal';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'
import { hrHeroConfig } from '../../../../../src/environments/environment'

@Component({
  selector: 'widget-tools-flsa',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './widget-tools-flsa.html'
})

export class WidgetToolsFLSA {
  isLimitedAccess: boolean;

  modal: BsModalRef;
  constructor(private _mbService: MembershipDataService, private modalService: BsModalService) { }

  ngOnInit() {

    this.isLimitedAccess = this._mbService.isLimitedAccess();
  }

  navigate() {
    if (this._mbService.userIsHRAuthenticated) {
      window.open("http://stagingflsa.smarthrmanager.com/CheckSSOlogin.aspx", '_blank');
    } else {
      location.href = "/login";
    }
  }

  click() {

    //window.open("https://flsa.smarthrmanager.com/Login.aspx", '_blank');
    window.open(hrHeroConfig.flsaURL, '_blank');

  }

    openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.show(template);
  }

  closeModal()  {
    this.modal.hide();
  }
}

