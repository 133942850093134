import { Component, TemplateRef } from '@angular/core';
import { MembershipDataService } from '../shared/services/membership.data.service'
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'hotline',
  templateUrl: './hotline.component.html',
  styleUrls: ['./hotline.component.css']
})

export class HotlineComponent {
  isLimitedAccess: boolean;
  modal: BsModalRef;
  constructor(private _mbService: MembershipDataService, private modalService: BsModalService ) { }

  ngOnInit() {
    this.isLimitedAccess = this._mbService.isLimitedAccess();

    //var file = "../assets/Scripts/zenDesk.js";
    //// DOM: Create the script element
    //var jsElm = document.createElement("script");
    //// set the type attribute
    //jsElm.type = "application/javascript";
    //// make the script element load file
    //jsElm.src = file;
    //// finally insert the element to the body element in order to load the script
    //document.body.appendChild(jsElm);
  }

  openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.show(template);
  }
  closeModal()  {
    this.modal.hide();
  }

}
