  <div class="container" >
    <div class="row">
      <div class="col-md-6 col-md-offset-3">
  
        <div style="text-align:center;" class="imgloader">
  
          <h1>Logging you in...</h1>
  
          <span ><img style="margin-left:5px; height:60px;width:60px;" src="../../assets/images/loading-dots.gif" /></span>
  
        </div>
        
      </div>
    </div>
  </div>