<h2>Custom Site Settings</h2>
<div class="panel panel-default">
  <div class="panel-heading">Custom Header Logo</div>
  <div class="panel-body">

    <div class="row top20">
      <input #headerInput
             type="file"
             accept="image/*"
             (change)="uploadHeader($event.target.files)">
    </div>

    <div class="row justify-content-center top20">
      <img *ngIf="!headerUploadInProgress" class="preview-image" [src]="headerImage.Value" />
      <img style="max-height: 46px;" *ngIf="headerUploadInProgress" src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loader.gif" />
    </div>

    <div class="row top20">
      <div class="col-sm-1" style="padding-top:6px;">
        <label>Active:</label>
      </div>
      <div class="col-md-1" style="padding-top:6px;">
        <input [checked]="isActive(headerImage)" (click)="applyHeader($event.target.checked)" type="checkbox" />
      </div>
    </div>
  </div>
</div>
<div class="alert alert-danger" role="alert" *ngIf="showHeaderError">
  <button type="button" class="close" (click)="showHeaderError=false" data-dismiss="alert">&times;</button>
  <p>{{headerError}}</p>
</div>
<div class="panel panel-default">
  <div class="panel-heading">Custom Footer Logo</div>
  <div class="panel-body">
    <div class="row top20">
      <input #footerInput
             type="file"
             accept="image/*"
             (change)="uploadFooter($event.target.files)">
    </div>
    <div style="background-color:#2B2B2B" class="row justify-content-center top20">
      <img *ngIf="!footerUploadInProgress" class="preview-image" [src]="footerImage.Value" />
      <img style="max-height: 46px;" *ngIf="footerUploadInProgress" src="../../ClientApp/hero.ehs/Hero.EHS.Client/dist/assets/images/loader.gif" />
    </div>
    <div class="row top20">
      <div class="col-sm-1" style="padding-top:6px;">
        <label>Active:</label>
      </div>
      <div class="col-md-1" style="padding-top:6px;">
        <input [checked]="isActive(footerImage)" (click)="applyFooter($event.target.checked)" type="checkbox" />
      </div>
    </div>
  </div>
</div>
<div class="alert alert-danger" role="alert" *ngIf="showFooterError">
  <button type="button" class="close" (click)="showFooterError=false" data-dismiss="alert">&times;</button>
  <p>{{footerError}}</p>
</div>
<div class="panel panel-default">
  <div class="panel-heading">Custom company contact info (footer)</div>
  <div class="panel-body">
    <div class="row top20">
      <div class="col-sm-1" style="padding-top:6px;">
        <label _ngcontent-c16="">Address:</label>
      </div>
      <div class="col-md-3">
        <input class="form-control" [(ngModel)]="footerAddress.Value" type="text">
      </div>
    </div>
    <div class="row top20">
      <div class="col-sm-1" style="padding-top:6px;">
        <label _ngcontent-c16="">Phone:</label>
      </div>
      <div class="col-md-3">
        <input class="form-control" [(ngModel)]="footerPhone.Value" type="text">
      </div>
    </div>

    <div class="row top20">
      <div class="col-sm-1" style="padding-top:6px;">
        <label>Active:</label>
      </div>
      <div class="col-md-1" style="padding-top:6px;">
        <input [checked]="isActive(footerAddress) && isActive(footerPhone)" (click)="applyContacts($event.target.checked)" type="checkbox" />
      </div>
    </div>
  </div>
</div>

<div class="row top20">
  <div class="alert alert-success alert-dismissible" role="alert" *ngIf="showSuccess">
    <button type="button" class="close" (click)="showSuccess=false" data-dismiss="alert">&times;</button>
    Your changes have been saved
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="showError">
    <button type="button" class="close" (click)="showError=false" data-dismiss="alert">&times;</button>
    <p>{{error.Message}}</p>
    <!--<p>{{error.ExceptionMessage}}</p>-->
  </div>
  <div class="col-sm-1" style="padding-top:6px;">
    <input type="button" [disabled]="headerUploadInProgress || footerUploadInProgress" class="btn btn-success" (click)="saveSettings()" value="Save" />
  </div>
</div>


