
<div *ngIf="dataAvailable == 2">

  <div class="row bottom20">
    <div class="col-xs-12">
      <p class="catHeader">{{book.InformationTypeName}} </p>
    </div>
  </div>

  <div class="row bottom20">
    <div class="col-md-10" style="border-bottom: 2px solid rgba(0,0,0,.05); padding-bottom:10px; ">
      <b>{{book.Title}} </b>
    </div>
    <div class="col-md-2">
      <div [informationId]="book.InformationId" [informationTypeId]="book.InformationTypeId" [topicId]="book.TopicId" [bookmarkTitle]="book.Title"></div>
    </div>
  </div>

  <div class="row bottom20">
    <div class="col-sm-2" style="width:145px !important;">
      <img [src]=book.Thumbnail width="135" height="135" alt="" />
    </div>

    <div class="col-sm-10">
      <div [innerHTML]="book.Teaser"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <p><b>Chapters:</b></p>
    </div>
    <div class="col-md-6">
      <p><b>Newsletters:</b></p>
    </div>
  </div>

  <div class="row" style="position:absolute;z-index:1002;left: 44%; top: 55%;">
    <!--top: 65%;-->
    <div class="col-xs-12 text-center">
      <img *ngIf="loading" style="height:60px;width:60px;" src="../../assets/images/loading-dots.gif" />
    </div>
  </div>

  <div class="row" style="margin-bottom:20px;">
    <div class="col-md-6">
      <div *ngFor="let chapter of chapters">
        <p> 
          <!--<a *ngIf="!isLimitedAccess" href='{{chapter.PdfUrl}}' target="_blank">{{chapter.Title}} </a>--> 
          <a *ngIf="!isLimitedAccess" (click)="downloadPDF(chapter.PdfUrl)" style="cursor: pointer;">{{chapter.Title}} </a> 

          <a *ngIf="isLimitedAccess" style="cursor: pointer;" (click)="openModal(modal)">{{chapter.Title}} </a> 
        </p>
      </div>
    </div>

    <div class="col-md-6"> 
      <tree-root [nodes]="nodes" >
        <ng-template #treeNodeTemplate let-node let-index="index">
          <span *ngIf="!node.data.path" style="cursor:text;">{{ node.data.name }}</span>
  
          <!--<a *ngIf="!isLimitedAccess && node.data.path" href='{{node.data.path}}' target="_blank">{{ node.data.name }}</a>-->
          <a *ngIf="!isLimitedAccess && node.data.path" (click)="downloadPDF(node.data.path)" >{{ node.data.name }}</a>
          <a *ngIf="isLimitedAccess && node.data.path" style="cursor: pointer;" (click)="openModal(modal)">{{ node.data.name }}</a>
        </ng-template>
      </tree-root>
    </div>
  </div>
</div>
