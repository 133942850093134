import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject} from 'rxjs';
import { DataTableDirective } from 'angular-datatables';

import { Information } from '../../shared/types/information.type'
import { InformationAllModel } from '../../shared/types/information-all.type'

import { hrHeroConfig } from '../../../environments/environment'
import { HrHeroService } from '../../shared/services/hr-hero.service'
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { setUTCOffset } from 'ngx-bootstrap/chronos/units/offset';

@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './all-resources.html',
  styleUrls: ['./all-resources.css']
})

export class AllResourcesComponent implements OnDestroy, OnInit {
  resources: InformationAllModel;
  resourcesMaster: InformationAllModel;
  resourceInfoMaster: Information[];

  loading: boolean = false;
  isLimitedAccess: boolean;
  tags: any = [];
  filtersKey: string = "all-hr-resources-filters";
  public sortBy = "Title";

  statesCollapsed: boolean = false;
  languageCollapsed: boolean = false;
  categoryCollapsed: boolean = false;
  topicsCollapsed: boolean = false;
  docsCollapsed: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;


  resourceInformation: Information[];

  constructor( public _route: ActivatedRoute, public _router: Router,  private _heroService: HrHeroService,  private _mbService: MembershipDataService) { }

  ngOnInit() {

    this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 15,
        searching: false,
        ordering: true,
        order: [[2,"asc"]],
        info: false,
        lengthMenu:[15,25,50],
        language : {
          lengthMenu: '_MENU_',
          paginate: {
                first: '<<',
                last: '>>',
                next: '>',
                previous: '<'
            }
          },
          dom:  '<"input-group col-md-3"<"col pull-left" f>><"" t<"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
      };

      this.isLimitedAccess = this._mbService.isLimitedAccess();
      this.getAllResources(); 
      this.loading = true;
  }

  getAllResources() {
      var documentTypeIds = hrHeroConfig.resourcesTypes;
      this._heroService.getAllResources(documentTypeIds).then(information => {
          this.resources = information;
          this.resourceInformation = this.resources.Information;
          this.resourcesMaster = this.resources;
          this.resourceInfoMaster = this.resources.Information;
          this.loading = false;
          this.restoreFilters();


          this._route.params.subscribe(params => {
              if (params["type"] != null && params["category"] != null && params["topic"] != null) {
                this.clearAll();
                  this.selectInformationByCategoryTopic(params["category"], params["topic"], params["type"]);
              }
              else
              {
                this.filter();
              }
          }); 
      });

  }

  selectInformationByCategoryTopic(categoryURL, topicURL, infoTypeUrl) {
      this._heroService.getTopicByUrl(topicURL).then(topic => {
          this.filterTopic(topic.TopicId, true, topic.TopicName,false, true);
          this._heroService.getCategoryByUrl(categoryURL).then(category => {
              this.filterCategory(category.CategoryId, true, category.CategoryName, false, false);
              this._heroService.getInfoTypeByUrl(infoTypeUrl).then(infoType => {
                  this.filterDocumentType(infoType.InformationTypeId, true, infoType.InformationTypeName,true, true, false);
              });
          });
      });
  }
  setAllUrl(categories,states,docTypes,topics)
  { 
    this._router.navigate(
      [], 
      {
        relativeTo: this._route,
        queryParams: { categories: categories?categories:null, states:states?states:null, docTypes:docTypes?docTypes:null, topics:topics?topics:null},
        queryParamsHandling: 'merge'
      });
  }  
  
  setTopicsUrl(topics)
  {
   
    this._router.navigate(
      [], 
      {
        relativeTo: this._route,
        queryParams: {topics: topics?topics:null},
        queryParamsHandling: 'merge'
      });
  }
  setDocTypesUrl(docTypes)
  {     
    this._router.navigate(
      [], 
      {
        relativeTo: this._route,
        queryParams: {docTypes: docTypes?docTypes:null},
        queryParamsHandling: 'merge'
      });
  }
  
  setStateUrl(states)
  {
    this._router.navigate(
      [], 
      {
        relativeTo: this._route,
        queryParams: {states: states?states:null},
        queryParamsHandling: 'merge'
      });
  }
  
  setCategoriesUrl(categories)
  {
    this._router.navigate(
      [], 
      {
        relativeTo: this._route,
        queryParams: {categories: categories?categories:null},
        queryParamsHandling: 'merge'
      });
  }

  restoreFilters() {
      
      let categories = this._route.snapshot.queryParams['categories']?.split(',').map(Number);
      let states = this._route.snapshot.queryParams['states']?.split(',').map(Number);
      let docTypes = this._route.snapshot.queryParams['docTypes']?.split(',').map(Number);
      let topics = this._route.snapshot.queryParams['topics']?.split(',').map(Number);

      if((categories!=null && categories.length>0) || (states!=null && states.length>0) || (docTypes!=null && docTypes.length>0) || (topics!=null && topics.length>0))
      {        
        if (categories != null && categories.length>0) {
            this.resources.Categories.filter(f => categories.includes(f.CategoryId)).forEach((item) => {
                this.filterCategory(item.CategoryId, true, item.CategoryName,false, true,false)});
        }
        if (states != null && states.length>0) {
            this.resources.Jurisdictions.filter(f => states.includes(f.JurisdictionId)).forEach((item) => {
                this.filterState(item.JurisdictionId, true, item.JurisdictionName,false, true,false)});
        }
        if (docTypes!= null && docTypes.length>0) {
            this.resources.DocumentTypes.filter(f => docTypes.includes(f.InformationTypeId)).forEach((item) => {
                this.filterDocumentType(item.InformationTypeId, true, item.InformationTypeName,false, true,false,false)});
        }
        if (topics != null && topics.length>0) {
  
            this.resources.Topics.filter(f => topics.includes(f.TopicId)).forEach((item) => {
                this.filterTopic(item.TopicId, true, item.TopicName,false, true,false)});
        }
  
        this.saveFilters();
      }
      else
      {
        let savedFilters = this._heroService.popSavedFilters("all-hr-resources-filters");
        
        if (savedFilters != null) {
            if (savedFilters.Categories && savedFilters.Categories.length>0) {
                savedFilters.Categories.forEach(f => this.filterCategory(f.CategoryId, true, f.CategoryName,false, true,false));
            }
            if (savedFilters.Topics && savedFilters.Topics.length > 0) {
               savedFilters.Topics.forEach(f => this.filterTopic(f.TopicId, true, f.TopicName,false, true,false));
            }
            if (savedFilters.DocumentTypes && savedFilters.DocumentTypes.length > 0) {
                savedFilters.DocumentTypes.forEach(f => this.filterDocumentType(f.InformationTypeId, true, f.InformationTypeName,false, true, false,false));
            }
            if (savedFilters.Jurisdictions && savedFilters.Jurisdictions.length > 0) {
                savedFilters.Jurisdictions.forEach(f => this.filterState(f.JurisdictionId, true, f.JurisdictionName,false, true,false));
            }

           this.setAllUrl(savedFilters.Categories.map(function(item){return item.CategoryId;}).toString(),
           savedFilters.Jurisdictions.map(function(item){return item.JurisdictionId;}).toString(),
           savedFilters.DocumentTypes.map(function(item){return item.InformationTypeId;}).toString(),
           savedFilters.Topics.map(function(item){return item.TopicId;}).toString());
  
        }
    }
  }

  openDetails(information) {
      this._router.navigate([information.Url], { relativeTo: this._route });
  }

  saveFilters() {
      var filters = new InformationAllModel();
      filters.Topics = this.resources.Topics.filter(f => f.Checked);
      filters.DocumentTypes = this.resources.DocumentTypes.filter(f => f.Checked);
      filters.Categories = this.resources.Categories.filter(f => f.Checked);
      filters.Jurisdictions = this.resources.Jurisdictions.filter(f => f.Checked);
      this._heroService.saveResouceFilters("all-hr-resources-filters", filters);
  }

  filterTopic(topicId, checked, name, refresh, filterSelections, setUrl=true) {
      this.resources.Topics.filter(f => f.TopicId == topicId || topicId == 'all').forEach((item) => {
          item.Checked = checked;
      });
      if (topicId !== "all")
          if (checked) this.tags.push({ Id: topicId, Filter: "Topic", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === topicId && item.Filter === "Topic"));

       if(setUrl)
       {
            this.setTopicsUrl(this.resources.Topics.reduce((a, b) => { if (b.Checked) return a + b.TopicId + ","; else return a; }, "").slice(0, -1)); 
       }
      if (refresh)
      {
        this.filter();      
        this.saveFilters();

      }

      if (filterSelections)
      {
        this.filterSelections(4);
      }
  }

  filterDocumentType(documentTypeId, checked, name, refresh, filterSelections, saveFilters, setUrl=true) {
      this.resources.DocumentTypes.filter(f => f.InformationTypeId == documentTypeId || documentTypeId == 'all').forEach((item) => {
          item.Checked = checked;
      });
      if (documentTypeId !== "all")
          if (checked)
              this.tags.push({ Id: documentTypeId, Filter: "DocumentType", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === documentTypeId && item.Filter === "DocumentType"));
      
      if(setUrl)
      {
        this.setDocTypesUrl(this.resources.DocumentTypes.reduce((a, b) => { if (b.Checked) return a + b.InformationTypeId + ","; else return a; }, "").slice(0, -1)); 
      }
      if (refresh)
      {
        this.filter();
      }
      if (saveFilters)
      {
        this.saveFilters();
      }
      if (filterSelections)
      {
          this.filterSelections(5);
      }
  }

  filterCategory(categoryId, checked, name, refresh, filterSelections, seturl=true) {

      this.resources.Categories.filter(f => f.CategoryId == categoryId || categoryId == 'all').forEach((item) => {
          item.Checked = checked;
      });
      if (categoryId !== "all")
          if (checked)
              this.tags.push({ Id: categoryId, Filter: "Category", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === categoryId && item.Filter === "Category"));

    if(seturl)
    {
        this.setCategoriesUrl(this.resources.Categories.reduce((a, b) => { if (b.Checked) return a + b.CategoryId + ","; else return a; }, "").slice(0, -1)); 
    }

      if (refresh)
      {
        this.filter();
        this.saveFilters();
      }

      if (filterSelections)
      {
        this.filterSelections(6);
      }

     
      
  }

  filterState(jurisId, checked, name,refresh, filterSelections, setUrl=true) {

      this.resources.Jurisdictions.filter(f => f.JurisdictionId == jurisId || jurisId == 'all').forEach((item) => {
          item.Checked = checked;
      });
      if (jurisId !== "all")
          if (checked)
              this.tags.push({ Id: jurisId, Filter: "State", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === jurisId && item.Filter === "State"));
 
        if(setUrl)
        {
            this.setStateUrl(this.resources.Jurisdictions.reduce((a, b) => { if (b.Checked) return a + b.JurisdictionId + ","; else return a; }, "").slice(0, -1)); 
        }
        if (refresh)
        {
            this.filter();
            this.saveFilters();
        }

        if (filterSelections)
        {
          this.filterSelections(7);
        }
  }

  clearTag(tag) {
      switch (tag.Filter) {
          case "Topic": this.filterTopic(tag.Id, false, tag.Name,true, true); break;
          case "DocumentType": this.filterDocumentType(tag.Id, false, tag.Name,true, true, true); break;
          case "Category": this.filterCategory(tag.Id, false, tag.Name,true, true); break;
          case "State": this.filterState(tag.Id, false, tag.Name, true, true); break;
      }
  }

  filter() {
      this.resourceInformation = this.getFilterResult("");
      this.rerender();
  }

  filterSelections(selector) {

      if (selector != 41) {
          this.resources.Topics.forEach(obj => {
              this.resources.Topics.filter(f => f.TopicId == obj.TopicId)[0].Count = this.getFilterResult('topics').filter(f => f.TopicId == obj.TopicId).length;
          });
      }
      if (selector != 51) {
          this.resources.DocumentTypes.forEach(obj => {
              this.resources.DocumentTypes.filter(f => f.InformationTypeId == obj.InformationTypeId)[0].Count = this.getFilterResult('documentTypes').filter(f => f.InformationTypeId == obj.InformationTypeId).length;
          });
      }

      if (selector != 61) {
          this.resources.Categories.forEach(obj => {
              this.resources.Categories.filter(f => f.CategoryId == obj.CategoryId)[0].Count = this.getFilterResult('categories').filter(f => f.CategoryId == obj.CategoryId).length;
          });
      }

      if (selector != 71) {
          this.resources.Jurisdictions.forEach(obj => {
              this.resources.Jurisdictions.filter(f => f.JurisdictionId == obj.JurisdictionId)[0].Count = this.getFilterResult('states').filter(f => f.JurisdictionId == obj.JurisdictionId).length;
          });
      }
  }

  getFilterResult(section) {
      return this.resourceInfoMaster.filter(item => (
          (this.resources.Topics.every(f => (!f.Checked)) || this.resources.Topics.some(f => (f.TopicId == item.TopicId && f.Checked)) || section == 'topics') &&
          (this.resources.DocumentTypes.every(f => (!f.Checked)) || this.resources.DocumentTypes.some(f => (f.InformationTypeId == item.InformationTypeId && f.Checked)) || section == 'documentTypes') &&
          (this.resources.Categories.every(f => (!f.Checked)) || this.resources.Categories.some(f => (f.CategoryId == item.CategoryId && f.Checked)) || section == 'categories') &&      
          (this.resources.Jurisdictions.every(f => (!f.Checked)) || this.resources.Jurisdictions.some(f => (f.JurisdictionId == item.JurisdictionId && f.Checked)) || section == 'states') )
      );
  }

  clearAll() {
      this.tags = [];
      this._heroService.removeSavedFilters("all-hr-resources-filters");
      this.resources.Topics.forEach((item) => {
          item.Checked = false;
      });
      this.resources.DocumentTypes.forEach((item) => {
          item.Checked = false;
      });
      this.resources.Categories.forEach((item) => {
          item.Checked = false;
      });
      this.resources.Jurisdictions.forEach((item) => {
          item.Checked = false;
      });
      
      this.setAllUrl(null,null,null,null);

      this.resources = this.resourcesMaster;
      this.filter();
      this.filterSelections(8);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    if(this.dtElement && this.dtElement.dtInstance)
    {           
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });  
        
      }  
      else if (this.dtElement)
      {      
          this.dtTrigger.next();     
      }
    }
}
