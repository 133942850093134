import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DragulaService, dragula } from 'ng2-dragula'

//types
import { CustomWidget } from '../../shared/types/custom-widget.type'
import { KeyValue } from '../../shared/types/key-value.type'
import { UserProfile } from '../../shared/types/user-profile.type'

//services
import { HrHeroService } from '../../shared/services/hr-hero.service';
import { UserProfileService } from '../../shared/services/user-profile.service';
import { MembershipDataService } from '../../shared/services/membership.data.service'

//config
import { hrHeroConfig } from '../../../environments/environment'

@Component({
  selector: 'edit-widget',
  templateUrl: './edit-widget.component.html',
  styleUrls: ['../../../../node_modules/dragula/dist/dragula.css', './edit-widget.component.css']
})

export class AddWidgetComponent {

  customWidgets: CustomWidget[];
  selectedCustomWidget: CustomWidget;
  form: FormGroup;
  isEdit: boolean = false;
  cwbag: Array<string> = new Array<string>();
  nameErrorMessage: string;
  contentErrorMessage: string;

  userProfile: UserProfile = null;
  useParent: boolean = false;
  hasAccess: boolean = false;

  public options: Object = null;

  constructor(
    public _route: ActivatedRoute,
    private _router: Router,
    private _heroService: HrHeroService,
    private fb: FormBuilder,
    private _mb: MembershipDataService,
    private _dragulaService: DragulaService,
    private _up: UserProfileService
  ) {    
    this.form = this.fb.group({
      Active: true,
      Header: null,
      Name: null,
      WidthClass: 'col-lg-3',
      VisibleOnDashboard: true,
      VisibleOnTools: true,
      Body: null,
      CustomWidgetId: 0,
      UserId: this.getCustomerId(),
      ApplicationId: 1
    });

    this.hasAccess = this._mb.getVisibility('Custom Widgets');

    this._route.params.subscribe(params => {

      if (params["id"] != null) {

        this.getCustomWidgetById(params["id"]);

        this.isEdit = true;

      }

    });
    let parentUserProfile = this._mb.parentUserProfile;
    if(parentUserProfile != null && parentUserProfile != undefined && parentUserProfile.Tools != null && parentUserProfile.Tools != undefined){
      if(parentUserProfile.DashboardLock){
        this.useParent = true;
      }
    }

  }

  ngOnInit() {

    /*if (this._mb.parentUserId != null && this._mb.parentUserId > 0) {

      this._up.getUserProfile(this._mb.parentUserId, this._mb.userProfileAPIToken, 1).then(result => {

        this.userProfile = JSON.parse(result.UserProfileJSON);

      });

    } else {*/
      this.userProfile = this._mb.userProfile;
    //}
    let isIE = /*@cc_on!@*/false || !!document['documentMode'];
    let validButtons = ['imageBack','|',"imageUpload","imageByURL"];
    if(!isIE){ //image manager doesn't work in IE
      validButtons.push("imageManager");
    }
    this.options = {
      imageUploadURL: hrHeroConfig.endpoint +'/Upload',
      imageUploadMethod: 'POST',
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],
      imageInsertButtons: validButtons,
      key: '1wzeB3zuv==',
      fileUploadURL: hrHeroConfig.endpoint + '/Upload/PostFile',
      fileUploadMethod: 'POST',
      fileAllowedTypes: ['*']
    };

  }

  getCustomerId() {
    if (this._mb.parentUserId != null && this._mb.parentUserId > 0) {
      return this._mb.parentUserId;
    } else {
      this._mb.user.CustomerId;
    }
  }

  getCss(colClass) {
    if (colClass == 1) {
      colClass = 'col-lg-3';
    }
    return "bag-item " + colClass;
  }

  getCustomWidgetById(id) {
    this._heroService.getMyCustomWidgets().then(result => {
      if (result.length > 0) {
        if (result.filter(f => f.CustomWidgetId == id).length > 0) {

          this.selectedCustomWidget = result.filter(f => f.CustomWidgetId == id)[0];

          this.form = this.fb.group({
            Active: this.selectedCustomWidget.Active,
            Header: this.selectedCustomWidget.Header,
            Name: this.selectedCustomWidget.Name,
            WidthClass: this.selectedCustomWidget.WidthClass,
            VisibleOnDashboard: this.selectedCustomWidget.VisibleOnDashboard,
            VisibleOnTools: this.selectedCustomWidget.VisibleOnTools,
            Body: this.selectedCustomWidget.Body.replace(/\\/g,"/"),
            CustomWidgetId: this.selectedCustomWidget.CustomWidgetId,
            UserId: this._mb.getCustomerId(),
            ApplicationId: this.selectedCustomWidget.ApplicationId
          });
        }
      }
    });
  }

  saveCustomWidget() {

    this.nameErrorMessage = "";
    this.contentErrorMessage = "";

    let userId = this._mb.user.CustomerId;//null;
    let isChild = this._mb.parentUserId != null && this._mb.parentUserProfile != null;
    /*if (this._mb.parentUserId != null && this._mb.parentUserProfile != null) {
      userId = this._mb.parentUserId;
    } else {
      userId = this._mb.user.CustomerId;
    }*/

    this.form.value['UserId'] = userId;

    let errored = false;
    if (this.form.value['Name'] == null || this.form.value['Name'] == '') {
      this.nameErrorMessage = "Name is required.";
      errored = true;
    }
    if (this.form.value['Body'] == null || this.form.value['Body'] == '') {
      this.contentErrorMessage = "Content is required.";
      errored = true;
    }
    if(errored){
      return;
    }

    //if (this.form.value['Header'] == null) {
    //  this.form.value['Header'] = "&nbsp;";
    //}

    let data = {
      Model: this.form.value
    }

    this._heroService.saveCustomWidget(data).subscribe((data) => {

      if (this.userProfile.Tools.filter(f => f.Id == data).length == 0) {

        this.userProfile.Tools.push({
          'Id': parseInt(data.toString()),
          'Component': this.form.value['Name'],
          "VisibleOnHome": true,
          "VisibleOnTools": true,
          "PositionOnHome": null,
          "PositionOnTools": null,
          'Updated': false,
          'Height': '400px',
          "Type": "Custom-Widget"
        });
        
        this._up.saveUserProfile(userId, JSON.stringify(this.userProfile), this._mb.userProfileAPIToken, isChild ? 2 : 1);

      }
      else {
        
        this._mb.userProfile.Tools.filter(f => f.Id ==data)[0].Component = this.form.value['Name'];

        this._up.saveUserProfile(userId, JSON.stringify(this.userProfile), this._mb.userProfileAPIToken, isChild ? 2 : 1);
      }

      this._router.navigate(['/custom-settings']);
    });

  }

  deleteCustomWidget() {
    if (confirm("Are you sure you want to delete this widget?")) {
      this._heroService.deleteCustomWidget(this.selectedCustomWidget.CustomWidgetId).subscribe((data) => {

        if (this._mb.userProfile.Tools.filter(f => f.Id == this.selectedCustomWidget.CustomWidgetId).length > 0) {

          this._mb.userProfile.Tools = this._mb.userProfile.Tools.filter(f => f.Id != this.selectedCustomWidget.CustomWidgetId);

          this._up.saveUserProfile(this._mb.user.CustomerId, JSON.stringify(this._mb.userProfile), this._mb.userProfileAPIToken);
        }

        this._router.navigate(['/custom-settings']);
      });
    }
  }
}
