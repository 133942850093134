import { Component, Provider, ElementRef, OnInit, Input, TemplateRef} from '@angular/core';
//import { BsModalComponent } from 'ng2-bs3-modal';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { HrHeroService } from '../services/hr-hero.service';

@Component({
  selector:"download-information",
  templateUrl: './download.component.html'
})

export class DownloadComponent implements OnInit{
  
  modal: BsModalRef;

  isLimitedAccess: boolean;

  @Input() informationId: number;
  @Input() documentType: string;
  @Input() informationLink: string;
  @Input() fileName: string;

  constructor(private _mbService: MembershipDataService, private modalService: BsModalService, 
    private _heroService: HrHeroService ) { }

  ngOnInit() {
    this.isLimitedAccess = this._mbService.isLimitedAccess();
  } 
  notifyInquiry(){
    if(!this.isLimitedAccess){
      this._mbService.specialInquiryRequest("DOWNLOAD");
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.show(template);
  }

  closeModal()  {
    this.modal.hide();
  }
  download()
  {
   
    this.notifyInquiry();
    var fileName = this.fileName?.replace(/[^\x00-\x7F]/g, "").replace(/[|&;$%@"<>()+,]/g, "").replace(/\s/g, '_');

    this._heroService.downloadInformationFile(this.informationId,fileName, this.documentType);
  }

}
