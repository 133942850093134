import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import { MembershipDataService } from '../shared/services/membership.data.service'
import { HrHeroService } from '../shared/services/hr-hero.service'

import  'jquery';


@Component({
  selector: 'salary-finder',
  templateUrl: './salary-finder.component.html'
})

export class SalaryFinderComponent {

  url: string = null;

  constructor
    (
    private mbDataService: MembershipDataService,
    private heroService: HrHeroService,
    public _router: Router
  ) {

  }

  ngOnInit() {

    this.heroService.getStates().then(states => {

      let statelist = states.length > 0 ? states[0].JurisdictionAbbreviation : ""
      for (let i = 1; i < states.length; i++) {
        statelist += "," + states[i].JurisdictionAbbreviation;
      }
      let baseurl = "../../assets/widgets/tools/sf/sf-ui/index.html";
      this.url = baseurl + "?userId=" + this.mbDataService.user.CustomerId + "&parentId=" + this.mbDataService.parentUserId + "&legacyUserId=" + this.mbDataService.user.LegacyUserId + "&legacyParentUserId=" + this.mbDataService.user.LegacyParentUserId + "&apiToken=" + this.mbDataService.sfAPIToken + "&userStateList=" + statelist + "&membershipType=" //+ "HR";//"membership type placeholder";

      $(function ($) {
        var lastHeight = 0, curHeight = 0, $frame = $('#sfIFrame');
        setInterval(function () {
          curHeight = $frame.contents().find('body').height();
          if (curHeight != lastHeight) {
            $frame.css('height', (lastHeight = curHeight) + 50 + 'px');
          }
        }, 500);
      });
    });
  }

}


