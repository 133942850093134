import { Membership } from '../types/membershipbase-membership'

export class User {
  Email: string;
  Memberships: Membership[];
  AddOns: any;
  MembershipIds: number[];
  AddOnsIds: number[];
  Password: string;
  Username: string;
  UserName: string;
  CustomerId: number;
  ParentCustomerId: number;
  UserId: number;
  LegacyUserId: number;
  LegacyParentUserId: number;
  FirstName: string;
  LastName: string;
  Institution: string;
  LastLoginDate: string;
  SubscriptionType: string;
  TokenExpiry: number=60;//default in minutes

}
