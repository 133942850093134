<!--*ngIf="dataAvailable">-->
<div *ngIf="dataAvailable">
  <!--Mobile top nav-->
  <div class="row grayTopNav-mobile noPrint" *ngIf="(topNavBar === 'true' || topNavBar === true) && dataAvailable" [hidden]="willisBranded || snBranded" >
    <div class="container">
      <div class="col-xs-12">
        <ul>
          <li><a href="https://www.blr.com" target="_blank">BLR</a></li>
          <li><a class="active" href={{hrHeroLink}}>HR Hero</a></li>
          <li><a href="http://store.blr.com" target="_blank">Store</a></li>
          <li><a href={{ehsLink}} target="_blank">EHS Hero</a></li>
          <li *ngIf="!(_mbService.showLitmosLogin)"><a [href]="trainingTodayUrl" target="_blank">TrainingToday</a></li>
          <li *ngIf="_mbService.showLitmosLogin" style="cursor:pointer;"><a (click)=litmosLogin()>TrainingToday LMS Login</a></li>

          <li style="float:right">
            <a data-toggle="collapse" data-target="#mobile-dropdown" #mobileClick>
              <i class="glyphicon glyphicon-menu-hamburger white" data-toggle="collapse" data-target="#date"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!--Mobile top nav dropdown-->
  <div id="mobile-dropdown" class="collapse out navigation-menu-mobile" *ngIf="(currentRoute.indexOf('login') == -1) && dataAvailable">
    <a *ngIf='toolsTab == "true"' [routerLink]="['tools']" (click)="closeMobileDropDown()" [ngClass]="currentRoute == 'tools' ? 'mobile-nav-item-selected  navigation-menu-mobile' : 'mobile-nav-item  navigation-menu-mobile'">Tools</a>
    <a *ngIf='hotlineTab == "true"' [routerLink]="['hotline']" (click)="closeMobileDropDown()" [ngClass]="currentRoute == 'hotline' ? 'mobile-nav-item-selected  navigation-menu-mobile' : 'mobile-nav-item  navigation-menu-mobile'">HR Hotline</a>
    <a *ngIf='trainingTab == "true"' [routerLink]="['training']" (click)="closeMobileDropDown()" [ngClass]="currentRoute == 'training' ? 'mobile-nav-item-selected  navigation-menu-mobile' : 'mobile-nav-item  navigation-menu-mobile'">Training</a>
    <a *ngIf='resourcesTab == "true"' [routerLink]="['federal-state-topic-analysis']" (click)="closeMobileDropDown()" [ngClass]="currentRoute == 'resources' ? 'mobile-nav-item-selected  navigation-menu-mobile' : 'mobile-nav-item  navigation-menu-mobile'">Resources</a>
    <a (click)="navigate('preferences', 'close')" style="cursor:pointer;" [ngClass]="currentRoute == 'settings' ? 'mobile-nav-item-selected  navigation-menu-mobile' : 'mobile-nav-item  navigation-menu-mobile'">Settings</a>
    <a [routerLink]="['myBookmarks']" (click)="closeMobileDropDown()" [ngClass]="currentRoute == 'myBookmarks' ? 'mobile-nav-item-selected  navigation-menu-mobile' : 'mobile-nav-item  navigation-menu-mobile'">My Bookmarks</a>
    <a (click)="logout()" class="mobile-nav-item  navigation-menu-mobile">Log Out</a>
  </div>
  <!--Standard top nav dropdown-->
  <div class="row grayTopNav noPrint" style="margin-right: 0px !important;" *ngIf="(topNavBar === 'true' || topNavBar === true) && dataAvailable" [hidden]="willisBranded || snBranded">
    <div class="container">
      <div class="col-xs-12">
        <ul>
          <li><a href="https://www.blr.com" target="_blank">BLR</a></li>
          <li><a class="active" href={{hrHeroLink}}>HR Hero</a></li>
          <li><a href={{ehsLink}} target="_blank">EHS Hero</a></li>
          <li *ngIf="!(_mbService.showLitmosLogin)"><a [href]="trainingTodayUrl" target="_blank">TrainingToday</a></li>
          <!--<li style="color:red; font-weight:bold; font-family: 'Open Sans', sans-serif !important; font-size:16px; margin-top:2px; margin-left:410px;">BETA</li>-->
          <li style="float:right"><a href="http://store.blr.com" target="_blank">Store</a></li>
          <li *ngIf="_mbService.showLitmosLogin" style="float:right; cursor:pointer;"><a (click)=litmosLogin()>TrainingToday LMS Login</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div id="main-wrapper" *ngIf="dataAvailable">
    <div id="mainNavBar" class="navbar navbar-inverse">
      <div id="app" [ngClass]="(currentSubRoute == 'external' && currentRoute == 'tools') ? 'container collapse in showhead' : 'container'" style="margin-bottom:10px;" aria-expanded="true">
        <div class="navigation-menu navbar-header hr-hero-logo-container" style="text-align:center">
          <div class="center logo" *ngIf="currentRoute.indexOf('login') > -1">
            <img (click)="navigate('login', null)" [src]="headerImage" class="hr-hero-logo" *ngIf="!(willisBranded || snBranded)"/>
            <img src="/ClientApp/hero.hr/Hero.HR.Client/dist/assets/images/wtw2.png" class="hr-hero-logo willis-logo" *ngIf="willisBranded"/>
            <img src="/ClientApp/hero.hr/Hero.HR.Client/dist/assets/images/safetynational2.png" class="hr-hero-logo sn-logo" *ngIf="snBranded"/>
          </div>
          <div class="center logo" *ngIf="currentRoute.indexOf('login') == -1">
            <img [routerLink]="['dashboard']" [src]="headerImage" class="hr-hero-logo" />
          </div>
        </div>
        <div class="navigation-menu-mobile-search" style="text-align:center" *ngIf="currentRoute.indexOf('login') == -1">
          <div class="center hr-hero-logo-container" *ngIf="!(willisBranded || snBranded)">
            <img [routerLink]="['dashboard']" [src]="headerImage" style="display: inline-block;" height="80" width="140" />
          </div>
          <div class="center hr-hero-logo-container" *ngIf="willisBranded">
            <img src="/ClientApp/hero.hr/Hero.HR.Client/dist/assets/images/wtw2.png" class="willis-logo" style="display: inline-block;" />
          </div>
          <div class="center hr-hero-logo-container" *ngIf="snBranded">
            <img src="/ClientApp/hero.hr/Hero.HR.Client/dist/assets/images/safetynational2.png" class="sn-logo" style="display: inline-block;" />
          </div>
          <div [ngClass]="currentRoute == 'search' ? 'navigation-menu-mobile-search-hide' : 'navigation-menu-mobile-search'">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search" (keyup.enter)="search(searchTermMobile.value)" [value]="searchTermValue" #searchTermMobile>
              <span class="input-group-btn" (click)="search(searchTermMobile.value)">
                <button class="btn btn-default" type="submit">
                  <i class="fa fa-search"></i>
                </button>
              </span>            
            </div>
            
          </div>
        </div>

        <div class="navigation-menu" *ngIf="currentRoute.indexOf('login') == -1">
          <div class="collapse navbar-collapse col-md-9">
            <div class="row navigation-menu" style="margin-right:0px; margin-left:60px; padding-top:56px;">
              <div style="margin-left:50px;">
                <a [routerLink]="['tools']" [ngClass]="currentRoute == 'tools' ? 'nav-item-selected' : 'nav-item'" *ngIf='toolsTab == "true"'>Tools</a>
                <a [routerLink]="['hotline']" [ngClass]="currentRoute == 'hotline' ? 'nav-item-selected' : 'nav-item'" *ngIf='hotlineTab == "true"'>HR Hotline</a>
                <a [routerLink]="['training']" [ngClass]="currentRoute == 'training' ? 'nav-item-selected' : 'nav-item'" *ngIf='trainingTab == "true"'>Training</a>
                <a [routerLink]="['federal-state-topic-analysis']" [ngClass]="currentRoute == 'resources' ? 'nav-item-selected' : 'nav-item'" *ngIf='resourcesTab == "true"'>Resources</a>
              </div>
            </div>
          </div>
          <div class="search col-md-3">
            Welcome, {{userName}} |
            <div class="dropdown" style="margin-bottom:5px;">
              <a class="droplink nav-sub-link">My Account</a>
              <div class="dropdown-content">
                <a (click)="navigate('preferences', null)" style="cursor:pointer;">Settings</a>
                <a [routerLink]="'myBookmarks'">My Bookmarks</a>
                <a (click)="logout()" class="link">Log Out</a>
              </div>
            </div>
            <div class="input-group">
              <!--<input type="text" class="form-control" placeholder="Search" (keyup.enter)="search(searchTerm)"  [(ngModel)]="searchTerm" [typeahead]="suggestions" (typeaheadLoading)="changeTypeaheadLoading($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)" typeaheadMinLength="3">-->
              <input type="text" class="form-control" placeholder="Search" (keyup.enter)="search(searchTerm)" [(ngModel)]="searchTerm">
              <span class="input-group-btn" (click)="search(searchTerm)">
                <button class="btn btn-default" type="submit">
                  <i class="fa fa-search"></i>
                </button>
              </span>              
            </div>
            <div style="margin-top: 10px; text-align: right;" *ngIf="helpVisibility()">
              <a style="color:#333;" href="https://interactive.blr.com/hr-hero-help-page" rel="noopener noreferrer" target="_blank"><i class="fa fa-question-circle"></i> Help Center</a>            
            </div>           
          </div>
        </div>
      </div>
      <div class="navbar navbar-inverse in" style="min-height:40px !important;background-color:#1a8cd8; border:none;">
        <div *ngIf="currentSubRoute == 'external' && currentRoute == 'tools'">
          <ul style="list-style:none;">
            <li style="float:left">
              <a data-toggle="collapse" data-target="#app" id="appTrigger" style="cursor:pointer;" class="white-link headeroption" aria-expanded="true" (click)="toggleToolHeader(headerVisible);" #appClick>
                <i class="glyphicon glyphicon-resize-full white" data-toggle="expand" style="color:white;"></i> <span class="showHead" aria-expanded="false">Show</span><span class="hideHead" aria-expanded="true">Hide</span> Header
              </a>
            </li>
          </ul>
        </div>
        <div class="container" style="background-color:#1a8cd8; margin-top:8px;" *ngIf="currentRoute.indexOf('login') == -1">
          <div class="navbar-header" style="margin-left:10px;">
            <div *ngIf="currentRoute == 'dashboard'">
              <a [routerLink]="['dashboard']" class="white-link">My Dashboard</a>
            </div>
            <div *ngIf="currentRoute == 'training'">
              <a [routerLink]="['training']" [ngClass]="currentSubRoute == 'all' ? 'white-link-selected' : 'white-link'">All Training Materials</a>
              <a [routerLink]="['training/new']" [ngClass]="currentSubRoute == 'new' ? 'white-link-selected' : 'white-link'">New Training Materials</a>
              <a [routerLink]="['training/trainers-toolbox']" [ngClass]="currentSubRoute == 'trainers-toolbox' ? 'white-link-selected' : 'white-link'">Trainer's Toolbox</a>
            </div>
            <div *ngIf="currentRoute == 'resources'">
              <a [routerLink]="['federal-state-topic-analysis']" [ngClass]="currentSubRoute == 'federal-state-topic-analysis' ? 'white-link-selected' : 'white-link'">Topic Analysis</a>
              <a [routerLink]="['news']" [ngClass]="currentSubRoute == 'news' ? 'white-link-selected' : 'white-link'">News</a>
              <a [routerLink]="['resources']" [ngClass]="currentSubRoute == 'resources' ? 'white-link-selected' : 'white-link'" *ngIf='allResourcesTab == "true"'>Templates & Guidance</a>
              <a [routerLink]="['calculators']" [ngClass]="currentSubRoute == 'calculators' ? 'white-link-selected' : 'white-link'" *ngIf='calculatorsTab == "true"'>Calculators</a>
              <a [routerLink]="['ReferenceGuides']" [ngClass]="currentSubRoute == 'ReferenceGuides' ? 'white-link-selected' : 'white-link'" *ngIf='referenceTab == "true"'>Reference Guides</a>
              <a [routerLink]="['podcasts']" [ngClass]="currentSubRoute == 'podcasts' ? 'white-link-selected' : 'white-link'">HR Works Podcasts</a>
              <a [routerLink]="['compliance-dates']" [ngClass]="currentSubRoute == 'compliance-dates' ? 'white-link-selected' : 'white-link'">Compliance Dates</a>
            </div>
            <div *ngIf="currentRoute == 'settings' || currentRoute == 'custom-site-settings'">
              <a [routerLink]="['preferences']" [ngClass]="currentSubRoute == 'preferences' ? 'white-link-selected' : 'white-link'">My Preferences</a>
              <a *ngIf="customWidgetsVisible" [routerLink]="['custom-settings']" [ngClass]="currentSubRoute == 'custom-settings' ? 'white-link-selected' : 'white-link'">Custom Widgets</a>
              <a *ngIf="userManagementVisible" [routerLink]="['user-management']" [ngClass]="currentSubRoute == 'user-management' ? 'white-link-selected' : 'white-link'">User Management</a>
              <a *ngIf="customSettingsVisible" [routerLink]="['custom-site-settings']" [ngClass]="currentSubRoute == 'custom-site-settings' ? 'white-link-selected' : 'white-link'">Custom Site Settings</a>
            </div>
            <!--<div *ngIf="currentRoute == 'preferences'">
              <a [routerLink]="'preferences'" class="white-link">My Preferences</a>
            </div>-->
            <div *ngIf="currentRoute == 'myBookmarks'">
              <a [routerLink]="'myBookmarks'" class="white-link">My Bookmarks</a>
            </div>
          </div>
        </div>
      </div>
      <hotline-header *ngIf="currentRoute == 'hotline'"></hotline-header>
    </div>
    <div [ngClass]="(currentSubRoute == 'external' && currentRoute == 'tools') ? '' : 'container'">
      <div style="margin:10px; margin-bottom:10px;">
        <router-outlet></router-outlet>
      </div>
      <!--<div style="color:#F0F8FF; vertical-align:bottom; text-align:center;" class="noPrint">{{userName}}</div>-->
    </div>
    <!--</div>-->
    <br /><br />
    <app-footer class="footer" [footerLogo]="footerImage" [footerAddress]="footerAddress" [footerPhone]="footerPhone" *ngIf="!(willisBranded || snBranded)"></app-footer>

  </div>

</div>
<div id="notificationBanner" *ngIf="showNotificationBanner" style="padding: 15px;">
  <p><b>Important notice: Your account will be logged out in {{warningTimeRemaining* 1000 | date: 'mm:ss'}} minutes due to our security policies.</b></p>
  <p>For the safety and security of your personal information, we periodically log out accounts. Please be sure to save any work or data you have open before the logout takes effect.</p>
  <p>You can extend your session by clicking 'Stay', or log back in at any time to continue using our services. Account logouts occur regularly to help prevent unauthorized access to your information.</p>
  <p> Thank you for your cooperation.</p>
  <button type="button" (click)="logout()" class="btn btn-default">Logout</button>  <button type="button" (click)="stay()" class="btn btn-default">Stay</button>
</div>
<back-to-top *ngIf="userLoggedIn"></back-to-top>