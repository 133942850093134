import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler} from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { CanActivate, Router, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';

import {DynamicAnchorDirective} from './shared/directives/dynamicAnchor';
import { SecureDownloadDirective } from './shared/directives/secureDownload';


//pages
import { DashBoardComponent } from '../app/dashboard/dashboard.component'
import { ToolsComponent } from '../app/tools/tools'
import { LoginComponent } from '../app/login/login.component'
import { PendingLoginComponent } from '../app/login/pending-login.component'
import { WillisLoginComponent } from './login/wtw-login.component'
import { SNHRLoginComponent } from '../app/login/snhr-login.component'
import { AutoLinkComponent } from '../app/login/autolink.component';
import { LatestNewsComponent } from '../app/resources/news/latest-news.component'
import { TrainingNewComponent } from '../app/training/new/new'
import { TrainingNewDashboardComponent } from '../app/training/shared/new.component'
import { TrainingAllComponent } from '../app/training/all/all'
import { TrainingDetailsComponent } from '../app/training/details/details'
import { TrainingTrainersToolboxComponent } from '../app/training/trainers-toolbox/trainers-toolbox'
import { RebuiltTrainingAllComponent } from '../app/training/all-training-rebuild/all-training.component'
import { PreferencesComponent } from '../app/settings/preferences/preferences.component'
import { AccountSettingsComponent } from '../app/settings/account-settings/account-settings.component'
import { CustomSettingsComponent } from '../app/settings/custom-settings/custom-settings.component'
import { AddWidgetComponent } from '../app/settings/custom-settings/edit-widget.component'
import { UserManagementComponent } from '../app/settings/user-management/user-management.component'
import { EditUserComponent } from '../app/settings/user-management/edit-user.component'
import { MyBookmarksComponent } from '../app/myBookmarks/myBookmarks.component'
import { AllResourcesComponent } from '../app/resources/all-resources/all-resources'
import { ResourceDetailsComponent } from '../app/resources/resource-details/resource-details'
import { ReferenceGuidesComponent } from '../app/resources/reference-guides/reference-guides'
import { BookComponent } from '../app/resources/reference-guides/book'
import { CalculatorsComponent } from '../app/resources/calculators/calculators'
import { ComplianceTopicsComponent } from '../app/resources/compliance-topics/compliance-topics'
import { News } from '../app/resources/news/news'
import { Podcasts } from '../app/resources/podcasts/podcasts'
import { ComplianceDates } from '../app/resources/compliance-dates/compliance-dates'
import { Search } from '../app/search/search'
import { Error } from '../app/error/error'
import { Unauthorized } from '../app/unauthorized/unauthorized'
import { TopicAnalysisComponent } from '../app/resources/compliance-topics/topic-analysis/topic-analysis'

//components
import { BackToTopComponent } from '../app/shared/components/back-to-top.component'
import { CategoryTopicLanguageComponent } from '../app/shared/components/selectors/category-topic-language.component'
import { CategoryTopicTypeComponent } from '../app/shared/components/selectors/category-topic-type.component'
import { StateSelectorComponent } from '../app/shared/components/selectors/state.component'
import { DownloadComponent } from '../app/shared/components/download.component'
import { HotlineComponent } from '../app/hotline/hotline.component'
import { BookmarkComponent } from '../app/shared/components/bookmarks.component'
import { BookmarkFolderComponent } from '../app/shared/components/bookmark-folder.component'
import { WidgetToolsEHB } from '../assets/widgets/tools/ehb/widget-tools-ehb'
import { WidgetToolsFLSA } from '../assets/widgets/tools/flsa/widget-tools-flsa'
import { WidgetToolsJDM } from '../assets/widgets/tools/jdm/widget-tools-jdm'
import { WidgetToolsSLCB } from '../assets/widgets/tools/slcb/widget-tools-slcb'
import { WidgetToolsSF } from '../assets/widgets/tools/sf/widget-tools-sf'
import { WidgetToolsHelp } from '../assets/widgets/tools/help/widget-tools-help'
import { WidgetToolsCovid } from '../assets/widgets/tools/covid/widget-tools-covid'
import {WidgetToolsCovidGuide} from '../assets/widgets/tools/covid-guide/widget-tools-covid-guide'
import { WidgetToolsResourceCenter } from '../assets/widgets/tools/resource-center/widget-tools-resource-center'
import { WidgetToolsTopicAnalysis } from '../assets/widgets/tools/topic-analysis/widget-tools-topic-analysis'

import { CustomWidgetComponent } from '../app/shared/components/custom-widget.component'
import { TopicsAtoZComponent } from '../app/shared/components/topics-AtoZ.component'
import { ScriptComponent } from '../app/shared/components/script.component'
import { HotlineHeaderComponent } from '../app/hotline/hotline-header.component'
import { SiteSettingsComponent } from './settings/site-settings/site-settings.component';

//external components
import { ChartBuilderComponent } from '../app/external-components/chart-builder.component'
import { JDMComponent } from '../app/external-components/jdm.component'
import { TreeModule } from '@circlon/angular-tree-component';

//modules
import { DragulaModule } from 'ng2-dragula'
//import { BsModalModule } from 'ng2-bs3-modal'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables'
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule,BsModalRef } from 'ngx-bootstrap/modal';


import { TagInputModule } from 'ngx-chips';

TagInputModule.withDefaults({
  tagInput: {
    inputClass: 'tag-class',
  }
});

//pipes
import { SafeHtmlPipe } from '../app/shared/pipes/safe-html'
import { GroupByPipe } from '../app/shared/pipes/group-by'
import { SortPipe } from '../app/shared/pipes/sort.pipe'
import { Ng2OrderModule } from 'ng2-order-pipe';
import { FooterComponent } from '../app/footer/footer.component'
import { SafePipe } from '../app/shared/pipes/safe-url.pipe'
import { SanitizeHtmlPipe } from '../app/shared/pipes/sanitizeHtml.pipe'
import { DataFilterPipe } from '../app/shared/pipes/data-filter.pipe'
import { HighlightSearchPipe } from '../app/shared/pipes/highlight'

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';



//services

import { AuthGuard } from '../app/shared/guards/auth-guard'
import { ToolsAuthGuard } from '../app/shared/guards/tools-auth-guard'
import { MBToolAuthGuard } from '../app/shared/guards/mb-tool-auth-guard'

import { CookieService, CookieOptionsProvider, CookieModule} from 'ngx-cookie';
import { SalaryFinderComponent } from './external-components/salary-finder.component';
import { HiddenReindexComponent } from './dev/hidden-reindex.component';

//helpers
import { AuthInterceptor } from '../app/shared/Helpers/AuthInterceptor';

import {SSOLoginComponent} from './sso-login/sso-login.component';
import { HeroErrorHandler } from './error/error-handler';
import { AudioPresentationsComponent } from './training/audio-presentations/audio-presentations';

@NgModule({
  declarations: [
    BackToTopComponent,
    ScriptComponent,
    DashBoardComponent,
    ToolsComponent,
    AppComponent,
    WidgetToolsEHB,
    WidgetToolsFLSA,
    WidgetToolsJDM,
    WidgetToolsSLCB,
    WidgetToolsSF,
    WidgetToolsHelp,
    WidgetToolsCovid,
    WidgetToolsCovidGuide,
    WidgetToolsResourceCenter,
    WidgetToolsTopicAnalysis,
    CustomWidgetComponent,
    LoginComponent,
    PendingLoginComponent,
    WillisLoginComponent,
    SNHRLoginComponent,
    AutoLinkComponent,
    ChartBuilderComponent,
    JDMComponent,
    SalaryFinderComponent,
    HotlineComponent,
    PreferencesComponent,
    CustomSettingsComponent,
    EditUserComponent,
    //EditCustomerComponent,
    AddWidgetComponent,
    UserManagementComponent,
    AccountSettingsComponent,
    SiteSettingsComponent,
    MyBookmarksComponent,
    //AdminComponent,
    CategoryTopicLanguageComponent,
    CategoryTopicTypeComponent,
    StateSelectorComponent,
    DownloadComponent,
    //AnalysisComponent,
    TrainingNewComponent,
    AllResourcesComponent,
    ResourceDetailsComponent,
    ReferenceGuidesComponent,
    BookComponent,
    CalculatorsComponent,
    ComplianceTopicsComponent,
    TopicAnalysisComponent,
    TopicsAtoZComponent,
    TrainingNewDashboardComponent,
    TrainingAllComponent,
    TrainingTrainersToolboxComponent,
    TrainingDetailsComponent,
    RebuiltTrainingAllComponent,
    BookmarkComponent,
    BookmarkFolderComponent,
    HotlineHeaderComponent,
    FooterComponent,
    SafeHtmlPipe,
    GroupByPipe,
    SortPipe,
    SafePipe,
    SanitizeHtmlPipe,
    DataFilterPipe,
    HighlightSearchPipe,
    News,
    LatestNewsComponent,
    Podcasts,
    ComplianceDates,
    Search,
    Error,
    Unauthorized,
    HiddenReindexComponent,
    DynamicAnchorDirective,
    SecureDownloadDirective,
    SSOLoginComponent,
    AudioPresentationsComponent
  ],
  imports: [
    //NgDraggableModule,                       
    //Ng2DragDropModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    //TypeaheadModule.forRoot(),
    BrowserAnimationsModule,
    DragulaModule,
    //ResizableModule,
    //FormWizardModule,
    //BsModalModule,
    VirtualScrollModule,
    //DatepickerModule,
    //SimpleNotificationsModule.forRoot(),
    DataTablesModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TreeModule,
    TagInputModule,
    //TagsInputModule.forRoot(),
    HttpClientModule,
    HttpClientJsonpModule,
    Ng2OrderModule,
    CookieModule.forRoot(),
    //TypeaheadModule.forRoot(),
    RouterModule.forRoot([
      { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      { path: 'dashboard', component: DashBoardComponent, canActivate: [AuthGuard]  },
      { path: 'tools', component: ToolsComponent, canActivate: [ToolsAuthGuard]   },
      { path: 'hotline', component: HotlineComponent, canActivate: [AuthGuard]   },
      { path: 'myBookmarks', component: MyBookmarksComponent, canActivate: [AuthGuard]   },
      { path: 'preferences', component: PreferencesComponent, canActivate: [AuthGuard] },
      { path: 'custom-settings', component: CustomSettingsComponent, canActivate: [AuthGuard] },
      { path: 'custom-site-settings', component: SiteSettingsComponent, canActivate: [AuthGuard] },
      { path: 'edit-widget', component: AddWidgetComponent, canActivate: [AuthGuard] },
      { path: 'edit-widget/:id', component: AddWidgetComponent, canActivate: [AuthGuard] },
      { path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard] },
      { path: 'user-management/:refresh', component: UserManagementComponent, canActivate: [AuthGuard] },
      { path: 'edit-user/:id', component: EditUserComponent, canActivate: [AuthGuard] },
      { path: 'edit-user/:cid/:uid', component: EditUserComponent, canActivate: [AuthGuard] },
      //{ path: 'edit-customer/:cid/:uid', component: EditCustomerComponent, canActivate: [AuthGuard] },
      { path: 'account-settings', component: AccountSettingsComponent, canActivate: [AuthGuard] },
      { path: 'search', component: Search, canActivate: [AuthGuard]   },
      { path: 'search/:searchTerm', component: Search, canActivate: [AuthGuard]   },
      { path: 'training/new', component: TrainingNewComponent, canActivate: [AuthGuard]   },
      { path: 'training/trainers-toolbox', component: TrainingTrainersToolboxComponent, canActivate: [AuthGuard] },
      { path: 'training', component: RebuiltTrainingAllComponent, canActivate: [AuthGuard]   },
      { path: 'training/all', component: RebuiltTrainingAllComponent, canActivate: [AuthGuard]   },
      { path: 'training/related/:topic', component: RebuiltTrainingAllComponent, canActivate: [AuthGuard]   },
      { path: 'training/:type/:category/:topic/:title', component: TrainingDetailsComponent, canActivate: [AuthGuard] },
      { path: 'training/:type/:category/:topic/:title/:id', component: TrainingDetailsComponent, canActivate: [AuthGuard] },
      { path: 'resources', component: AllResourcesComponent, canActivate: [AuthGuard] },
      { path: 'resources/:type/:category/:topic', component: AllResourcesComponent, canActivate: [AuthGuard] },
      { path: 'resources/:type/:category/:topic/:title', component: ResourceDetailsComponent, canActivate: [AuthGuard] },
      { path: 'policies/:category/:topic/:title', component: ResourceDetailsComponent, canActivate: [AuthGuard] },
      { path: 'resources/policies/:category/:topic/:title', component: ResourceDetailsComponent, canActivate: [AuthGuard] },
      { path: 'podcasts', component: Podcasts, canActivate: [AuthGuard] },
      { path: 'podcasts/:category/:topic/:title', component: Podcasts, canActivate: [AuthGuard] },
      { path: 'podcasts/:category/:topic', component: Podcasts, canActivate: [AuthGuard] },
      { path: 'compliance-dates', component: ComplianceDates, canActivate: [AuthGuard] },

      { path: 'calculators', component: CalculatorsComponent, canActivate: [AuthGuard] },
      { path: 'calculators/:category/:topic/:title', component: CalculatorsComponent, canActivate: [AuthGuard] },
      { path: 'ReferenceGuides', component: ReferenceGuidesComponent, canActivate: [AuthGuard] },
      { path: 'Book', component: BookComponent, canActivate: [AuthGuard] },
      { path: 'Book/:bookUrl', component: BookComponent, canActivate: [AuthGuard] },
      { path: 'federal-state-topic-analysis', component: ComplianceTopicsComponent, canActivate: [AuthGuard] },
      { path: 'login/:route', component: LoginComponent },
      { path: 'login/:auth_token', component: LoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'sso-login/:route', component: SSOLoginComponent },
      { path: 'sso-login', component: SSOLoginComponent },
      { path: 'pending-login', component: PendingLoginComponent },
      { path: 'wtw-login', component: WillisLoginComponent },
      { path: 'snhr-login', component: SNHRLoginComponent },
      { path: 'autolink', component: AutoLinkComponent },
      { path: 'analysis', component: TopicAnalysisComponent, canActivate: [AuthGuard] },
      { path: 'analysis/:category/:topic', component: TopicAnalysisComponent, canActivate: [AuthGuard] },
      { path: 'analysis/:category/:topic/:state', component: TopicAnalysisComponent, canActivate: [AuthGuard] },
      { path: 'Analysis', component: TopicAnalysisComponent, canActivate: [AuthGuard] },
      { path: 'Analysis/:category/:topic/:pageName', component: TopicAnalysisComponent, canActivate: [AuthGuard] },
      { path: 'Analysis/:category/:topic/:pageName/:state', component: TopicAnalysisComponent, canActivate: [AuthGuard] },
      { path: 'news', component: News, canActivate: [AuthGuard]  },
      { path: 'news/:category/:topic/:title', component: News, canActivate: [AuthGuard] },
    
      { path: 'state-law-chart-builder', component: ChartBuilderComponent, canActivate: [MBToolAuthGuard] },
      { path: 'job-description-manager', component: JDMComponent, canActivate: [MBToolAuthGuard] },
      { path: 'salary-finder', component: SalaryFinderComponent, canActivate: [MBToolAuthGuard] },
      { path: 'error', component: Error, canActivate: [AuthGuard] },
      { path: 'error/:type', component: Error, canActivate: [AuthGuard] },
      { path: 'unauthorized', component: Unauthorized, canActivate: [AuthGuard]},
      { path: 'hidden-reindex', component: HiddenReindexComponent, canActivate: [AuthGuard] },
      { path: 'audio-presentations/:url', component: AudioPresentationsComponent, canActivate: [AuthGuard] },
      { path: '**', redirectTo: 'error/404' }//,
    ])
  ],
  bootstrap: [AppComponent],
  providers: [AuthGuard, ToolsAuthGuard, MBToolAuthGuard, BsModalRef, CookieService, CookieOptionsProvider,
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
]
})

export class AppModule { }
