export class InheritableProperties {

    constructor() {

    this.HeaderLogo.Value = "../assets/images/HRHero-Logo.png";
    this.FooterLogo.Value = "../assets/images/BLR_footerLogo.png";

    this.HRHeaderLogo.Value = "../assets/images/HRHero-Logo.png";
    this.HRFooterLogo.Value = "../assets/images/BLR_footerLogo.png";
    this.HRFooterAddress.Value = "101 Creekside Crossing, Suite 1700-375, Brentwood, Tennessee 37027";
    this.HRFooterPhone.Value = "800-727-5257";
  }

  Tabs: Tabs = new Tabs;
  TopNavBar: Setting = new Setting();
  HeaderLogo: Setting = new Setting();
  FooterLogo: Setting = new Setting();
  FooterAddress: Setting = new Setting();
  FooterPhone: Setting = new Setting();

  HRHeaderLogo: Setting = new Setting();
  HRFooterLogo: Setting = new Setting();
  HRFooterAddress: Setting = new Setting();
  HRFooterPhone: Setting = new Setting();
}

export class Tabs {
  Hotline: Setting = new Setting();
  Tools: Setting = new Setting();
  SLCB: Setting = new Setting();
  JDM: Setting = new Setting();
  FLSA: Setting = new Setting();
  EHB: Setting = new Setting();
  SF: Setting = new Setting();
  HELP: Setting = new Setting();
  COVID: Setting = new Setting();
  COVIDGUIDE: Setting = new Setting();
  RESOURCECENTER: Setting = new Setting();
  TOPICANALYSIS: Setting = new Setting()
  Training: Setting = new Setting();
  Resources: Setting = new Setting();
  ChecklistsForms: Setting = new Setting();
  Calculators: Setting = new Setting();
}

export class Setting {
  Value: string = "true";
  TakeChild: string = "false";
}

