import { Component, TemplateRef, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

//import { BsModalComponent } from 'ng2-bs3-modal';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

import { HrHeroService } from '../../shared/services/hr-hero.service'
import { Information } from '../../shared/types/information.type'

import { GroupByPipe } from '../../shared/pipes/group-by'
import { TestBed } from '@angular/core/testing';

@Component({
  selector: 'calculators',
  templateUrl: './calculators.html',
  styleUrls: ['./calculators.css']
})

export class CalculatorsComponent {

  information: Information[];
  informationSelected: Information;
  informationGrouped: Information[];

  bookmarkFormOpen: boolean = false;
  selectedArticle: Information;

  calculatorModal: BsModalRef;
  @ViewChild('calculatorModal') 
  public modalTemplate: TemplateRef<any>;

  constructor(
    public _route: ActivatedRoute,
    private _router: Router,
    private _heroService: HrHeroService,
    private modalService: BsModalService

  ) { }

  ngAfterViewInit() {

    this.getCalculators();

    this._route.params.subscribe(params => {
      if (params["category"] != null && params["topic"] != null && params["title"] != null) {

        this.selectInformationByURL('calculators', params["category"], params["topic"], params["title"]);
        this.openModal();

      } else {

      }
    });
  }

  getCalculatorByUrl(url: string) {

    let parts = url.split('/');

    this.getCalculator(parts[1], parts[2], parts[3], parts[4]);
  }

  getCalculatorById(informationId) {

      this._heroService.getInformationById(informationId).then(information => {

          if (information != null) {

              this.informationSelected = information;
              this._router.navigate([this.informationSelected.Url]);
          }
          else {
              this._router.navigate(['/error/404']);
          }
      });
  }

  getCalculator(type, category, topic, page) {

    this._heroService.getInformationByUrlParts('calculators', category, topic, page).then(information => {

      if (information != null) {

        this.informationSelected = information;
        this.openModal();
        this._router.navigate([this.informationSelected.Url]);

      }
      else {
        this._router.navigate(['/error/404']);
      }

    });
  }

  selectInformationByURL(type, category, topic, page) {

    this._heroService.getInformationByUrlParts(type, category, topic, page).then(information => {

      if (information != null) {

        this.informationSelected = information;
        
      }
      else {
        this._router.navigate(['/error/404']);
      }

    });

  }

  getCalculators() {

    this._heroService.getAllInformation(15).then(information => {
      if (information != null) {
        this.information = information;

        this.informationGrouped = GroupByPipe.prototype.transform(information, 'TopicDesc');
      }
    });
  }

  filterCalculatorsByTopic(topicDesc) {

    return this.information.filter(f => f.TopicDesc == topicDesc);
  }

  openBookmarkForm() {

    this.bookmarkFormOpen = true;
  }
  
  bookmarkUpdated(event) {

    switch (event) {
      case 'open':
        this.closeModal();
        break;
      case 'close':
        this.openModal();
        break;
    }

    this.bookmarkFormOpen = false;
  }

  openModal() {
    this.calculatorModal = this.modalService.show(this.modalTemplate);
  }

  closeModal()  {
    this.calculatorModal.hide();
  }
}
