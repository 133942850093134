import { Component, Provider, ElementRef, OnInit, Input, Compiler, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { HrHeroService } from '../../shared/services/hr-hero.service';
import { MembershipDataService } from '../../shared/services/membership.data.service';

import { Information } from "../../shared/types/information.type";
import { Chapter } from "../../shared/types/chapter.type";

@Component({
    templateUrl: './book.html',
    styleUrls: ['./book.css']
})

export class BookComponent {
    dataAvailable: number = 0;
    book: Information;
    bookUrl: any;
    chapters: Chapter[];
    options = {};
    nodes: any;
    allowAccess: boolean = true;
    bookmarkFormOpen: boolean = false;
    isLimitedAccess: boolean = false;
    benefitsInfoTypeId: number = 29;
    loading: boolean = false;

    constructor(private route: ActivatedRoute, public _router: Router, private _heroService: HrHeroService, private _mbService: MembershipDataService) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params['bookUrl'] != null) {
                this.bookUrl = params['bookUrl'];
                this.isLimitedAccess = this._mbService.isLimitedAccess();
                this.getBook(this.bookUrl);
            }
        });
    }

    getBook(bookUrl) {
        this._heroService.getInfo(bookUrl).then(book => {
            this.book = book;
            if (this.book.InformationTypeId==29) {
                this.allowAccess = this._mbService.hasBenefits;
            }
            this.getChapters(this.bookUrl);
            this.getNewsletters(book.FilePath);
            this.dataAvailable = 1;
        }); 
    }

    getChapters(bookUrl) {
        this._heroService.getChapters(bookUrl).then(chapters => {
            this.chapters = chapters;
            this.dataAvailable = 2;
        }); 
    }

    getNewsletters(path) {
        
        this._heroService.getNewsletters(path).then(result => {
            this.nodes = result;
        }); 
    }

    downloadPDF(url) {
        this.loading = true;
        //let isIE = /*@cc_on!@*/false || !!document['documentMode'];
        //let isEdge = window.navigator && window.navigator.msSaveOrOpenBlob;
        //if (isIE || isEdge) {
        //    window.open(url);
        //    this.loading = false;
        //}else{

        var filename = url.split('\\').pop();


        this._heroService.downloadPDF(url).subscribe(
            (blob) => {
                this.loading = false;
                    var fileURL = URL.createObjectURL(blob);
                    window.open(fileURL);
        });

        //}
    }
}