import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { MembershipDataService } from '../../../../../src/app/shared/services/membership.data.service'

@Component({
    selector: 'widget-tools-covid-guide',
    styles: ['>>> .modal-xl { width: 1000px; }'],
    templateUrl: './widget-tools-covid-guide.html'
})

export class WidgetToolsCovidGuide {
    isLimitedAccess: boolean;

    constructor(private _mbService: MembershipDataService) { }

    ngOnInit() {

        this.isLimitedAccess = this._mbService.isLimitedAccess();
    }

    navigate() {
        if (this._mbService.userIsHRAuthenticated) {
            console.log("deprecated navigation");
        } else {
            location.href = "/login";
        }
    }
}

