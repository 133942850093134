<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <p class="catHeader">Federal Labor Standards Act</p>
    </div>

    <div class="col-xs-12">
      These resources will help you understand wage and hour requirements as established by the federal FLSA, including how to analyze your jobs to determine whether they are
      properly classified as exempt or nonexempt.
    </div>
  </div>

  <div class="row top20" style="border-bottom:1px solid #e3e3e3;">
    <div *ngFor="let flsa of flsaBooks">
      <div class="col-sm-3">
        <div>
          <a [routerLink]="['/Book/' + flsa.InfoUrl]">
            <img src={{flsa.Thumbnail}} width="135" height="135" />
          </a>
          <p> 
            <a [routerLink]="['/Book/' + flsa.InfoUrl]">{{flsa.Title}} </a> 
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row top20">
    <div class="col-xs-12">
      <p class="catHeader">Leave & Disability</p>
    </div>
    <div class="col-xs-12">
      These resources will help you understand and comply with federal leave and accommodation requirements under the Family & Medical Leave Act, the Americans with Disabilities Act,
      and related state laws.
    </div>
  </div>

  <div class="row top20" style="border-bottom:1px solid #e3e3e3;">
    <div *ngFor="let ldBook of leaveDisabilityBooks">
      <div class="col-sm-3">
        <div>
          <a [routerLink]="['/Book/' + ldBook.InfoUrl]">
            <img src={{ldBook.Thumbnail}} width="135" height="135" />
          </a>
          <p> 
            <a [routerLink]="['/Book/' + ldBook.InfoUrl]">{{ldBook.Title}} </a> 
          </p>
        </div>  
      </div>
    </div>
  </div>

  <div class="row top20">
    <div class="col-xs-12">
      <p class="catHeader">Benefits</p>
    </div>
    <div class="col-xs-12">
      These resources will help you understand the mandatory requirements and best practices for administering competitive and compliant group health, retirement,
      and fringe benefit plans.
    </div>
  </div>

  <div class="row top20" style="margin-bottom:20px;">
    <div *ngFor="let bBook of benefitsBooks; let i = index">
      <div class="clearfix" *ngIf="i % 4 == 0"></div>

      <div class="col-md-3">
        <a *ngIf="hasApplication" [routerLink]="['/Book/' + bBook.InfoUrl]">
          <img src={{bBook.Thumbnail}} width="135" height="135" />
        </a>
        <a *ngIf="!hasApplication" class="pointer" (click)="openModal(modal)">
          <img src={{bBook.Thumbnail}} width="135" height="135" />
        </a>

        <p>
          <a *ngIf="hasApplication" [routerLink]="['/Book/' + bBook.InfoUrl]">{{bBook.Title}} </a>
          <a *ngIf="!hasApplication" class="pointer" (click)="openModal(modal)">{{bBook.Title}} </a>
        </p>
      </div>
    </div>
  </div>

<!--modals-->
<ng-template cssClass="modal-xl" #modal>

  <div *ngIf="!hasApplication">
    <div class="modal-header modal-header-hr">
      Limited Access
    </div>
    <div class="modal-body">
      <div class="body-content">
        <div>
          <p class="header">Full access to HR Hero’s Benefits Books is reserved for add-on subscribers.</p>
          <p> <a href="https://interactive.blr.com/hrhero-full-access-request" target="_blank">Request access now</a> to learn about subscription options – a solutions 
              expert will reach out ASAP! </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="closeModal()">Close</button>
  </div>
</ng-template>
