<div *ngIf="hasAccess">

  <div class="row">
    <div class="col-lg-6">
      <h2>User Management</h2><br />

      <form #userForm="ngForm" novalidate> 
        <div class="row">
          <div class="col-md-8">
            <div class="form-group" [ngClass]="{ 'has-error': ((Email.dirty || Email.touched) && !Email.valid) || (Email.dirty && Email.valid && !emailAvailable) }">
              <label>Email: </label> <span *ngIf="( ( Email.errors?.required) || ((Email.dirty || Email.touched) && Email.errors?.required)) " class="text-danger warningMsg">* </span> 
              <input type="text" id="Email" [(ngModel)]="User.Email" name="Email" #Email="ngModel" [email]="true" class="form-control" 
                     pattern="^[a-zA-Z0-9][a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]*(\.[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                     (blur)="!Email.errors?.pattern && Email.dirty && validateEmail(Email.value)" autocomplete="off" required > 
             
              <div *ngIf="(Email.touched && !Email.valid) && Email.errors?.pattern" class="text-danger">Email must be a valid email address. </div>
              <div *ngIf="Email.dirty && Email.valid && !emailAvailable" class="text-danger">
                <div *ngIf="!emailLinkable">
                  The email address you have entered is already in use. Please contact our customer success team at <br/>1-844-687-0479 to resolve. 
                </div>
                <div *ngIf="emailLinkable">
                  This email is already in use but does not have any HR Hero subscription. <a style="cursor: pointer;" (click)="openModal(linkingModal)">Click here</a> to add this user to your account.
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div class="row">
          <div class="col-md-8">
            <div class="form-group"  [ngClass]="{ 'has-error': ((Username.dirty || Username.touched) && !Username.valid ) || (Username.dirty && Username.valid && !usernameAvailable) }">
              <label>{{initialCustomerId != 0 ? "Username:" : "Username will be the provided email address"}}</label> 
              <input disabled type="text" id="Username" [(ngModel)]="User.username" name="Username" #Username="ngModel" minlength="3" maxlength="50"
                     pattern="^[a-zA-Z0-9][a-zA-Z0-9-_.@]*$" (blur)="Username.dirty && validateUsername(Username.value, Username.valid)"
                     autocomplete="off" class="form-control" required>

              <div *ngIf="Username.errors?.pattern" class="text-danger">Username must be a valid username. </div>
              <div *ngIf="Username.errors?.minlength" class="text-danger">Username must be at least 3 characters long</div>
              <div *ngIf="Username.dirty && Username.valid && !usernameAvailable" class="text-danger">Username is not available. </div>
            </div>
          </div>
        </div>

        <div *ngIf="customerId == 0" class="row top10">
          <div class="col-md-8">
            <div class="form-group" [ngClass]="{ 'has-error': (Password.dirty || Password.touched) && !Password.valid }">
              <label>Password:</label> <span *ngIf="((customerId == 0 && Password.errors?.required) || ((Password.dirty || Password.touched) && Password.errors?.required))" class="text-danger warningMsg">*</span>
              <input type="password" id="Password" [(ngModel)]="User.Password" name="Password" #Password="ngModel"
                     class="form-control" minlength="5" maxlength="20" autocomplete="new-password" [disabled]="!emailAvailable" required />
              
              <div *ngIf="Password.errors?.minlength" class="text-danger">Password must be at least 5 characters long</div>
            </div>
          </div>
        </div>

        <div class="row top10">
          <div class="col-md-8">
            <div class="form-group" [ngClass]="{ 'has-error': (FirstName.dirty || FirstName.touched) && !FirstName.valid }">
              <label>First Name:</label><span *ngIf="(( FirstName.errors?.required)  || ((FirstName.dirty || FirstName.touched) && FirstName.errors?.required))" class="text-danger warningMsg">*</span>
              <input type="text" id="FirstName" [(ngModel)]="User.FirstName" name="FirstName" #FirstName="ngModel" class="form-control"
                     minlength="1" maxlength="50" [disabled]="!emailAvailable" required />
            </div>
          </div>
        </div>

        <div class="row top10">
          <div class="col-md-8">
            <div class="form-group" [ngClass]="{ 'has-error': (LastName.dirty || LastName.touched) && !LastName.valid }">
              <label>Last Name:</label><span *ngIf="((LastName.errors?.required) || ((LastName.dirty || LastName.touched) && LastName.errors?.required))" class="text-danger warningMsg">*</span>
              <input type="text" id="LastName" [(ngModel)]="User.LastName" name="LastName" #LastName="ngModel" class="form-control" 
                     minlength="1" maxlength="50" [disabled]="!emailAvailable" required /> 
            </div>
          </div>
        </div>

        <div class="row top10">
          <div class="col-md-8">
            <div class="form-group" [ngClass]="{ 'has-error': (Company.dirty || Company.touched) && !Company.valid }">
              <label>Company:</label><span *ngIf="( (Company.errors?.required) || ((Company.dirty || Company.touched) && Company.errors?.required))" class="text-danger warningMsg">*</span>
              <input type="text" id="Company" [(ngModel)]="User.Company" name="Company" #Company="ngModel" minlength="1" maxlength="50"
                     class="form-control" [disabled]="!emailAvailable" required />
            </div>
         </div>
        </div>

        <div class="row top10"> 
          <div class="col-md-8"> 
            <label>Membership:</label> <span *ngIf="(( MembershipId.errors?.required) || ((MembershipId.dirty || MembershipId.touched) && MembershipId.errors?.required))" class="text-danger warningMsg">*</span>
            <select [(ngModel)]="User.MembershipId"  (ngModelChange)="getCustomTags($event)" id="MembershipId" name="MembershipId" #MembershipId="ngModel" class="form-control" [disabled]="!emailAvailable" required>
              <option *ngFor="let memb of parentMemberships" [ngValue]="memb.id">{{memb.plan.name}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="addOns?.length>0" class="row top10">
          <div class="col-md-8">
            <label>Add-On:</label> 
            <select [(ngModel)]="User.AddOnId" id="AddOnId" name="AddOnId" #AddOnId="ngModel" class="form-control" [disabled]="!emailAvailable" >
              <option value="0"> None </option>
              <option *ngFor="let addOn of addOns" [ngValue]="addOn.id">{{addOn.plan.name}}</option>
            </select>
          </div>
        </div>
        
        <div *ngFor="let memb of parentMemberships">
          <div *ngIf="memb.id==User.MembershipId">
            <div *ngFor="let tag of customTags ; let i = index" class="row top10">
              <div class="col-md-8">  
                <label>{{tag.Name}}:</label>
                <select class="form-control" *ngIf="tag.Options && tag.Options.length>0" name="{{tag.Name}}" [(ngModel)]="tag.MembershipTagId" class="form-control" [disabled]="!emailAvailable" >
                  <option [ngValue]="null">Select...</option>
                  <option *ngFor="let value of tag.Options;" [ngValue]="value.Item2">{{value.Item1}}</option>
                </select>
                <input type="text" class="form-control" *ngIf="!tag.Options || tag.Options.length<1" [(ngModel)]="tag.Value" name="{{tag.Name}}"  [disabled]="!emailAvailable"  minlength="1" maxlength="50"/>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="row top20">
        <div class="col-md-8 btn-toolbar">
          <button type="submit" class="btn btn-success" [disabled]="!userForm.valid || (Email.dirty &&!emailAvailable ) || 
                  (Username.dirty && !usernameAvailable) || saveInProgress" (click)="saveUser()" >Save User</button> 
          <a class="btn btn-default" [routerLink]="['/user-management/false']">Cancel</a>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <h2>Internal Admin Preferences</h2><br />

      <div class="form-group" *ngIf="internalPrefs != null && internalPrefs != undefined && internalPrefs.length > 0">
        <div *ngFor="let option of internalPrefs">

          <label>
            <input type="checkbox" name="options" value="{{option.Id}}" [(ngModel)]="option.Visible" />
            {{this.displayName(option)}}
          </label>
        </div>
      </div>
      <h2>Show the following tools:</h2>
      <div class="form-group" *ngIf="toolSettings != null && toolSettings != undefined && toolSettings.length > 0">
        <div *ngFor="let option of toolSettings">
          <div *ngIf="adminPrefShouldShow(option.Id)">
            <label>
              <input type="checkbox"
                    name="options"
                    value="{{option.Id}}"
                    [(ngModel)]="option.Visible" />

              {{this.displayName(option)}}
            </label>
          </div>
        </div>
      </div>
      <h2>Show custom widgets on:</h2>
      <div class="form-group" *ngIf="customWidgets != null && customWidgets != undefined && customWidgets.length > 0">
        <div *ngFor="let option of customWidgets">

          <div *ngIf="adminPrefShouldShow(option.Id)">
            <label>
              <input type="checkbox"
                    name="options"
                    value="{{option.Id}}"
                    [(ngModel)]="option.Visible" />

              {{this.displayName(option)}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!hasAccess">
  <h2>You do not have access to this view.</h2>
</div>

<ng-template #linkingModal>
  <div class="modal-header modal-header-blue">
    Link user to membership
  </div>
  <div class="modal-body">
    Select a membership to add {{User.Email}} to:
    <div>
            <select class="form-control" [(ngModel)]="User.MembershipId" id="linkingMembershipId" name="linkingMembershipId" >
              <option *ngFor="let memb of parentMemberships" [ngValue]="memb.id">{{memb.plan.name}}</option>
            </select>
    </div>
    
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link-green" (click)="linkUserByEmail(User.Email,User.MembershipId)" [disabled]="saveInProgress">Link user</button>
    <button type="button" class="btn btn-default" (click)="closeModal()">Cancel</button>
  </div>
</ng-template>