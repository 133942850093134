import { Component, Provider, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HrHeroService } from '../../shared/services/hr-hero.service'
import { Information } from '../../shared/types/information.type'

@Component({
  selector: 'new',
  templateUrl: './new.html',
  styleUrls: ['./new.css']
})

export class TrainingNewComponent {

  information: Information[];

  constructor(
    private _heroService: HrHeroService,
    public _router: Router, 
  ) { }

  ngOnInit() {
    //6 months
    this._heroService.getInformationTrainingNew(-180).then(information => {
      if (information != null) {
        this.information = information;
      }
    });

  }

  selectInformation(informationItem: Information) {
    this._router.navigate([informationItem.Url]);
  }

}
