import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'error',
  templateUrl: './error.html',
  styleUrls:['./error.css']
})

export class Error {

  type: string;

  constructor(
    public _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this._route.params.subscribe(params => {

      if (params["type"] != null) {

        this.type = params["type"];

      } 

    });

  }
  
}
