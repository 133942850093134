export class CCUser {
  id: number;
  username: string;
  memberbase_Id: number;
  Email: string;
  FirstName: string;
  LastName: string;
  Company: string;
  Password: string;
  _Links: CC_Links;
  ProfileAddressId: number;
  MembershipId: number;
  AddOnId: number=0;
}

export class CC_Links {
  Self: CCUser_Self;
}
export class CCUser_Self {
  href: string;
}
