<div>&nbsp; &nbsp;
	<div align="center">&nbsp;<img class="fr-dib" src="/ClientApp/hero.hr/Hero.HR.Client/dist/assets/images/covidguide.png" style="height: 100px;"></div>
	<br>
	<div align="center">
		<div class="nav-sub-link">Employer&#39;s Guide to COVID-19</div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
		<div class="blue-tool-line">
			<br>
		</div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
		<div class="body-content-italic">The Employer&rsquo;s Guide to COVID-19 tackles the practical and legal implications of COVID-19 on the workplace and workforce.</div>

		<p>
			<br>
		</p>
	</div>
	<div align="center">
		<br><a class="btn-blue-sm" [routerLink]="['/resources/guidance-documents/HR-Administration/HR-Strategy/Employers-Guide-to-COVID-19']">Get Started</a></div>
	<p>
		<br>
	</p>
</div>