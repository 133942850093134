<div *ngIf="hasAccess">
  <h1>My Custom Widgets <a [routerLink]="['/edit-widget']" class="default-link" style="font-size:14px;">add new</a></h1>

  

    <div class="panel panel-default">

       <table datatable class="table table-striped" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead class="thead">
          <tr>
            <th>
              Name
            </th>
            <th>
              Header
            </th>
            <th *ngIf="!useParent">
              Active on Dashboard
            </th>
            <th>
              Active on Tools
            </th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of customWidgets">
            <td>{{item.Name}}</td>
            <td>{{item.Header}}</td>
            <td *ngIf="!useParent">{{item.VisibleOnDashboard}}</td>
            <td>{{item.VisibleOnTools}}</td>
            <td><a [routerLink]="['/edit-widget', item.CustomWidgetId]">manage</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="!hasAccess">
    <h2>You do not have access to this view.</h2>
  </div>