export class Widget
{
    Id: number;
    Component: string;
    PositionOnHome: number;
    PositionOnTools: number;
    VisibleOnTools: boolean;
    VisibleOnHome: boolean;
    Height: any;
    Type: string;
    Updated: boolean;
}
