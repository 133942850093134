import { Component, TemplateRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HrHeroService } from '../../shared/services/hr-hero.service'
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { Information } from '../../shared/types/information.type'
//import { BsModalComponent } from 'ng2-bs3-modal';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'trainers-toolbox',
  templateUrl: './trainers-toolbox.html',
  styleUrls: ['./trainers-toolbox.css']
})

export class TrainingTrainersToolboxComponent {

  information: Information[];
  modal: BsModalRef;
  isLimitedAccess: boolean;

  constructor(
    private _heroService: HrHeroService,
    private _mbService: MembershipDataService,
    public _router: Router,
    private modalService: BsModalService 
  ) { }

  ngOnInit() {
    this.isLimitedAccess = this._mbService.isLimitedAccess();

    this._heroService.getInformation(10, null, null, null).then(information => {
      if (information != null) {
        this.information = information;
      }

    });

  }

  selectInformation(informationItem: Information) {
    this._router.navigate([informationItem.Url]);
  }

  notifyInquiry(docid){
    //console.log("sending to inquiry");
    this._mbService.specialInquiryRequest("DOWNLOAD/" + docid);
  }
    openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.show(template);
  }
  closeModal()  {
    this.modal.hide();
  }
  download(id,documentType, title)
  {
    this.notifyInquiry(id);

    var fileName = title?.replace(/[^\x00-\x7F]/g, "").replace(/[|&;$%@"<>()+,]/g, "").replace(/\s/g, '_');
    this._heroService.downloadInformationFile(id,fileName, documentType);
}
}
