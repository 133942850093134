<h1>Training Details</h1>

<div> 

  <div *ngIf="informationSelected != null" style="margin-top:0px;">
    <div style="padding-left:8px;">
      <h2 class="seperator">{{informationSelected.Title}}</h2>
      <div class="body-content body-content-padding"><span class="body-content-label">Topic: </span>{{informationSelected.TopicDesc}}</div>
      <div class="body-content body-content-padding" *ngIf="informationSelected.DocumentType != null || informationSelected.InformationTypeId == 11"><span class="body-content-label">Document Type: </span>{{(informationSelected.InformationTypeName == "Toolbox Talks" ? "Training Talks" : informationSelected.InformationTypeName)}}</div>
      <div class="body-content body-content-padding" *ngIf="informationSelected.DocumentType != null || informationSelected.InformationTypeId == 11"><span class="body-content-label">Summary: </span><div [innerHtml]="informationSelected.Teaser"></div></div>
      <div class="body-content body-content-padding" *ngIf="informationSelected.DocumentType == null && informationSelected.Body != null && informationSelected.Body != ''"><span class="body-content-label">Body: </span><div [innerHtml]="informationSelected.Body | safeHtml"></div></div>
    </div>
    <div class="document-btns">
      <download-information [informationId]="informationSelected.InformationId"  [documentType]="informationSelected.InformationTypeId != 11 ? informationSelected.DocumentType : 'audio'" [informationLink]="informationSelected.FilePath" [fileName]="informationSelected.Title"></download-information>
      <bookmarks style="margin-left:4px;" [bookmarkTitle]="informationSelected.Title" [informationId]="informationSelected.InformationId" [informationTypeId]="informationSelected.InformationTypeId" [topicId]="informationSelected.TopicId"></bookmarks>
    </div>

    <h2 class="body-content-padding" *ngIf="supportingDocuments != null && supportingDocuments.length > 0">Supporting Documents</h2>
    <div *ngFor="let ts of supportingDocuments">
      <a style="cursor:pointer;" class="link" [routerLink]="[ts.Url]" *ngIf="(ts.DocumentType == 'ppt' || ts.DocumentType =='pptx') && ts != null && ts.Url != null"><img src="../../assets/images/white-ppt.PNG" />{{ts.Title}}</a>
      <a style="cursor:pointer;" class="link" [routerLink]="[ts.Url]" *ngIf="ts.DocumentType == 'pdf' && ts != null && ts.Url != null"><img src="../../assets/images/white-pdf.PNG" />{{ts.Title}}</a>
      <a style="cursor:pointer;" class="link" [routerLink]="[ts.Url]" *ngIf="ts.DocumentType == 'doc' && ts != null && ts.Url != null"><img src="../../assets/images/white-doc.PNG" />{{ts.Title}}</a>
      <a style="cursor:pointer;" class="link" [routerLink]="[ts.Url]" *ngIf="ts.DocumentType == 'docx' && ts != null && ts.Url != null"><img src="../../assets/images/white-doc.PNG" />{{ts.Title}}</a>
      <a style="cursor:pointer;" class="link" [routerLink]="[ts.Url]" *ngIf="ts != null && ts.Url != null && ts.InformationTypeId == 11"><img src="/ClientApp/hero.hr/Hero.HR.Client/dist/assets/images/white-audio-2.png" />{{ts.Title}}</a>
    </div>

  </div>

</div>
<div *ngIf="informationSelected != null" style="display:none;">Unified ID: {{informationSelected.SourceInfoId}}</div>
