<div *ngIf="!loading">

  <h1>All HR Training Materials</h1>
  <div>Browse through the HR Hero training library and select the training materials that meet your needs.</div>

  <br />

</div>


<div *ngIf="!informationSelected && loading" class="row" style="text-align:center;">
  <img style="margin-left:5px; height:60px;width:60px;" src="../../../assets/images/loading-dots.gif" />
</div>

<div *ngIf="informationSelected != null">

  <div id="resource-details">
    <h2 class="seperator">{{informationSelected.Title}}</h2>
    <div class="body-content"><span class="body-content-label">Topic: </span>{{informationSelected.TopicName}}</div>
    <div class="body-content"><span class="body-content-label">Training Type: </span>{{informationSelected.TrainingTypeName}}</div>
    <div class="body-content"><span class="body-content-label">Market: </span>{{informationSelected.Market}}</div>
    <div class="body-content"><span class="body-content-label">Summary: </span>{{informationSelected.Teaser}}</div>

    <div class="body-content body-content-padding">{{informationSelected.DocumentTypeName}}</div>

    <div class="document-btns seperator">
      <download-information [informationId]="informationSelected.InformationId" [documentType]="informationSelected.DocumentType" [fileName]="informationSelected.Title"></download-information>
    </div>

    <div class="body-content-label" *ngIf="ChildInformationId == null">{{informationSelected.DocumentTypeName}} Supporting Documents</div>
    <div class="body-content-label" *ngIf="ChildInformationId != null">This document is one part of a collection of related documents.  Click below to see other components.</div>

    <div *ngFor="let ts of supportingDocuments">
      <a style="cursor:pointer;" class="link" (click)="navigateToDetailsByUrl(ts.Url, ts.DatabaseId)" *ngIf="ts != null && ts.Url != null">{{ts.Title}}</a>
    </div>

  </div>

</div>


<div class="row" [class.is-hidden]="loading">
  <div class="col-lg-3" style="margin-bottom:20px;" *ngIf="resourceItems != null"> 
    <div class="clear-all"><a (click)="clearAll()">Clear all filters</a></div>
    <div class="outer-filter-panel">
      <div class="filter-panel  body-content-label" data-target="#language">
        Languages<span data-toggle="collapse" (click)="langCollapsed = !langCollapsed" data-target="#language" style="float: right;cursor: pointer;">{{ langCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="language" class="collapse in" >
        <table class="table">
          <tbody>
            <tr *ngFor="let item of resourceItems.Languages" >
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterLanguages(item.Language, $event.target.checked, item.Language,true)" [checked]="item.Checked">{{item.Language}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>            
        </table>
      </div>
      <div class="filter-panel  body-content-label" data-target="#topic">
        Topics<span data-toggle="collapse" (click)="topicsCollapsed = !topicsCollapsed" data-target="#topic" style="float: right;cursor: pointer;">{{ topicsCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="topic" class="collapse in" style="overflow-y:auto; max-height:186px;" >
        <table class="table">
            <tbody>
              <tr *ngFor="let item of resourceItems.Topics">
                <td *ngIf="item.Count != 0">
                  <div class="checkbox">
                    <label><input type="checkbox" (click)="filterTopic(item.TopicId, $event.target.checked, item.TopicName,true)" [checked]="item.Checked">{{item.TopicName}}</label><span style="float:right;">{{item.Count}}</span>
                  </div>
                </td>
              </tr>
          </tbody>            
        </table>
      </div>
      <div class="filter-panel  body-content-label" data-target="#doc">
        Document Types<span data-toggle="collapse" (click)="dtCollapsed = !dtCollapsed" data-target="#doc" style="float: right;cursor: pointer;">{{ dtCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="doc" class="collapse in" style="overflow-y:auto; max-height:186px;">
        <table class="table">
          <tbody>
            <tr *ngFor="let item of resourceItems.DocumentTypes">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterDocumentTypes(item.InformationTypeId, $event.target.checked, item.InformationTypeName, true)" [checked]="item.Checked">{{(item.InformationTypeName == "Toolbox Talks" ? "Training Talks" : item.InformationTypeName) }}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>            
        </table>
      </div>

    </div>
  </div>
  <div class="col-lg-9">

    <div class="ar-tab-container">
      <div class="tag" *ngFor="let tag of tags" (click)="clearTag(tag)">
        <div class="inline-text">{{tag.Name}}</div>
        <div class="delete-button" style="height: 100%">
          <svg _ngcontent-c17="" height="16px" viewBox="0 0 32 32" width="16px">
            <path _ngcontent-c17="" d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313"></path>
          </svg>
        </div>
      </div>
    </div>
    <div>
      <div class="white-well">
        <table datatable class="table table-striped regulatory-activity-table" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead class="thead">
            <tr>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Topic <span></span></a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer" style="width:500px;">Title <span></span></a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Document Type <span></span></a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Language <span></span></a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of trainingInformation">
              <td>{{item.TopicName}}</td>
              <td><a (click)="openDetails(item)" style="cursor:pointer;">{{item.Title}}</a></td>
              <td>{{item.DocumentTypeName}}</td>
              <td>{{item.Language}}</td>
            </tr>
          </tbody>
        </table> 
      </div>
    </div>
  </div>
</div>
 