import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { UserProfileService } from '../services/user-profile.service'


import { User } from '../types/membershipbase-user';
import { Account } from '../types/account.type';
import { UserProfile } from '../types/user-profile.type';
import { KeyValueString } from '../types/key-value-string.type';
import { InheritableProperties, Setting } from '../types/application-settings';
import { CCCustomer } from '../../shared/types/cc-customer';
import { CCMembership } from '../../shared/types/cc-membership';

import { Injectable } from '@angular/core';
import { authConfig } from '../../../environments/environment'
import { hrHeroConfig } from '../../../environments/environment'
import  'jquery';
import { Subject, Observable } from "rxjs";

declare let pendo: any;

@Injectable({
  providedIn: 'root'  // <- ADD THIS
})

export class MembershipDataService {

  user: User = null;
  defaultUserProfile: UserProfile = new UserProfile;
  parentUserProfile: UserProfile = new UserProfile;
  userProfile: UserProfile = new UserProfile;
  userIsHRAuthenticated: boolean = false;
  userIsEHSAuthenticated: boolean = false;
  userProfileAPIToken: string;
  slcbAPIToken: string;
  jdmAPIToken: string;
  sfAPIToken: string;
  userProfileTypeId: number;
  parentUserId: number;
  libraries: string;
  hasJDM: boolean = false;
  hasSLCB: boolean = false;
  hasEHB: boolean = false;
  hasFLSA: boolean = false;
  hasSF: boolean = false;
  hasHelp: boolean = false;
  hasCovid: boolean = false;
  hasCovidGuide: boolean = false;
  hasBenefits: boolean = false;
  states: any;
  selectedState: any;
  selectedStateName: string = "";
  userProfileSet: boolean = false;
  profileSet: boolean = false;
  isMembershipLimited: boolean = false;
  //headerLogo: string = "../assets/images/HRHero-Logo.png";
  //footerLogo: string = "../assets/images/BLR_footerLogo.png";
  childCustomers: CCCustomer[];
  allChildren: CCCustomer[];
  showLitmosLogin: boolean = false;
  litmosLink: string = "";
  private userLoggedIn = new Subject<boolean>();
  AutherizationType : number;

  
  constructor(
    private httpClient: HttpClient,
    private _ups: UserProfileService
  ) {

    this.defaultUserProfile.ProfileGUIUpdated = false;

    this.defaultUserProfile.Tools = [
      { 'Id': 1, 'Component': 'Employee Handbook Builder', 'VisibleOnHome': true, 'VisibleOnTools': true, 'Updated': false, 'Height': '400px', "Type": "Tool", "PositionOnHome": 0, "PositionOnTools": 0 },
      { 'Id': 2, 'Component': 'State Law Chart Builder', 'VisibleOnHome': true, 'VisibleOnTools': true, 'Updated': false, 'Height': '400px', "Type": "Tool", "PositionOnHome": 1, "PositionOnTools": 1 },
      { 'Id': 3, 'Component': 'Job Classification & FLSA Audit', 'VisibleOnHome': true, 'VisibleOnTools': true, 'Updated': false, 'Height': '400px', "Type": "Tool", "PositionOnHome": 2, "PositionOnTools": 2 },
      { 'Id': 4, 'Component': 'Job Description Manager', 'VisibleOnHome': true, 'VisibleOnTools': true, 'Updated': false, 'Height': '400px', "Type": "Tool", "PositionOnHome": 3, "PositionOnTools": 3 },
      { 'Id': 5, 'Component': 'Salary Finder', 'VisibleOnHome': true, 'VisibleOnTools': true, 'Updated': false, 'Height': '400px', "Type": "Tool", "PositionOnHome": 4, "PositionOnTools": 4 },
      { "Id": 6, "Component": "Help widget", "VisibleOnHome": true, "VisibleOnTools": true, "Updated": false, "Height": "400px", "Type": "Tool", "PositionOnHome": 5, "PositionOnTools": 5 },
      { "Id": 7, "Component": "Coronavirus Resources", "VisibleOnHome": true, "VisibleOnTools": true, "Updated": false, "Height": "400px", "Type": "Tool", "PositionOnHome": 6, "PositionOnTools": 6 },
      { "Id": 8, "Component": "Employers Guide to COVID-19", "VisibleOnHome": true, "VisibleOnTools": true, "Updated": false, "Height": "400px", "Type": "Tool", "PositionOnHome": 7, "PositionOnTools": 7 },
      { "Id": 9, "Component": "Resource Center", "VisibleOnHome": true, "VisibleOnTools": true, "Updated": false, "Height": "400px", "Type": "Tool", "PositionOnHome": 8, "PositionOnTools": 8 }
    ];

    this.defaultUserProfile.AdminPreferences = [
      { 'Id': 1, 'Name': 'Custom Widgets', 'Visible': true },
      { 'Id': 3, 'Name': 'User Management', 'Visible': false },
      { 'Id': 4, 'Name': 'Show State Law Chart Builder', 'Visible': true },
      { 'Id': 5, 'Name': 'Show Employee Handbook Builder', 'Visible': true },
      { 'Id': 6, 'Name': 'Show Job Description Manager', 'Visible': true },
      { 'Id': 7, 'Name': 'Show Job Classification & FLSA Audit', 'Visible': true },
      { 'Id': 8, 'Name': 'Show Home page widgets', 'Visible': true },
      { 'Id': 9, 'Name': 'Show Tools page widgets', 'Visible': true },
      { 'Id': 10, 'Name': 'Show Salary Finder', 'Visible': true },
      { "Id": 11, "Name": "Show Help widget", "Visible": true },
      { "Id": 12, "Name": "Show Coronavirus Resources", "Visible": true },
      { "Id": 13, "Name": "Show Employer's Guide to COVID-19", "Visible": true },
      { "Id": 14, "Name": "Show Resource Center", "Visible": true },
      { "Id": 15, "Name": "Show Topic Analysis", "Visible": true }
    ];

    this.defaultUserProfile.CurrentStateId = 52;
    this.selectedState = -1;
    this.selectedStateName = "";
    this.defaultUserProfile.InheritableProperties = new InheritableProperties;
    this.defaultUserProfile.InheritableProperties.HeaderLogo.Value = "../assets/images/HRHero-Logo.png";
    this.defaultUserProfile.InheritableProperties.FooterLogo.Value = "../assets/images/BLR_footerLogo.png";

    this.userLoggedIn.next(false);
  }

  getSsoSession(returnUrl:string='') {

    var _this = this;

    $('.imgloader').css('visibility', 'visible');
    $('#btnSubmit').hide();

    $.ajax({
      type: 'POST',
      url: hrHeroConfig.endpoint + "/account/GetSessionRedirectUrl",
      xhrFields: {
        withCredentials: true
      },
      data: {
        UserName: null,
        Password: null,
        AuthenticationSystem: "WA",
        ApplicationId: hrHeroConfig.applicationId,
        Profile: JSON.stringify(_this.defaultUserProfile),
        AutoLogin: true,
        ReturnUrl: returnUrl
      },
      crossDomain: true,
      success: function (data, textStatus, jqXHR) {
        if (data.split('&return_url=').length > 1) {
          localStorage.setItem('ru', data.split('&return_url=')[1]);
        }
        if(data != null && data.indexOf("hero.blr.com") > -1){
          location.href = data;
        }else{
          location.href = "login";
        }
      }
    });
  }

  setUpAccount(account)
  {
    //if(!account.Authorized || account.Authorized == "false"){
    //  return;
    //}
    this.setApiTokens(account.APITokens);
    this.setUserProfileTypeId(account.UserProfileTypeId);
    this.userIsHRAuthenticated = true;
    this.userIsEHSAuthenticated = false;
    this.setUser(account);  

    this.parentUserId = account.ParentUserId;
    this.setUserProfile(JSON.parse(account.Profile), JSON.parse(account.ParentProfile), account.ParentUserId);

    this.setUserLoggedIn(true);
  }


  autoLogin(profile=null) {

    if(profile==null)
    {
      profile= this.userProfile;
    }   

    let data: any;

    data = {
      "AuthenticationSystem": 'WA',
      "Profile": JSON.stringify(profile),
      "ApplicationId": hrHeroConfig.applicationId
    };

    let body = JSON.stringify(data);

    return this.httpClient.post(hrHeroConfig.endpoint + "/account/AutoLogin?origin=" + hrHeroConfig.origin + "&isSecure=" + hrHeroConfig.isSecure, data);

  }

  login(userName, password) {

    let headers: any = {
      'Content-Type': 'application/json'
    }

    let options = {
      withCredentials: true,
      headers: new HttpHeaders(headers)
    };

    let data: any;

    data = {
      UserName: userName,
      Password: password,
      AuthenticationSystem: "WA",
      ApplicationId: "5DFB4010-E889-4BA6-BED7-F69884981EEC",
      Profile: JSON.stringify(this.defaultUserProfile)
    };

    let body = JSON.stringify(data);

    return this.httpClient.post(hrHeroConfig.endpoint + + "/account/Login", body, options);

  }

  logout() {
    this.setUserLoggedIn(false);
    $.ajax({
      url: hrHeroConfig.endpoint + "/account/logout",
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true,
      success: function (data) {
        localStorage.removeItem('ru');
        location.href = hrHeroConfig.ccLogoutUrl + "/account/logout?return_url=" + hrHeroConfig.endpoint.replace("/api", "") + "/hr/login";
      }
    });
  }

  setUser(account: Account) {
    //console.log("in setUser, account: ", account);
    this.user = new User();

    this.user.CustomerId = account.CustomerId;
    this.user.UserId = account.UserId;
    this.user.TokenExpiry = account.TokenExpiry;
    this.user.ParentCustomerId = account.ParentUserId;
    this.user.LegacyUserId = account.LegacyUserId;
    this.user.LegacyParentUserId = account.LegacyParentUserId;
    this.user.Username = account.UserName;
    this.user.FirstName = account.FirstName;
    this.user.LastName = account.LastName;
    this.user.Email = account.EmailAddress;
    this.user.Institution = account.Company;
    this.user.LastLoginDate = account.LastLoginDate;
    this.user.MembershipIds = account.MembershipIds;
    this.user.AddOns = account.AddOns;
    this.user.AddOnsIds = new Array();
    this.AutherizationType = account.AutherizationType;

    // just need any API URL so we can get a response...doesn't need to be anything specific
    var apiUrl = 'https://api.github.com';

    console.log('Pendo Initialization');
    this.httpClient.get(apiUrl, {}).subscribe(r => {
      pendo.initialize({
          visitor: {
              id: this.user.CustomerId,
              email: this.user.Email,
              full_name: this.user.FirstName + ' ' + this.user.LastName
          },
          account: {
              id: account.CustomerId,
              name: this.user.Institution 
          }
      });
    });
      console.log('After pendo');

    //if (window.navigator.userAgent.toLowerCase().indexOf('firefox') == -1){
      window["UserLeap"]("setUserId","" + account.CustomerId);
      window["UserLeap"]("setEmail","" + account.EmailAddress);
      window["UserLeap"]("setAttributes",{
        email: this.user.Email,
        company: this.user.Institution,
        ccid: this.user.CustomerId,
        memberships: this.user.MembershipIds
      });
    //}

    if (this.user.AddOns!=null) {
        for (var i = 0; i < this.user.AddOns.length; i++) {
            this.user.AddOnsIds.push(this.user.AddOns[i].id);
        }
    }

    this.userIsHRAuthenticated = account.Authorized;
    if(this.userIsHRAuthenticated){
      this.userIsEHSAuthenticated = false;
    }
    this.user.SubscriptionType = account.SubscriptionType;

   // this.user.Username = account.
    //let userData = {
    //  Username: this.user.Username,
    //  Email: this.user.Email,
    //  Memberships: this.user.Memberships
    //}

    this.libraries = account.Roles.toString();
    this.states = account.States;
    
    //jdm
    if (account.Roles.filter(f => f == "Limited").length > 0) {
      this.isMembershipLimited = true;
    }

    //jdm
    if (account.Roles.filter(f => f == "JDM").length > 0) {
      this.hasJDM = true;
    }

    //slcb
    if (account.Roles.filter(f => f == "SLCB").length > 0) {
      this.hasSLCB = true;
    }

    //
    this.hasHelp = true;

    this.hasCovid = true;
    this.hasCovidGuide = true;

    //ehb
    if (account.Roles.filter(f => f == "TPBILD").length > 0) {
        this.hasEHB = true;
    }

    //flsa
    if (account.Roles.filter(f => f == "TPFLAX").length > 0) {
        this.hasFLSA = true;
    }
    
    //benefits
    if (account.Roles.filter(f => f == "Benefits").length > 0) {
      this.hasBenefits = true;
    }

    if (account.SubscriptionType != "JDM" && account.SubscriptionType != "SLCB" && account.SubscriptionType != "JDM and SLCB"){
      this.hasSF = true; //only on hero, not standalone memberships. maybe refactor this if we add new standalones.
    }

    if(this.user.Email != null && this.user.MembershipIds != null && this.user.MembershipIds != undefined && this.user.MembershipIds.length > 0){
      this.checkLitmos();
    }

  }

  setUserProfile(userProfile: UserProfile, parentUserProfile: UserProfile, parentUserId) {

    let _ths = this;
    let save = false;

    if (userProfile.InheritableProperties.HRHeaderLogo == undefined)
        userProfile.InheritableProperties.HRHeaderLogo = this.defaultUserProfile.InheritableProperties.HRHeaderLogo;

    if (userProfile.InheritableProperties.HRFooterLogo == undefined)
        userProfile.InheritableProperties.HRFooterLogo = this.defaultUserProfile.InheritableProperties.HRFooterLogo;

    if (userProfile.InheritableProperties.HRFooterPhone == undefined)
        userProfile.InheritableProperties.HRFooterPhone = this.defaultUserProfile.InheritableProperties.HRFooterPhone;

    if (userProfile.InheritableProperties.HRFooterAddress == undefined)
        userProfile.InheritableProperties.HRFooterAddress = this.defaultUserProfile.InheritableProperties.HRFooterAddress;
    
    if (parentUserProfile != null && parentUserProfile.Tools != null) {

      this.parentUserProfile = parentUserProfile;

      this.parentUserProfile.Tools.forEach(function (value) {

        let matchingTools = userProfile.Tools.filter(f => f.Id == value.Id);
        if (matchingTools.length != 0) { 
          matchingTools[0].Component = value.Component;
        }
        else { //take parent pref wholesale
          userProfile.Tools.push(value);
          save = true;
        }

      });

      this.userProfile = userProfile;
      let headerLogo: string = "../assets/images/HRHero-Logo.png";
      let footerLogo: string = "../assets/images/BLR_footerLogo.png";

      //set child to parents header image if child doesnt already have custom image
      if (this.userProfile.InheritableProperties.HeaderLogo.Value == headerLogo &&
        this.parentUserProfile.InheritableProperties.HeaderLogo.Value != headerLogo &&
        this.parentUserProfile.InheritableProperties.HeaderLogo.Value != this.userProfile.InheritableProperties.HeaderLogo.Value
      ) {
        this.userProfile.InheritableProperties.HeaderLogo.Value = this.parentUserProfile.InheritableProperties.HeaderLogo.Value;
      }

      if (this.userProfile.InheritableProperties.FooterLogo.Value == footerLogo &&
        this.parentUserProfile.InheritableProperties.FooterLogo.Value != footerLogo &&
        this.parentUserProfile.InheritableProperties.FooterLogo.Value != this.userProfile.InheritableProperties.FooterLogo.Value
      ) {
        this.userProfile.InheritableProperties.FooterLogo.Value = this.parentUserProfile.InheritableProperties.FooterLogo.Value;
      }

      //this.userProfile.CurrentStateId = this.parentUserProfile.CurrentStateId;

      if (save) {
        this._ups.saveUserProfile(this.user.CustomerId, JSON.stringify(this.userProfile), this.userProfileAPIToken, 2);
      }

      this.profileSet = true;

    } else {
      this.userProfile = userProfile;
      this.profileSet = true;
    }
  }

  truthValue(possiblyAString){ //sometimes the json decodes things wrong and we need to convert "false" to false.
    if(possiblyAString === "true"){
      return true;
    }else if(possiblyAString === "false"){
      return false;
    }else{
      return possiblyAString;
    }
  }
  getSettingValue(key) {

    switch (key) {
      case "Hotline":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.Hotline.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.Hotline.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.Hotline.Value;
        }
        
      case "Tools":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.Tools.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.Tools.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.Tools.Value;
        }

      case "SLCB":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.SLCB.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.SLCB.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.SLCB.Value;
        }
        
      case "JDM":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.JDM.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.JDM.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.JDM.Value;
        }

      case "FLSA":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.FLSA.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.FLSA.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.FLSA.Value;
        }

      case "EHB":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.EHB.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.EHB.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.EHB.Value;
        }
      case "SF":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.SF.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.SF.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.SF.Value;
        }
      case "HELP":
  
        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.HELP.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.HELP.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.HELP.Value;
          }

      case "COVID":
          if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.COVID.TakeChild))) {
              return this.parentUserProfile.InheritableProperties.Tabs.COVID.Value;
          } else {
              return this.userProfile.InheritableProperties.Tabs.COVID.Value;
          }
      case "COVIDGUIDE":
          if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.COVIDGUIDE.TakeChild))) {
              return this.parentUserProfile.InheritableProperties.Tabs.COVIDGUIDE.Value;
          } else {
              return this.userProfile.InheritableProperties.Tabs.COVIDGUIDE.Value;
          }
      case "RESOURCECENTER":
            if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.RESOURCECENTER.TakeChild))) {
                return this.parentUserProfile.InheritableProperties.Tabs.RESOURCECENTER.Value;
            } else {
                  return this.userProfile.InheritableProperties.Tabs.RESOURCECENTER.Value;
            }
      case "TOPICANALYSIS":
        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.TOPICANALYSIS.TakeChild))) {
            return this.parentUserProfile.InheritableProperties.Tabs.TOPICANALYSIS.Value;
        } else {
              return this.userProfile.InheritableProperties.Tabs.TOPICANALYSIS.Value;
        }            
      case "Training":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.Training.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.Training.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.Training.Value;
        }

      case "Resources":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.Resources.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.Resources.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.Resources.Value;
        }

      case "ChecklistsForms":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.ChecklistsForms.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.ChecklistsForms.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.ChecklistsForms.Value;
        }

      case "Calculators":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.Tabs.Calculators.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.Tabs.Calculators.Value;
        } else {
          return this.userProfile.InheritableProperties.Tabs.Calculators.Value;
        }

      case "TopNavBar":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.TopNavBar.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.TopNavBar.Value;
        } else {
          return this.userProfile.InheritableProperties.TopNavBar.Value;
        }

      case "HeaderLogo":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.HeaderLogo.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.HeaderLogo.Value;
        } else {
          return this.userProfile.InheritableProperties.HeaderLogo.Value;
        }

      case "FooterLogo":

        if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.FooterLogo.TakeChild))) {
          return this.parentUserProfile.InheritableProperties.FooterLogo.Value;
        } else {
          return this.userProfile.InheritableProperties.FooterLogo.Value;
        }

        case "FooterAddress":

          if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.FooterAddress.TakeChild))) {
            if(this.parentUserProfile.InheritableProperties.FooterAddress != null){
              return this.parentUserProfile.InheritableProperties.FooterAddress.Value;
            }else {
              return this.userProfile.InheritableProperties.FooterAddress.Value;
            }
          } else {
            return this.userProfile.InheritableProperties.FooterAddress.Value;
          }
  
          case "FooterPhone":
  
          if (this.userProfileTypeId == 2 && !(this.truthValue(this.userProfile.InheritableProperties.FooterPhone.TakeChild))) {
            if(this.parentUserProfile.InheritableProperties.FooterPhone != null){
              return this.parentUserProfile.InheritableProperties.FooterPhone.Value;
            }else {
              return this.userProfile.InheritableProperties.FooterPhone.Value;
            }
          } else {
            return this.userProfile.InheritableProperties.FooterPhone.Value;
          }

    }
  }

    setUserLoggedIn(userLoggedIn: boolean) {
      this.userLoggedIn.next(userLoggedIn);
    }

    getUserLoggedIn(): Observable<boolean> {
      return this.userLoggedIn.asObservable();
    }
  setApiTokens(tokens: Array<KeyValueString>) {
    if (!tokens) {

      return;
    }
    if (tokens.filter(w => w.Key == "blr").length > 0) {
      this.userProfileAPIToken = tokens[0].Value;
      localStorage.setItem('token', this.userProfileAPIToken);
    }
    if (tokens.filter(w => w.Key == "slcb").length > 0) {
      this.slcbAPIToken = tokens[1].Value;
    }
    if (tokens.filter(w => w.Key == "jdm").length > 0) {
      this.jdmAPIToken = tokens[2].Value;
    }
    if (tokens.filter(w => w.Key == "sf").length > 0) {
      this.sfAPIToken = tokens[3].Value;
    }

  }

  setUserProfileTypeId(id) {
    this.userProfileTypeId = id;
  }

  getUserProfile() {

  }

  setParentUserId(id) {
    this.parentUserId = id;
  }

  getCustomerId() {
    return this.user.CustomerId;
  }
  getLegacyId() {
    return this.user.LegacyUserId;
  }
  getParentId() {
    return this.user.ParentCustomerId;
  }
  getPrimaryHeroMembershipId() {
    return this.user.MembershipIds[0];
  }
  getAllHeroMembershipIds() {
      return this.user.MembershipIds;
  }
  getHeroMembershipAndAddOnIds() {
      var allIds = this.user.MembershipIds.concat(this.user.AddOnsIds);
      return allIds;
  }
  getAddOns() {
      return this.user.AddOns;
  }
  getAddOnIds() {
      return this.user.AddOnsIds;
  }

  getMemberships(customerId: number) {
    let headers = new HttpHeaders({
    //  'Token': this.userProfileAPIToken,
      'Content-Type': 'application/json'
    });
    
    let options =  {
      headers: headers
    }
    
      var memberships = this.httpClient.get<CCMembership[]>(hrHeroConfig.endpoint + '/CustomerCentral/GetMemberships?customerId=' + customerId + '&pubCode=hrhero',options)
        .toPromise();

      return memberships;
  }

  isLimitedAccess() {

    if (this.isMembershipLimited) {

      return true;

    }

    return false;
  }

  getLibraries() {
    //TODO: update to CC libs
    //if (this.user.Memberships != null && this.user.Memberships.length > 0) {
    //  let hrHero = this.user.Memberships.filter(w => w.ApplicationId == "198a42e5-1bd7-430b-8e44-13f5e73432f8");

    //  if (hrHero.length > 0) {
    //    let libraries = hrHero[0].Libraries.split(',');

    //    return libraries;
    //  }

    //} else {
    //  return null;
    //}
    return null;
  }

  isAuthenticated() {
    if (this.userIsHRAuthenticated) {
      return true;
    } else {
      return false;
    }
  }
  verifyUserProfile() {
   
    let headers = new HttpHeaders({
     // 'Token': this.userProfileAPIToken,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    let verifyGet = this.httpClient.get(hrHeroConfig.endpoint + '/UserProfile/VerifyUserProfileAPIToken', options);
    verifyGet.subscribe(val => { }, err => {
      console.log("verify caught error %o", err);
      if (err.status == 401) {
        this.logout();
      }
    });
  }

  populateChildCustomers(heroServiceResult) {
      this.childCustomers = heroServiceResult;
      this.allChildren = heroServiceResult;

      if (this.childCustomers != null) {

          this.childCustomers = this.childCustomers.filter(customer => customer.id != this.getCustomerId());
          this.childCustomers = this.childCustomers.filter(customer => customer.id != this.getParentId());
          this.childCustomers = this.childCustomers.filter(customer => customer.users.length > 0);

          if (this.userProfileTypeId == 1) {
              this.allChildren = this.allChildren.filter(customer => customer.id != this.user.CustomerId);
          }
          else {
              this.allChildren = this.allChildren.filter(customer => customer.id != this.getParentId());
          }
         
          this.allChildren = this.allChildren.filter(customer => customer.users.length > 0);
      }else{
        this.childCustomers = [];
        this.allChildren = [];
      }

      /*if(this.childCustomers.length <= 0){
        this.childCustomers = null;
      }
      if (this.allChildren.length <= 0) {
          this.allChildren = null;
      }*/
  }

  getVisibility(name) {
    if (this.userProfileTypeId == 1) {
      return true;
    } else {
      if (this.userProfile.AdminPreferences.filter(f => f.Name == name).length > 0) {
        return this.userProfile.AdminPreferences.filter(f => f.Name == name)[0].Visible;
      }
    }

    return false;
  }

  getRedirectUrl(returnUrl: string) {

    let data: any;

    data = {
      "userid": this.user.UserId,
      "AuthorizationProfile": "HERO",
      "returnurl": returnUrl
    };

    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    let body = JSON.stringify(data);

    //console.log(data);

    return this.httpClient.post(hrHeroConfig.endpoint + '/Account/GetRedirectUrl', body, options)
      .toPromise();
  }

  specialInquiryRequest(urlExtension: string, secondField: string = ""){
    if(!(hrHeroConfig.usesInquiry)){
      return; //don't send requests from QA/STG/local
    }
    var xmlhttp; var send;
    if ((<any>window).XMLHttpRequest) {
        xmlhttp = new XMLHttpRequest();
    } else {
        xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
    }
    xmlhttp.open("POST", ("https://d1v834xrsg9kw8.cloudfront.net/api/request.php"), true);
    xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xmlhttp.send("&uf="
      + this.user.FirstName + "&ul=" + this.user.LastName + "&ue="
      + this.user.Email + "&uc=" + this.user.Institution + "&us="
      + this.user.SubscriptionType + "&r1=" + hrHeroConfig.origin +
      "&r2=" + secondField + "&r3=" +
      this.user.LastLoginDate + "&uldap=" + this.user.Username +
      "&uccid=" + this.user.CustomerId + "&uccpid=" +
      this.user.ParentCustomerId + "&xu=" + window.location.href + "/" + urlExtension + "&xw="
      + screen.width + "&xh=" + screen.height + "&xn="
      + navigator.userAgent + "&xc=" +
      navigator.cookieEnabled + "&xl=" + navigator.language +
      "&xp=" + navigator.platform + "&xx=13");
  }
  getLitmosSsoLink(email,membershipId){
    let headers = new HttpHeaders({
     // 'Token': this.userProfileAPIToken,
      'Content-Type': 'application/json'
    });
    
    let options =  {
      headers: headers
    }
    let url = hrHeroConfig.endpoint + '/UserProfile/GetLitmosInformationForUser?commonUsername=' + encodeURIComponent(email) + '&membershipId=' + membershipId;
    return this.httpClient.get<string>(url, options)
    .toPromise();
  }
  checkLitmos(){
    let self = this;
    this.getLitmosSsoLink(this.user.Email,this.user.MembershipIds[0]).then(link => {
      if(link != null && link != "N/A"){
        self.showLitmosLogin = true;
        self.litmosLink = link;
      }
    });
  }
}

