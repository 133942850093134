<div *ngIf="dataAvailable == 1">
  <div *ngIf="printVersion">
    <img [src]="headerLogo" height="80" width="140" class="hr-hero-logo" />
  </div>

  <div *ngIf="!printVersion" class="row">
    <div class="col-xs-12 page-head noLeftPadding">
      <h1>Federal and State Topic Analysis</h1>
    </div>
  </div>

  <div class="search-well row">
    <div class="row">
      <div class="col-md-2 search-col">
        <a class="btn-default btn btn-block hero-blue" (click)="toggleAtoZ()">{{toggleAtoZButtonLabel}} Topics A to Z</a>
      </div>

      <div class="col-md-7 search-col" style="vertical-align:top;">
        <div class="tag-control">
          <tag-input [(ngModel)]='tags' theme="minimal" secondaryPlaceholder="Search Topics" placeholder="Search Topics" id="tagSelector" [(inputText)]="searchInput"
                     (onRemove)="itemUpdated()" (onAdd)="itemUpdated()"> </tag-input>
        </div>
      </div>

      <div class="col-md-3 search-col">
        <div class="row">
          <div class="col-md-6">
            <a class="btn-default btn btn-block hero-orange" (click)="search()">Search&nbsp;&nbsp;</a>
          </div>
          <div class="col-md-6">
            <a class="btn-default btn btn-block hero-grey" (click)="clearSearch()">Clear Search</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />
  <div class="row" id="aToZPanel" *ngIf="aToZcategoryTopics != null">
    <div>
      <h3>{{searchResults}}</h3>
    </div>
    <div class="col-lg-3">
      <div *ngFor="let item of aToZcategoryTopics | sortBy : 'TopicName'; let i = index">
        <div *ngIf="aToZcategoryTopics.length < 20 || i < (aToZcategoryTopics.length / 4)">
          <div *ngIf="displayAtoZLabel(aToZcategoryTopics[i].TopicName.charAt(0))" class="a-to-z-char-label">{{aToZLabel}}</div>
          <a class="link-no-color" (click)="goToAnalysisURL(aToZcategoryTopics[i])">{{aToZcategoryTopics[i].TopicName}}</a>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div *ngFor="let item of aToZcategoryTopics | sortBy : 'TopicName'; let i = index">
        <div *ngIf="aToZcategoryTopics.length >= 20 && i >= (aToZcategoryTopics.length / 4) && i < ((aToZcategoryTopics.length / 4) * 2)">
          <div *ngIf="displayAtoZLabel(aToZcategoryTopics[i].TopicName.charAt(0))" class="a-to-z-char-label">{{aToZLabel}}</div>
          <a class="link-no-color" (click)="goToAnalysisURL(aToZcategoryTopics[i])">{{aToZcategoryTopics[i].TopicName}}</a>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div *ngFor="let item of aToZcategoryTopics | sortBy : 'TopicName'; let i = index">
        <div *ngIf="aToZcategoryTopics.length >= 20 && i >= ((aToZcategoryTopics.length / 4) * 2) && i < ((aToZcategoryTopics.length / 4) * 3)">
          <div *ngIf="displayAtoZLabel(aToZcategoryTopics[i].TopicName.charAt(0))" class="a-to-z-char-label">{{aToZLabel}}</div>
          <a class="link-no-color" (click)="goToAnalysisURL(aToZcategoryTopics[i])">{{aToZcategoryTopics[i].TopicName}}</a>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div *ngFor="let item of aToZcategoryTopics | sortBy : 'TopicName'; let i = index">
        <div *ngIf="aToZcategoryTopics.length >= 20 && i >= ((aToZcategoryTopics.length / 4) * 3) && i < aToZcategoryTopics.length">
          <div *ngIf="displayAtoZLabel(aToZcategoryTopics[i].TopicName.charAt(0))" class="a-to-z-char-label">{{aToZLabel}}</div>
          <a class="link-no-color" (click)="goToAnalysisURL(aToZcategoryTopics[i])">{{aToZcategoryTopics[i].TopicName}}</a>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="!printVersion" class="row">
    <div class="col-sm-8 noLeftPadding">
      <div *ngIf="this.relatedContent != null && this.relatedContent.length>0">
        <span style="margin-right:4px;">Related Content</span>
        <span *ngFor="let item of this.relatedContent">
          <span class="badge">{{item.Count}}</span>
          <a (click)="goToRelatedContent(item.Name)" style="cursor:pointer;">
            <span>{{item.ResourceId == -1 ? "Training Materials" : (item.ResourceId == -2 ? "Books" : item.Name)}}</span>
          </a> &nbsp;
        </span>
      </div>
    </div>
    <div *ngIf="!printVersion" class="col-sm-4 noRightPadding">
      <div class="pull-right">
        <bookmarks style="margin-right:4px;" [informationId]="information.InformationId" [informationTypeId]="information.InformationTypeId" [topicId]="information.TopicId" [bookmarkTitle]="information.Title" [stateName]="stateName"></bookmarks>
        <button type="button" class="btn-white" (click)="openPrintOptionsModal(printOptionsModal)">Print Preview</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div *ngIf="!printVersion" class="col-sm-6 noLeftPadding">
      <span class="head2">Category: &nbsp; {{category}}<br /> </span>
      <span class="head2">Topic: &nbsp; {{topicInfo.TopicName}} </span>
    </div>
    <div *ngIf="printVersion" class="col-sm-6 noLeftPadding">
      <span class="mainHead">{{topicInfo.TopicName}}</span>
    </div>
  </div>
  <br />

  <div class="row">
    <div [class]="printVersion ? 'col-sm-12 noLeftPadding noRightPadding': 'col-sm-6 noLeftPadding noRightPadding' ">
      <div *ngIf="showFederal" class="col-sm-6 noLeftPadding" style="padding-top:7px;">
        <span [class]="printVersion ? 'mainHeadRed' : ''"><b>Federal</b> </span>
      </div>
      <div class="col-sm-6 noRightPadding" >
        <div class="pull-right">
          <button type="button" class="btn btn-success noPrint" (click)="toggleAllSections()">{{toggleAllButtonLabel}} all</button>
          <span style="margin-left:4px;" *ngIf="printVersion"><button type="button" class="btn btn-success noPrint" (click)="print()">Print</button>   </span>
        </div>
      </div>
    </div>

    <div *ngIf="!printVersion" [class]="printVersion? 'col-sm-12': 'col-sm-5 col-sm-offset-1 noLeftPadding'">
      <div *ngIf="!printVersion" class="col-sm-6 noLeftPadding">
        <div style="width:200px; margin-bottom:20px;">
          <state-selector [saveDefaultState]="false" [currentStateId]="stateId" [topicAnalysis]=true (onSelectionComplete)="onStateSelectionComplete()"></state-selector>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div *ngIf="showFederal" [class]="printVersion? 'col-sm-12': 'col-sm-6'">
      <div [class]="!printVersion ? 'panel-group' : '' " id="accordion">
        <div *ngIf="this.nationalAnalysis != null" class="row">
          <div *ngFor="let section of this.nationalAnalysis; let c = index">
            <a href="#collapse{{c}}" data-toggle="collapse" data-parent="#accordion" (click)="toggleClass(c)" class="link toggle-header">
              <div [class]="!printVersion ? 'new-panel body-content-label' : ''">
                <span [class]="c== 0 ? 'fa fa-minus plus-minus' : 'fa fa-plus plus-minus'" id="plusMinus_{{c}}"></span> <span [class]="printVersion ? 'contentSectionTitle' : ''">{{section.Title}} </span>
              </div>
            </a>
            <div id="collapse{{c}}" [class]="getCssClass(c)">
              <div [class]="printVersion ? 'para':''" [innerHTML]="section.Body | safeHtml" secureDownload></div>
            </div>
            <div [class]="!printVersion ? 'toggle-seperator': '' "> &nbsp;   </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="printVersion && showState && this.stateInfo != null && this.stateInfo.JurisdictionName != null && this.stateInfo.JurisdictionName != '' && this.stateInfo.JurisdictionName != 'National'" [class]="printVersion? 'col-sm-12 noLeftPadding': 'col-sm-5 col-sm-offset-1 noLeftPadding'" style="margin-top:10px;">
      <span class="mainHeadRed"><b>{{this.stateInfo.JurisdictionName}}</b></span>
    </div>

    <div *ngIf="showState" [class]="printVersion? 'col-sm-12': 'col-sm-5 col-sm-offset-1'">
      <div *ngIf="this.stateInfoLoading"  class="row">
        <span><img style="margin-left:5px; height:60px;width:60px;" src="/assets/images/loading-dots.gif"></span>
      </div>
      <div *ngIf="!this.stateInfoLoading && this.stateAnalysis?.length!=0" class="row">
        <div [class]="!printVersion ? 'panel-group' : '' " id="stateAccordion">
          <div *ngFor="let section of this.stateAnalysis; let s = index">
            <a href="#stateCollapse{{s}}" data-toggle="collapse" data-parent="#stateAccordion" (click)="toggleStateClass(s)" class="link toggle-header">
              <div [class]="!printVersion ? 'new-panel body-content-label' : ''">
                <span [class]="s == 0 ? 'fa fa-minus plus-minus' : 'fa fa-plus plus-minus'" id="statePlusMinus_{{s}}"></span> <span [class]="printVersion ? 'contentSectionTitle' : ''">{{section.Title}} </span>
              </div>
            </a>
            <div id="stateCollapse{{s}}" [class]="getCssClass(s)">
              <div [class]="printVersion ? 'para':''" [innerHTML]="section.Body | safeHtml" secureDownload></div>
            </div>
            <div [class]="!printVersion ? 'toggle-seperator' : '' "> &nbsp;   </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngIf="!printVersion && ((this.stateInfo && ( this.stateInfo.JurisdictionId==52 || this.stateInfo.JurisdictionId=='null' )) || !this.stateInfo)">
          <span *ngIf="!printVersion" [class]="printVersion ? 'para':''">Please select a specific state for review.</span>
        </div>

        <div *ngIf="!this.stateInfoLoading && (!this.stateAnalysis || this.stateAnalysis?.length == 0)">

          <span *ngIf="this.stateInfo && this.stateInfo.JurisdictionId!=52 && topicInfo.TopicId!=120 && topicInfo.TopicId!=118" [class]="printVersion ? 'para':''">
            Analysis for {{this.stateInfo.JurisdictionName}} on {{topicInfo.TopicName}} is not provided.
          </span>
           <span *ngIf="this.stateInfo && this.stateInfo.JurisdictionId!=52 && topicInfo.TopicId==118" [class]="printVersion ? 'para':''">
            For information on payment of wages in {{this.stateInfo.JurisdictionName}}, see the Paychecks analysis.
          </span>

          <span *ngIf="this.stateInfo && this.stateInfo.JurisdictionId!=52 && topicInfo.TopicId==120" [class]="printVersion ? 'para':''">
            Go to the State Law Chart Builder for more information about Personal Leave in {{this.stateInfo.JurisdictionName}}, including voting leave, domestic violence leave, crime victim leave, and small necessities like bone marrow and organ donation, serving as a witness, jury duty, and attending school activities.
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Print options modal-->
<ng-template #printOptionsModal>
  <div class="modal-header modal-header-hr"> Print Options </div>
  <div class="modal-body">
    <input type="checkbox" #chkFederal class="form-check-input" checked /> Federal <br />
    <input type="checkbox" #chkState class="form-check-input" checked /> State

    <div style="margin-top:10px; color:red;">{{errorMessage}}</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="printPreview(chkFederal.checked, chkState.checked)">Print</button>
    <button type="button" class="btn btn-default" (click)="closeModal()">Cancel</button>
  </div>
</ng-template>

<script-hack src="/assets/Scripts/analysis.js" type="text/javascript"></script-hack>
