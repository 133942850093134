<div class="panel panel-default">
  <div class="panel-heading">Default State</div>
  <div class="panel-body">
    <div class="body-content" style="margin-bottom:10px;">Use the following state as my default selected state:</div>

    <div style="width:200px; margin-bottom:20px;"> 
      <state-selector></state-selector>
    </div>
  </div>
</div>
