<h1>My Preferences</h1>

<div class="panel panel-default">
  <div class="panel-heading">Default State</div>
  <div class="panel-body">
    <div class="body-content" style="margin-bottom:10px;">Use the following state as my default selected state:</div>

    <div style="width:200px; margin-bottom:20px;"> 
      <state-selector [saveDefaultState]="true" (onSelectionComplete)="onStateSelectionComplete()"></state-selector>
    </div>
  </div>
</div>

<div id="stateAlert" class="alert alert-success">
    <strong>Preferences have been saved.</strong>
</div>

<div class="panel panel-default" *ngIf="!isChild">
  <div class="panel-heading">Dashboard Lock</div>
  <div class="panel-body">
    <input type="checkbox" [checked]='userProfile.DashboardLock' (click)="saveDashboardLock($event.target.checked)"> Prevent child accounts from rearranging their dashboard widgets. Child dashboards will match your widget order.
  </div>
</div>

<div id="lockAlert" class="alert alert-success">
    <strong>Preferences have been saved.</strong>
</div>

<div *ngIf="userProfile != null && userProfile.Tools != null">

  <div class="panel panel-default">

     <table datatable class="table table-striped" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
      <thead class="thead">
        <tr>
          <th>
            Widget Name
          </th>
          <th>
            Type
          </th>
          <th *ngIf="!useParent">
            Visible on Dashboard
          </th>
          <th>
            Visible on Tools
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of filteredTools">
            <td>{{item.Component}}</td>
            <td>{{item.Type}}</td>
            <td *ngIf="!useParent"><input *ngIf="toolCheckable(item.Id,'dashboard')" type="checkbox" [checked]="item.VisibleOnHome" [ngModel]="item.VisibleOnHome" (ngModelChange)="check($event, item.Id, 'dashboard')" /></td>
            <td><input *ngIf="toolCheckable(item.Id,'tools')" type="checkbox" [checked]="item.VisibleOnTools" [ngModel]="item.VisibleOnTools" (ngModelChange)="check($event, item.Id, 'tools')" /></td>
        </tr>
      </tbody>
    </table> 
  </div>

</div>

<div id="toolAlert" class="alert alert-success">
  <strong>Preferences have been saved.</strong>
</div>
