import { Component, OnInit, TemplateRef } from '@angular/core';
//import { BsModalComponent } from 'ng2-bs3-modal';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'

@Component({
  selector: 'widget-tools-jdm',
  styles: ['>>> .modal-xl { width: 1100px; }'],
  templateUrl: './widget-tools-jdm.html'
})

export class WidgetToolsJDM implements OnInit {

  isLimitedAccess: boolean;
  hasApplication: boolean = false;
  modal: BsModalRef;

  constructor(private _mbService: MembershipDataService, private modalService: BsModalService) { }

  ngOnInit() {

    this.isLimitedAccess = this._mbService.isLimitedAccess();

    //if (this.isLimitedAccess != true) {
      this.hasApplication = this._mbService.hasJDM;
    //}
  }

  navigate() {
    if (this._mbService.isAuthenticated()) {
      location.href = "/job-description-manager";
    } else {
      location.href = "/login";
    }
  }

    openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.show(template);
  }

  closeModal()  {
    this.modal.hide();
  }
}
