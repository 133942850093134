import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import { MembershipDataService } from '../shared/services/membership.data.service'

import  'jquery';


@Component({
  selector: 'jdm',
  templateUrl: './jdm.component.html'
})

export class JDMComponent {

  url: string = null;

  constructor
    (
    private mbDataService: MembershipDataService,
    private sanitizer: DomSanitizer,
    public _router: Router
  ) {

  }

  ngOnInit() {

    if (!this.mbDataService.hasJDM) {
      this._router.navigate(['/tools']);
    }

    this.url = "../../assets/widgets/tools/jdm/jdm-ui/index.html?userId=" + this.mbDataService.user.CustomerId + "&legacyUserId=" + this.mbDataService.user.LegacyUserId + "&legacyParentUserId=" + this.mbDataService.user.LegacyParentUserId + "&apiToken=" + this.mbDataService.jdmAPIToken + "&parentId=" + this.mbDataService.parentUserId;

    $(function ($) {
      var lastHeight = 0, curHeight = 0, $frame = $('#jdmIFrame');
      setInterval(function () {
        curHeight = $frame.contents().find('body').height();
        if (curHeight != lastHeight) {
          $frame.css('height', (lastHeight = curHeight) + 50 + 'px');
        }
      }, 500);
    });

  }

}


