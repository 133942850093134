<div>

      <!--Widget drop down-->
      <!--<select class="form-control" [(ngModel)]="selectedComponent" (ngModelChange)="addComponent($event)">
        <option value="0">-- add component --</option>
        <option *ngFor="let item of availableItems" [ngValue]="item.Id">
          {{item.Component}}
        </option>
      </select>-->

  <!--<a (click)="loginTest()">login test</a>-->


      <div class="body-content-margin-override">
        <tools></tools>
      </div>

      <!--<div class="row"><div id="cwbag" [dragula]='cwbag' [dragulaModel]='CustomWidgets'></div></div>

      <div class='row' *ngIf="customWidgetsVisible">
        <div class='container' [dragula]='"nested-bag"'>
          <div *ngFor='let item of customWidgets' [innerHtml]='getHtml(item) | safeHtml' [ngClass]="item.WidthClass"></div>
        </div>
      </div>-->
      
      <h2 *ngIf="resourcesVisible">Latest News</h2>
      <div *ngIf="resourcesVisible" class="white-well">
        <div>
          <latest-news></latest-news>
        </div>
      </div>

      <h2 *ngIf="trainingVisible">New Training Materials</h2>
      <div *ngIf="trainingVisible" class="white-well">
        <div >
          <training-new></training-new>
        </div>
      </div>
 
</div>
