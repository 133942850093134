import { Component, Provider, ElementRef, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pending-login',
  templateUrl: './pending-login.component.html',
  styleUrls: ['./login.component.css'],
})

export class PendingLoginComponent {

  constructor(
    public _router: Router,
    public _route: ActivatedRoute
  ) { }

    ngOnInit(){
        console.log("Autologin in progress...");
    }
}
