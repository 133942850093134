<a *ngIf="!isLimitedAccess && (documentType == 'doc' || documentType == 'docx')" target="_blank" class="btn-green" (click)="download()" role="button"><img src="../../assets/images/green-doc.PNG" />Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'xls' || documentType == 'xlsx')" target="_blank" class="btn-green" (click)="download()" role="button"><img src="../../assets/images/green-xls.PNG" />Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'pdf')" target="_blank" class="btn-green" (click)="download()" role="button"><img src="../../assets/images/green-pdf.PNG" />Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'ppt' || documentType == 'ppsx' || documentType == 'pptx')" target="_blank" class="btn-green" (click)="download()" role="button"><img src="../../assets/images/green-ppt.jpg" />Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'html' || documentType == 'htm'|| documentType == 'jpg' || documentType == 'jpeg' || documentType == 'png' || documentType == 'rtf' ||documentType == 'mp3' ||documentType == 'RTF')" target="_blank" class="btn-green" (click)="download()" role="button">Download Now</a>
<a *ngIf="!isLimitedAccess && (documentType == 'audio') && (informationLink?.length > 10)" target="_blank" [routerLink]="['/audio-presentations',informationLink]" class="btn-green" (click)="notifyInquiry()">Go</a>

<a *ngIf="isLimitedAccess && (documentType != 'audio')" (click)="openModal(modal)" class="btn-green">Download Now</a>
<a *ngIf="isLimitedAccess && (documentType == 'audio')" class="btn-green" (click)="openModal(modal)">Go</a>

<!--bookmark modal-->
<ng-template #modal>
  <div class="modal-header modal-header-hr">
    Limited Access
  </div>
  <div class="modal-body">
    <h1>
      Downloads are reserved for HR Hero subscribers.
    </h1>

    <div class="body-content">
      <a href="http://store.blr.com/hrhero" target="_blank">Upgrade now</a> or <a href="http://interactive.blr.com/hrhero-demo-request" target="_blank">request a demo</a> for immediate access to a hundreds of downloadable forms, checklists, and instructor led training materials.
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="closeModal()">Close</button>
  </div>
</ng-template>
