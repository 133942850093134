import { Component, TemplateRef, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
//import { BsModalComponent } from 'ng2-bs3-modal';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'

import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { hrHeroConfig } from '../../../../../src/environments/environment'

@Component({
  selector: 'widget-tools-ehb',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './widget-tools-ehb.html'
})

export class WidgetToolsEHB {

  isLimitedAccess: boolean;
  modal: BsModalRef;
  constructor(private _mbService: MembershipDataService, private modalService: BsModalService) { }

  ngOnInit() {

    this.isLimitedAccess = this._mbService.isLimitedAccess();

  }

  click() {

    //window.open("https://handbookbuilder.blr.com/Login.aspx", '_blank');
    window.open(hrHeroConfig.ehbURL, '_blank');
  }

  openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.show(template);
  }

  closeModal()  {
    this.modal.hide();
  }
}
