import { Component, Provider, ElementRef, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Account } from '../shared/types/account.type';
import { MembershipDataService } from '../shared/services/membership.data.service'
import { UserProfileService } from '../shared/services/user-profile.service'
import {HttpClient} from '@angular/common/http'
import { authConfig, hrHeroConfig } from '../../environments/environment'
import  'jquery';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent {

  userNameValue: string = null;
  passwordValue: string = null;
  loginResponse: string;
  authenticationSystem: string = "WA";
  route: string = "";
  copy: string = "HR Hero";
  authToken: string;
  forgotLink: string;

  constructor(
    public _router: Router,
    public _route: ActivatedRoute,
    private _mbService: MembershipDataService,
    private http: HttpClient,
    private _ups: UserProfileService
  ) { }

  ngOnInit() {

    this.forgotLink = hrHeroConfig.ccLogoutUrl + '/account/forgot';
    $('.imgloader').css('visibility', 'visible');
    $('#btnSubmit, #loginForm, #infoBox').hide();
    if (this._route.snapshot.queryParams["auth_token"] != null) {

      this.authToken = this._route.snapshot.queryParams["auth_token"];

      this.authenticate();

    } else {
      this._route.params.subscribe(params => {

        let path = 'dashboard';

        if (params["route"] != null) {
          this.route = params["route"];
        }

        switch (this.route) {
          case 'home':
            this.copy = "HR Hero";
            break;
          case 'ehb':
            this.copy = "Employee Handbook Builder";
            this.authenticationSystem = "WA";
            break;
          case 'jdm':
            this.copy = "Job Description Manager";
            path = 'job-description-manager';
            break;
          case 'hotline':
            this.copy = "HR Hotline";
            path = 'hotline';
            break;
          case 'slcb':
            this.copy = "State Law Chart Builder";
            path = 'state-law-chart-builder';
            break;
          case 'flsa':
            this.copy = "Job Classification & FLSA Audit";
            this.authenticationSystem = "WA";
            break;
          default:
            this.copy = "HR Hero";
            break;
        }

        if (this._mbService.isAuthenticated()) {

          switch (this.route) {
            case 'ehb':
              location.href = "http://handbookbuilder.blr.com/checkssologin.aspx";
              break;
            case 'flsa':
              location.href = "https://flsa.smarthrmanager.com/CheckSSOlogin.aspx";
              break;
          }

          this._router.navigate([path]);
        }else{
          $('.imgloader').css('visibility', 'hidden');
          $('#btnSubmit, #loginForm, #infoBox').show();
        }

      });
    }


  }

  iframeClick() {

      var iframe = $("#myiFrame");
      iframe.attr("src", iframe.data("src"));


  }

  logIframeEvent(item) {

    console.log("iframe src", item);
  }

  login(userName, password, authSystem = null) {

    if (userName == "" || password == "" || userName == null || password == null) {

      this.loginResponse = "Username or password incorrect";

      return;
    }

    var _this = this;

    if (authSystem != null) {
      this.authenticationSystem = authSystem;
    }

    $('.imgloader').css('visibility', 'visible');
    $('#btnSubmit, #loginForm, #infoBox').hide();

    //localStorage.setItem("un", userName);
    //localStorage.setItem("pw", password);

    $.ajax({
      type: 'POST',
      url: hrHeroConfig.endpoint + "/account/GetSessionRedirectUrl",
      xhrFields: {
        withCredentials: true
      },
      data: {
        UserName: userName,
        Password: password,
        AuthenticationSystem: this.authenticationSystem,
        ApplicationId: hrHeroConfig.applicationId
      },
      crossDomain: true,
      success: function (data, textStatus, jqXHR) {

        location.href = data;

      }
    });
  }

  authenticate(authSystem = null) {

    let _this = this;

    //this.userNameValue = localStorage.getItem('un');
    //this.passwordValue = localStorage.getItem('pw');

    $('.imgloader').css('visibility', 'visible');
    $('#btnSubmit, #loginForm, #infoBox').hide();

    $.ajax({
      type: 'POST',
      url: hrHeroConfig.endpoint + "/account/SsoSessionLogin",
      xhrFields: {
        withCredentials: true
      },
      data: {
        UserName: null,
        Password: null,
        AuthenticationSystem: this.authenticationSystem,
        ApplicationId: hrHeroConfig.applicationId,
        AuthToken: this.authToken
      },
      crossDomain: true,
      success: function (data, textStatus, jqXHR) {

        if (data == "Username or password incorrect") {

          if (_this.authenticationSystem == "WA") {
            if (_this.route.toLowerCase() == 'ehb') {
              _this.authenticationSystem = "EHB";
              _this.authenticate("EHB");

              $('.imgloader').css('visibility', 'hidden');
              $('#btnSubmit, #loginForm, #infoBox').show();

              return;
            }
            if (_this.route.toLowerCase() == 'flsa') {
              _this.authenticationSystem = "FLSA";
              _this.authenticate("FLSA");

              $('.imgloader').css('visibility', 'hidden');
              $('#btnSubmit, #loginForm, #infoBox').show();

              return;
            }
          }

          _this.loginResponse = data;

          $('.imgloader').css('visibility', 'hidden');
          $('#btnSubmit, #loginForm, #infoBox').show();

          return;

        }

        var account = data;

        //authentication failed
        if (account == null) {
          this._router.navigate(['login']);
          return false;
        }

        if (account.Authorized == false || account.Authorized == "false") {

          if (_this.authenticationSystem == "WA") {
            if (_this.route.toLowerCase() == 'ehb') {
              _this.authenticationSystem = "EHB";
              _this.authenticate("EHB");

              return;
            }
            if (_this.route.toLowerCase() == 'flsa') {
              _this.authenticationSystem = "FLSA";
              _this.authenticate("FLSA");

              return;
            }
          }

          if (account.ResponseMessage != "") {

            _this.loginResponse = account.ResponseMessage;

          }
          else {

            _this.loginResponse = "This account is not active. Please contact Customer Service at 800-727-5257.";

          }

          $('.imgloader').css('visibility', 'hidden');
          $('#btnSubmit, #loginForm, #infoBox').show();

          //return;

        }

        //if (account.Authorized != null && account.Authorized == true) {

        switch (_this.route) {
          case 'ehb':
            location.href = "http://handbookbuilder.blr.com/checkssologin.aspx";
            break;
          case 'flsa':
            location.href = "https://flsa.smarthrmanager.com/CheckSSOlogin.aspx";
            break;
        }

        _this._mbService.setUpAccount(account);
        _this.processLogin(_this, account);
        //}

        //$('.imgloader').css('visibility', 'hidden');
        //$('#btnSubmit, #loginForm, #infoBox').show();
      }
    });
  }

  processLogin(_this, account) {

    if (!account.Authorized) {
      let navigateToTools: boolean = false;

      //jdm
      if (account.Roles && account.Roles.filter(f => f == "JDM").length > 0) {
        navigateToTools = true;
        _this._router.navigate(['tools']);
      }

      //slcb
      if (account.Roles && account.Roles.filter(f => f == "SLCB").length > 0) {
        navigateToTools = true;
        _this._router.navigate(['tools']);
      }

      if (navigateToTools) {
        switch (_this.route) {
          case 'jdm':
            _this._router.navigate(['job-description-manager']);
            break;
          case 'slcb':
            _this._router.navigate(['state-law-chart-builder']);
            break;
          default:
            _this._router.navigate(['tools']);
            break;
        }
      }

      _this.loginResponse = "This account is not authorized.";
    }
    else {

      _this._mbService.userIsHRAuthenticated = true;
      _this._mbService.userIsEHSAuthenticated = false;
      if (localStorage.getItem('ru')) {
        var url = localStorage.getItem('ru');
        localStorage.removeItem('ru');
        window.location.href = window.location.protocol + '//' + window.location.hostname + '/hr' + url;
      } else {
        _this._router.navigate(['dashboard']);
      }

      switch (_this.route) {
        case 'home':
          _this._router.navigate(['dashboard']);
          break;
        case 'jdm':
          _this._router.navigate(['job-description-manager']);
          break;
        case 'hotline':
          _this._router.navigate(['hotline']);
          break;
        case 'slcb':
          _this._router.navigate(['state-law-chart-builder']);
          break;
        default:
          _this._router.navigate(['dashboard']);
          break;
      }
    }
  }
}
