import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlightText'
})

export class HighlightSearchPipe implements PipeTransform {

    transform(text: any, searchKeywords: any): any {

        searchKeywords.forEach(function (keywordObj) {
            if (!keywordObj) { return text; }

            var regex = new RegExp(keywordObj.value, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
            text = text.replace(regex, function (str) { return "<span class='yellow'>" + str + "</span>"  });
        });

        return text;
    }
}