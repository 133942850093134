import { Component, TemplateRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';
import { DataTableDirective } from 'angular-datatables';

//services
import { HrHeroService } from '../shared/services/hr-hero.service';
import { Subject} from 'rxjs';

//types
import { Bookmark } from '../shared/types/bookmark.type';
import { BookmarkFolder } from '../shared/types/bookmark-folder.type'
import { hrHeroConfig } from '../../environments/environment'


@Component({
  selector: 'myBookmarks',
  templateUrl: './myBookmarks.component.html',
  styleUrls: ['./myBookmarks.component.css']
})

export class MyBookmarksComponent implements OnDestroy, OnInit {

  modal: BsModalRef;

  bookmarkFolderModal: BsModalRef;
  dataAvailable: number = 0;
  bookmarks: Bookmark[];
  bookmarkFolders: BookmarkFolder[];
  bookmarkFolderName: string = null;
  bookmarkFolderLabel: string = "All";

  selectedBookmark: Bookmark;
  selectedBookamrkFolderId: number = null;
  errorMessage: string;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  loading: boolean = false;

  constructor(private _heroService: HrHeroService, private modalService: BsModalService) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      ordering: false,
      info: false,
      language : {
        lengthMenu: '_MENU_',
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
      dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
    };
    this.loading = true;
    this.getBookmarkFolders();
    this.getBookmarks();
    
  }

 getBookmarks() {
    
    this.bookmarkFolderLabel = null;
    this._heroService.getBookmarks("user", null).then(result => {
      if (result.length > 0) {
        this.dataAvailable = 1;
        this.bookmarks = result;
        //append information IDs to training records
        this.bookmarks.forEach(bm => {
          if(hrHeroConfig.trainingTypes.includes(bm.InformationTypeId)){
            bm.URL += '/' + bm.InformationId;
          }
        });
        this.loading = false;
        this.rerender();
      } else {
        this.dataAvailable = 0;
        this.bookmarks = null;
        this.loading = false;
      }
      
    });
  }

  getBookmarksByBookmarkFolder(bookmarkFolderId, bookmarkFolderName) {

    this.bookmarkFolderLabel = bookmarkFolderName;

    this._heroService.getBookmarks("folder", bookmarkFolderId).then(result => {
      if (result.length > 0) {
        this.dataAvailable = 1;
        this.bookmarks = result;
        //append information IDs to training records
        this.bookmarks.forEach(bm => {
          if(hrHeroConfig.trainingTypes.includes(bm.InformationTypeId)){
            bm.URL += '/' + bm.InformationId;
          }
        });
      } else {
        this.bookmarks = null;
        this.dataAvailable = 0;
      }
      this.rerender();
    });
  }

  saveBookmark(bookmark: Bookmark) {
    this._heroService.updateMyBookmark(bookmark);
    this.selectedBookamrkFolderId = null;
    this.modal.hide();
  }

  deleteBookmark(bookmarkId: number) {

    if (confirm("Are you sure you want to delete this bookmark?")) {
      this._heroService.deleteMyBookmark(bookmarkId);

      this.modal.hide();
    }
  }

  bookmarkSaved(event) {

    if (event != 'open') {
      this.getBookmarkFolders();

      this.getBookmarks();
    }
  }

  getBookmarkFolders() {

    this._heroService.getBookmarkFolders().then(result => {
      if (result != null) {
        this.bookmarkFolders = result;
      } else {
        this.bookmarkFolders = null;
      }
    });
  }

  addBookmarkFolder(name) {

    this._heroService.addBookmarkFolder(name, this.selectedBookamrkFolderId).subscribe((data) => {
      var result = JSON.parse(data)
      if (result == "success") {
        this.getBookmarkFolders();
        this.bookmarkFolderName = "";
        this.bookmarkFolderModal.hide();
        this.selectedBookamrkFolderId = null;
      } else {
        this.errorMessage = result;
      }

    });
  }

  editBookmarkFolder(folder: BookmarkFolder, template: TemplateRef<any>) {

    this.selectedBookamrkFolderId = folder.BookmarkFolderId;
    this.bookmarkFolderName = folder.Name;
    this.openBookmarkFolderModal(template);
  }

  deleteBookmarkFolder() {

    if (confirm("Are you sure you want to delete this folder?")) {
      this._heroService.deleteBookmarkFolder(this.selectedBookamrkFolderId).then((data) => {

        if (data == "success") {
          this.getBookmarkFolders();

          this.getBookmarks();

          this.bookmarkFolderName = "";
          this.bookmarkFolderLabel = "All";

          this.closeBookmarkFolderModal();

        } else {
          this.errorMessage = data.toString();
        }
      });
    }
  }


  openBookmarkFolderModal(template: TemplateRef<any>) {
    this.bookmarkFolderModal = this.modalService.show(template);
  }

  closeBookmarkFolderModal() {
    this.bookmarkFolderModal.hide();
    this.bookmarkFolderName = "";
    this.errorMessage = "";
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    if(this.dtElement && this.dtElement.dtInstance)
    {           
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });  
        
      }  
      else if (this.dtElement)
      {      
          this.dtTrigger.next();     
      }
    }
}
