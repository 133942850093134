import { Component, Provider, ElementRef, OnInit, Input, Compiler } from '@angular/core';

@Component({
  selector: 'account-settings',
  templateUrl: './account-settings.component.html'
})

export class AccountSettingsComponent {

  constructor() { }

}
