import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';

import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'

@Component({
  selector: 'widget-tools-help',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './widget-tools-help.html'
})

export class WidgetToolsHelp {
  isLimitedAccess: boolean;

  constructor(private _mbService: MembershipDataService) { }

  ngOnInit() {

    this.isLimitedAccess = this._mbService.isLimitedAccess();
  }

  navigate() {
    if (this._mbService.userIsHRAuthenticated) {
      console.log("deprecated navigation");
    } else {
      location.href = "/login";
    }
  }
}

