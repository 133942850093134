import { Component, TemplateRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

//types
import { Information } from '../../shared/types/information.type'
import { BookmarkFolder } from '../../shared/types/bookmark-folder.type'

//services
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { HrHeroService } from '../../shared/services/hr-hero.service'

@Component({
  selector: "bookmarks",
  templateUrl: './bookmarks.component.html'
})

export class BookmarkComponent implements OnInit {

  modal: BsModalRef;

  @Output() bookmarkSaved = new EventEmitter();

  bookmarkFolders: BookmarkFolder[];
  selectedBookmarkFolder: BookmarkFolder;
  errorMessage: string = "";

  @Input() informationId: number = 0;
  @Input() informationTypeId: number = 0;
  @Input() categoryId: number = 0;
  @Input() topicId: number = 0;
  @Input() bookmarkFolderId: number = 0;
  @Input() bookmarkTitle: string;
  @Input() bookmarkId: number;
  @Input() stateName: string = ''; //no state in particular

  constructor(private _heroService: HrHeroService, private _mbDataService: MembershipDataService, private modalService: BsModalService) { }

  ngOnInit() { }

  getBookmarkFolders() {
    this._heroService.getBookmarkFolders().then(result => {
      if (result != null) {
        this.bookmarkFolders = result;
        this.setDefaultBookmarkFolder(this.bookmarkFolderId);

        this.bookmarkFolders.unshift(this.selectedBookmarkFolder);

      }
    });
  }

  saveBookmark() {

    this._heroService.saveBookmark(

      this.informationId,
      this.bookmarkTitle,
      this._mbDataService.getCustomerId(),
      (this.selectedBookmarkFolder == null) ? 0 : this.selectedBookmarkFolder.BookmarkFolderId,
      this.informationTypeId,
      this.categoryId,
      this.topicId,
      this.bookmarkId,
      this.stateName

    ).subscribe((data) => {
      var result = JSON.parse(data)

      if (result == "success") {

        this.bookmarkSaved.emit('close');
        this.modal.hide();
        //this.clearSelections();
        this.errorMessage = "";

      }
      else
      {
        this.errorMessage = result;
      }

    });
  }

  deleteBookmark(bookmarkId) {
    if (confirm("Are you sure you want to delete this bookmark?")) {
      this._heroService.deleteMyBookmark(bookmarkId).subscribe((data) => {

        if (data == "success") {
          this.bookmarkSaved.emit('close');
          this.dismissBookmarkModal();
        } else {
          this.errorMessage = JSON.parse(data["_body"]);
        }

      });
    }
  }

  openBookmarkModal(template: TemplateRef<any>) {
    this.getBookmarkFolders();
    this.modal = this.modalService.show(template);
    this.bookmarkSaved.emit('open');
  }

  dismissBookmarkModal() {
    this.modal.hide();
    this.errorMessage = "";
    this.bookmarkSaved.emit('close');
  }

  clearSelections() {
    this.errorMessage = "";
    this.bookmarkTitle = "";
    this.bookmarkFolderId = 0;
    this.setDefaultBookmarkFolder();
  }

  setDefaultBookmarkFolder(bookmarkFolderId = 0) {
    this.selectedBookmarkFolder = new BookmarkFolder();
    if (bookmarkFolderId) {
      var selectedBookmark = this.bookmarkFolders.filter(x => x.BookmarkFolderId == bookmarkFolderId)[0];
      this.selectedBookmarkFolder.Name = selectedBookmark.Name;
      this.selectedBookmarkFolder.BookmarkFolderId = selectedBookmark.BookmarkFolderId;
    } else {
      this.selectedBookmarkFolder.Name = "Bookmarks (default)";
      this.selectedBookmarkFolder.BookmarkFolderId = 0;
    }
  }

}
