<div class="tool-image-container"><img class="tool-image" src="/ClientApp/hero.hr/Hero.HR.Client/dist/assets/images/covidwidget.png" style="width: 144px;" /></div>

<div class="tool-content">
  <div class="nav-sub-link">Coronavirus (COVID-19)</div>
  <div class="blue-tool-line-container">
    <div class="blue-tool-line"></div>
  </div>
  <div class="body-content-italic">Access HR Hero resources that relate to Coronavirus (COVID-19)</div>
</div>

<div style="padding-top:25px;">
  <a class="btn-blue-sm" [routerLink]="['/search/Coronavirus']">Get Started</a> 
</div>