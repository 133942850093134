import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HrHeroService } from '../shared/services/hr-hero.service';

@Component({
  selector: 'hidden-reindex',
  templateUrl: './hidden-reindex.component.html',
  styleUrls: ['./hidden-reindex.component.css']
})

export class HiddenReindexComponent {

    timestrings: string[];
    intervals: number[];
    typeIds: number[] = [20, 55, 105, 111, 57, 58, 60, 100, 104, 112, 16, 61, 32, 106, 7, 54, 117, 103, 101, 115, 113, 28, 119, 120]
    times: Date[];
    states: string[];

    constructor(
      public _route: ActivatedRoute,
      private _hr: HrHeroService
    ) { 
        this.timestrings = [];
        this.times = [];
        this.states = [];
        this.intervals = [];
        for(let i = 0; i < this.typeIds.length; i++){
            this.timestrings.push("0 seconds");
            this.times.push(new Date());
            this.states.push("STANDBY");
            this.intervals.push(0);
        }
        
    }
  
    ngOnInit() {
      
    }
    /*fakeIndexType(index){
        this.states[index] = "RUNNING";
        this.times[index] = new Date();
        let intervalIndex = index;
        let self = this;
        this.intervals[index] = window.setInterval(function(){
            self.timestrings[intervalIndex] = self.runtimeOf(intervalIndex);
        },1000);
        window.setTimeout(function(){
            self.states[intervalIndex] = "ERROR";
            self.timestrings[intervalIndex] = self.runtimeOf(intervalIndex);
            window.clearInterval(self.intervals[intervalIndex]);
        },15000);
    }*/
    indexType(index){
        this.states[index] = "RUNNING";
        this.times[index] = new Date();
        let intervalIndex = index;
        let self = this;
        this.intervals[index] = window.setInterval(function(){
            self.timestrings[intervalIndex] = self.runtimeOf(intervalIndex);
        },1000);

        this._hr.reindexType(this.typeIds[index]).then(result => {
            if(result == "success"){
                self.states[intervalIndex] = "DONE";
                self.timestrings[intervalIndex] = self.runtimeOf(intervalIndex);
                window.clearInterval(self.intervals[intervalIndex]);
            }else{
                self.states[intervalIndex] = "ERROR";
                self.timestrings[intervalIndex] = self.runtimeOf(intervalIndex);
                window.clearInterval(self.intervals[intervalIndex]);
            }
        })
    }
    cancelType(index){
        this.states[index] = "STOPPED";
        this.timestrings[index] = this.runtimeOf(index);
        window.clearInterval(this.intervals[index]);
    }
    typeFinished(index){
        
    }
    runtimeOf(index){
        let time = new Date();
        let milliseconds = time.getTime() - this.times[index].getTime();
        let seconds = Math.floor(milliseconds/1000);
        let minutes = Math.floor(milliseconds/60000);
        let adjustedSeconds = Math.floor( (milliseconds % 60000) / 1000);
        if(minutes > 0){
            return minutes + " minutes and " + adjustedSeconds + " seconds"
        }else{
            return seconds + " seconds";
        }
    }
    
  }