import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HrHeroService } from '../../shared/services/hr-hero.service';
import { UserProfileService } from '../../shared/services/user-profile.service';
import { MembershipDataService } from '../../shared/services/membership.data.service';
import { DataTableDirective } from 'angular-datatables';

import { User } from '../../shared/types/membershipbase-user'
import { UserProfile } from '../../shared/types/user-profile.type';
import { CCCustomer } from '../../shared/types/cc-customer';
import { CCUser } from '../../shared/types/cc-user';
import { CCMembership } from '../../shared/types/cc-membership';
import { Subject} from 'rxjs';

@Component({
    selector: 'user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.css']
})

export class UserManagementComponent implements OnDestroy, OnInit {
  hasAccess: boolean = true;
  loading: boolean = true;
  selectedCount: number = 0;
  parentMemberships: CCMembership[];
  membershipsAvailable: boolean = false;
  childCustomersMaster: CCCustomer[];
  childCustomers: CCCustomer[];
  availableCount: number = 0;
  isFiltering: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(private _up: UserProfileService, private _heroService: HrHeroService, private _mb: MembershipDataService,
      public _route: ActivatedRoute, private _router: Router) { 
}

  ngOnInit() {
    this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        searching: true,
        ordering: true,
        order: [[1,"asc"]],
        info: false,
        autoWidth: true,
        language : {
          search: "",
          emptyTable: "No additional users. To add a user, click ADD NEW USER.",
          zeroRecords:"No users found matching your search criteria. Please try another search.",
          searchPlaceholder: "Search",
          lengthMenu: '_MENU_',
          paginate: {
                first: '<<',
                last: '>>',
                next: '>',
                previous: '<'
            }
          },
          columnDefs: [
            {targets: [6], searchable: !1, orderable: !1},
            {targets: [7], searchable: !1, orderable: !1}
         ],
         lengthMenu : [5, 10,25],
         dom:  '<"input-group col-md-3"<"col pull-left" f>><"panel panel-default" t<"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
      };


      this.hasAccess = this._mb.getVisibility('User Management') && this._mb.AutherizationType==1;

      if (this.hasAccess)
      {
         this.getCCUsers();
      };
  }

  getCCUsers() {
      this.childCustomers = new Array<CCCustomer>();
      this.childCustomersMaster = new Array<CCCustomer>();

          this._heroService.getCustomerCentralUsers().then(result => {
              this._mb.populateChildCustomers(result);
              this.childCustomersMaster = this._mb.childCustomers;
              this.childCustomers = this._mb.childCustomers;
              var parentId = this._mb.user.CustomerId;
              this.getMemberships(parentId);
              this.loading = false;
              this.rerender();
          });
    }

  getMemberships(customerId: number) {
      this._mb.getMemberships(customerId).then(result => {
          this.parentMemberships = result;
          var childrenMembershipCount = 0;
          var addOnCount = 0;
          var addOnChildren = null;
          var addOnUsedCount = 0;

          for (var i = 0; i < this.parentMemberships.length; i++) {
              childrenMembershipCount = this._mb.allChildren.filter(child => child.memberships.filter(memb => memb.id == this.parentMemberships[i].id).length > 0).length;
              addOnCount = this._mb.allChildren.filter(child => child.addOns ? child.addOns.filter(memb => memb.id == this.parentMemberships[i].id).length > 0 : 0).length;
              addOnChildren = this._mb.allChildren.filter(child => child.addOns ? child.addOns.filter(memb => memb.id == this.parentMemberships[i].id) : null);
            addOnUsedCount = (addOnChildren && addOnChildren.length>0) ? addOnChildren[0].addOns[0].usedAccounts - 1 : 0;

              this.parentMemberships[i].usedChildren = childrenMembershipCount > 0 ? childrenMembershipCount : addOnUsedCount ;
          }
          this.membershipsAvailable = true;
      });
  }

  getExpirationDate(membership: CCMembership) {
    return membership.effectiveDates.sort((a, b) => { if (a.stop_date < b.stop_date) return 1; else if (a.stop_date > b.stop_date) return -1; else return 0 })[0].stop_date;
  }

  filterKeyword(keywordValue: string) {

      if (keywordValue && keywordValue.trim().length > 0) {
        this.childCustomers = this.childCustomersMaster.filter(f => (keywordValue.toLowerCase() == '' ||
            ((f.profileAddress && f.profileAddress.first_name)  ? f.profileAddress.first_name.toLowerCase().includes(keywordValue.toLowerCase()) : '') ||
            ((f.profileAddress && f.profileAddress.last_name) ? f.profileAddress.last_name.toLowerCase().includes(keywordValue.toLowerCase()) : '') ||
            ((f.profileAddress && f.profileAddress.company) ? f.profileAddress.company.toLowerCase().includes(keywordValue.toLowerCase()) : '') ||
            ((f.profileAddress && f.email) ? f.email.toLowerCase().includes(keywordValue.toLowerCase()) : '') ||
            f.users[0].username.toLowerCase().includes(keywordValue.toLowerCase())));
        this.isFiltering = true;
      }
      else {
          this.childCustomers = this.childCustomersMaster;
          this.isFiltering = false;
      }
  }

  removeUsers() {
      if (confirm("Are you sure you want to remove this user?")) {
          let selectedUsers = this.childCustomers.filter(user => user.IsSelected);

          for (let user of selectedUsers) {
              
              this._heroService.removeChildAcct(user.memberships[0].id, user.id).then(result => {
                  if (user.addOns && user.addOns.length > 0) {
                      this._heroService.removeChildAcct(user.addOns[0].id, user.id).then(res => {
                          this.selectedCount--;
                          this.childCustomers = this.childCustomers.filter(item => item.id != user.id);
                          this.childCustomersMaster = this.childCustomersMaster.filter(item => item.id != user.id);
                          if (this.selectedCount == 0) {
                              this.getMemberships(this._mb.user.CustomerId);
                          }
                      });
                  }
                  else {
                      this.selectedCount--;
                      this.childCustomers = this.childCustomers.filter(item => item.id != user.id);
                      this.childCustomersMaster = this.childCustomersMaster.filter(item => item.id != user.id);
                      if (this.selectedCount == 0) {
                          this.getMemberships(this._mb.user.CustomerId);
                      }
                  }
              });   
          }   
      }
  }

  setSelectedFlag(e) {
      if (e.target.checked==true){
          this.selectedCount++;
      }
      else {
          this.selectedCount--;
      }
  }

  sortByUsername = (child: any) => {
      var username = child.users[0].username.toLowerCase();
      return username;
  }

  sortByMembership = (item: any) => {
      var membership = item.memberships ? item.memberships[0].plan.name : '' + item.addOns ? ',' : ''
      var addOn = item.addOns ? item.addOns[0].plan.name : '';
      var result = membership + (addOn ? (',' + addOn) : '');

      return result;
  }

  addDays(date: Date, days: number): Date {
      var newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 4);

      return newDate;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    if(this.dtElement && this.dtElement.dtInstance)
    {           
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });  
        
      }  
      else if (this.dtElement)
      {      
          this.dtTrigger.next();     
      }
    }
}
