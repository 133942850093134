import { Component, TemplateRef, ElementRef, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector:"back-to-top",
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.css']
})

export class BackToTopComponent {


  constructor() { }

  windowScrolled = false;

  ngOnInit() {
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset !== 0;
    });
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }
}
