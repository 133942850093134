
<div *ngIf="informationSelected != null">
  <div *ngIf="informationSelected != null" style="display:none;">Unified ID: {{informationSelected.SourceInfoId}}</div>
  <h2 class="seperator">{{informationSelected.Title}}</h2>
  <div class="body-content body-content-padding"><span class="body-content-label">Document Type: </span>{{informationSelected.InformationTypeName}}</div>
  <div class="body-content body-content-padding"><span class="body-content-label">Topic: </span>{{informationSelected.TopicDesc}}</div>
  <div class="body-content body-content-padding"><span class="body-content-label">Summary: </span><div [innerHtml]="informationSelected.Teaser"></div></div>
  <div dynamicAnchor [innerHTML]="informationSelected.Body"></div>


  <div class="document-btns" style="padding-top:15px; padding-bottom:20px;">
    <download-information *ngIf="linkType(informationSelected) == 3" [informationId]="informationSelected.InformationId" [documentType]="informationSelected.DocumentType" [fileName]="informationSelected.Title"></download-information>
    <a *ngIf="linkType(informationSelected) == 2" [href]="informationSelected.FilePath" target="_blank" class="btn-green" id="goBtn">Go</a>

    <bookmarks style="margin-left:4px;" [bookmarkTitle]="informationSelected.Title" [informationId]="informationSelected.InformationId" [informationTypeId]="informationSelected.InformationTypeId" [topicId]="informationSelected.TopicId"></bookmarks>
  </div>
</div>