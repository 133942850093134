<div *ngIf="userProfile != null && userProfile.Tools != null && available">
  <div class="row">
    <div [dragula]='currentRoute' [dragulaModel]='useParent ? parentUserProfile.Tools : userProfile.Tools'>

      <ng-container *ngIf="currentRoute == 'dashboard'">

        <div *ngFor="let item of (useParent ? parentUserProfile.Tools : userProfile.Tools) | sortBy: 'PositionOnHome'; let i = index" [ngClass]="getToolClass(item)">
 

          <div [ngClass]="useParent ? 'white-well no-drag' : 'white-well bag-item'" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Employee Handbook Builder' && ehbVisible && checkVisibility(item) && isAuthenticated">
            <span *ngIf="!useParent" class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-ehb></widget-tools-ehb>
          </div>
          <div [ngClass]="useParent ? 'white-well no-drag' : 'white-well bag-item'" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'State Law Chart Builder' && slcbVisible && checkVisibility(item)">
            <span *ngIf="!useParent" class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-slcb></widget-tools-slcb>
          </div>
          <div [ngClass]="useParent ? 'white-well no-drag' : 'white-well bag-item'" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Job Classification & FLSA Audit' && flsaVisible && checkVisibility(item) && isAuthenticated">
            <span *ngIf="!useParent" class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-flsa></widget-tools-flsa>
          </div>
          <div [ngClass]="useParent ? 'white-well no-drag' : 'white-well bag-item'" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Job Description Manager' && jdmVisible && checkVisibility(item)">
            <span *ngIf="!useParent" class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-jdm></widget-tools-jdm>
          </div>
          <div [ngClass]="useParent ? 'white-well no-drag' : 'white-well bag-item'" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Salary Finder' && sfVisible && checkVisibility(item)">
            <span *ngIf="!useParent" class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-sf></widget-tools-sf>
          </div>
          <div [ngClass]="useParent ? 'white-well no-drag' : 'white-well bag-item'" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Help widget' && helpVisible && checkVisibility(item)">
            <span *ngIf="!useParent" class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-help></widget-tools-help>
          </div>
          <div [ngClass]="useParent ? 'white-well no-drag' : 'white-well bag-item'" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Coronavirus Resources' && covidVisible && checkVisibility(item)">
            <span *ngIf="!useParent" class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-covid></widget-tools-covid>
          </div>
          <div [ngClass]="useParent ? 'white-well no-drag' : 'white-well bag-item'" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Employers Guide to COVID-19' && covidGuideVisible && checkVisibility(item)">
            <span *ngIf="!useParent" class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-covid-guide></widget-tools-covid-guide>
          </div>
          <div [ngClass]="useParent ? 'white-well no-drag' : 'white-well bag-item'" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Resource Center' && resourceCenterVisible && checkVisibility(item)">
            <span *ngIf="!useParent" class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-resource-center></widget-tools-resource-center>
          </div>
          <div [ngClass]="useParent ? 'white-well no-drag' : 'white-well bag-item'" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Topic Analysis' && topicAnalysisVisible && checkVisibility(item)">
            <span *ngIf="!useParent" class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-topic-analysis></widget-tools-topic-analysis>
          </div>
          <custom-widget *ngIf="item.Type == 'Custom-Widget' && checkVisibility(item) && customWidgets != null && customWidgetsVisible"
                         [id]="item.Id"
                         [useParent]="useParent"
                         [customWidgets]="customWidgets"
                         [ngClass]="useParent ? 'no-drag' : 'bag-item'">
          </custom-widget>

        </div>

      </ng-container>

      <ng-container *ngIf="currentRoute == 'tools'">

        <div *ngFor="let item of userProfile.Tools | sortBy: 'PositionOnTools'; let i = index" [ngClass]="getToolClass(item)">
          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Topic' && resourceCenterVisible && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-resource-center></widget-tools-resource-center>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Employee Handbook Builder' && ehbVisible && checkVisibility(item) && isAuthenticated">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-ehb></widget-tools-ehb>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'State Law Chart Builder' && slcbVisible && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-slcb></widget-tools-slcb>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Job Classification & FLSA Audit' && flsaVisible && checkVisibility(item) && isAuthenticated">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-flsa></widget-tools-flsa>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Job Description Manager' && jdmVisible && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-jdm></widget-tools-jdm>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Salary Finder' && sfVisible && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-sf></widget-tools-sf>
          </div>
          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Help widget' && helpVisible && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-help></widget-tools-help>
          </div>

          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Coronavirus Resources' && covidVisible && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-covid></widget-tools-covid>
          </div>

          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Employers Guide to COVID-19' && covidGuideVisible && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-covid-guide></widget-tools-covid-guide>
          </div>

          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Resource Center' && resourceCenterVisible && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-resource-center></widget-tools-resource-center>
          </div>

          <div class="white-well bag-item" [id]="item.Id" *ngIf="(item.Type != 'Custom-Widget') && item.Component == 'Topic Analysis' && topicAnalysisVisible && checkVisibility(item)">
            <span class="close" (click)="hideComponent(item.Id, 'tool')"><i class="fas fa-times"></i></span>
            <widget-tools-topic-analysis></widget-tools-topic-analysis>
          </div>


          <custom-widget *ngIf="item.Type == 'Custom-Widget' && checkVisibility(item) && customWidgets != null && customWidgetsVisible" [id]="item.Id" [customWidgets]="customWidgets" class="bag-item"></custom-widget>

        </div>
      </ng-container>

    </div>
  </div>
</div>
