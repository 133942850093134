<div *ngIf="loading" class="row" style="text-align:center;">
  <img src="../assets/images/loader.gif" />
</div>

<div class="row" style="padding-bottom:10px;">
  <div class="col-md-10" style="text-align:left;">
    <h1 *ngIf="!loading">Recent & Upcoming Compliance Dates</h1>
  </div>
</div>

<div class="row" [class.is-hidden]="loading">

  <div *ngIf="complianceDates != null" class="col-lg-3" style="margin-bottom:20px;">
    <div class="clear-all"><a (click)="clearAll()">Clear all filters</a></div>
    <div class="outer-filter-panel">

      <div class="filter-panel  body-content-label" data-target="#new"> &nbsp;
        <span data-toggle="collapse" (click)="newCollapsed = !newCollapsed" data-target="#new" style="float: right;cursor: pointer;">{{ newCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="new" class="collapse  in filterSegment">
        <table class="table">
          <tbody>
            <tr>
              <td><div class="radio">
                  <label><input name="recentUpcomingAll" type="radio" (click)="filterByWhen('recent', $event.target.checked)" [checked]="filters.PubDateCategory.Recent">Recent</label><span style="float:right;">{{filters.NewItems}}</span> 
                </div>
              </td>
            </tr>
            <tr>
              <td> <div class="radio">
                  <label><input name="recentUpcomingAll" type="radio" (click)="filterByWhen('upcoming', $event.target.checked)" [checked]="filters.PubDateCategory.Upcoming">Upcoming</label><span style="float:right;">{{ filters.Upcoming}}</span> 
                </div>
              </td>
            </tr>
            <tr>
              <td> <div class="radio">
                  <label><input name="recentUpcomingAll" type="radio" (click)="filterByWhen('all', $event.target.checked)" [checked]="filters.PubDateCategory.All">All</label><span style="float:right;">{{filters.AllTimeTotal}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="filter-panel  body-content-label" data-target="#states">
        States<span data-toggle="collapse" (click)="statesCollapsed = !statesCollapsed" data-target="#states" style="float: right;cursor: pointer;">{{ statesCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="states" class="collapse in filterSegment">
        <table class="table">
          <tbody>
            <tr *ngFor="let item of filters.Jurisdictions">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterState(item.JurisdictionId, $event.target.checked, item.JurisdictionName)" [checked]="item.Checked">{{item.JurisdictionName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>          
        </table>
      </div>

      <div class="filter-panel  body-content-label" data-target="#topic">
        Topics<span data-toggle="collapse" (click)="topicsCollapsed = !topicsCollapsed" data-target="#topic" style="float: right;cursor: pointer;">{{ topicsCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="topic" class="collapse in filterSegment">
        <table class="table">
          <tbody>
            <tr *ngFor="let item of filters.Topics">
              <td *ngIf="item.Count != 0">
                <div class="checkbox">
                  <label><input type="checkbox" (click)="filterTopic(item.TopicId, $event.target.checked, item.TopicName)" [checked]="item.Checked">{{item.TopicName}}</label><span style="float:right;">{{item.Count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div class="filter-panel  body-content-label" data-target="#date">Effective Date Range
        <span data-toggle="collapse" (click)="dateCollapsed = !dateCollapsed" data-target="#date" style="float: right;cursor: pointer;">{{ dateCollapsed? '+': '&ndash;' }}</span>
      </div>
      <div id="date" class="collapse in" style="max-height:186px; padding:8px 0px 8px 5px">
        <label> From:
          <input id="fromDate" type="text" placeholder="From" class="form-control" bsDatepicker [bsConfig]="{ adaptivePosition: true, showWeekNumbers:false, containerClass:'theme-dark-blue' }"
          [(ngModel)]="fromDate" [isDisabled]="!filters.PubDateCategory.All">
        </label>

        <label> <span style="padding-right:19px;">To: </span>
          <input type="text" placeholder="To" class="form-control" bsDatepicker [bsConfig]="{ adaptivePosition: true, showWeekNumbers:false, containerClass:'theme-dark-blue' }" 
          [(ngModel)]="toDate" [isDisabled]="!filters.PubDateCategory.All">   
        </label>
        <div style="padding-right:5px;"><button style="float:right;" class="btn btn-default" (click)="filterByDate(fromDate, toDate)">Go</button><div style="clear: both;"></div></div>
      </div>
    </div>
  </div>

  <div class="col-lg-9">
    <div>
      <div class="ar-tab-container">
        <div class="tag" *ngFor="let tag of tags" (click)="clearTag(tag)">
          <div class="inline-text">{{tag.Name}}</div>
          <div class="delete-button" style="height: 100%">
            <svg _ngcontent-c17="" height="16px" viewBox="0 0 32 32" width="16px">
              <path _ngcontent-c17="" d="M17.459,16.014l8.239-8.194c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.391-1.034-0.391-1.428,0  l-8.232,8.187L7.73,6.284c-0.394-0.395-1.034-0.395-1.428,0c-0.394,0.396-0.394,1.037,0,1.432l8.302,8.303l-8.332,8.286  c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.428,0l8.325-8.279l8.275,8.276c0.394,0.395,1.034,0.395,1.428,0  c0.394-0.396,0.394-1.037,0-1.432L17.459,16.014z" fill="#121313"></path>
            </svg>
          </div>
        </div>
      </div>

      <div class="white-well">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped complianceDatesTable allResourcesTable">
          <thead class="thead">
            <tr>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Topic <span></span></a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer" style="min-width:500px;">Description <span></span></a>
              </th>
              <th>
                <a class="text-nowrap" style="cursor:pointer">Effective Date <span></span></a>
              </th>
              <th> 
                <a class="text-nowrap" style="cursor:pointer">State <span></span></a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of information">
              <td>{{item.TopicDesc}}</td> 
              <td><a (click)="openDetails(item)" style="cursor:pointer;">{{item.Teaser}}</a></td>
              <td>{{item.PubDate | date:'MM/dd/yyyy'}}</td>
              <td>{{item.JurisdictionName}}</td>
            </tr>
          </tbody>
        </table> 
      </div>
    </div>
  </div>
</div>