import { Component, Provider, ElementRef, OnInit, Input, Compiler } from '@angular/core';

@Component({
  selector: 'hotline-header',
  templateUrl: './hotline-header.component.html',
  styleUrls: ['./hotline-header.component.css']
})

export class HotlineHeaderComponent {
  isLimitedAccess: boolean;

  constructor() { }


}
