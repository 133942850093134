import { Component } from '@angular/core';
import { DragulaService } from 'ng2-dragula'
import { Router } from '@angular/router';

//services
import { UserProfileService } from '../shared/services/user-profile.service';
import { MembershipDataService } from '../shared/services/membership.data.service'
import { HrHeroService } from '../shared/services/hr-hero.service';

//types
import { Widget } from '../shared/types/widget.type'
import { UserProfile } from '../shared/types/user-profile.type'
import { CustomWidget } from '../shared/types/custom-widget.type'


import  'jquery';


@Component({
  selector: 'tools',
  templateUrl: './tools.html',
  styleUrls: ['../../../node_modules/dragula/dist/dragula.css', './tools.css']
})

export class ToolsComponent {

  selectedComponent: Widget;
  userProfile: UserProfile = new UserProfile;
  parentUserProfile: UserProfile = new UserProfile;
  useParent: boolean = false;
  customWidgets: CustomWidget[] = [];
  index: number = 0;
  available: boolean = false;
  sortBy: string = null;

  ehbVisible: boolean = false;
  slcbVisible: boolean = false;
  flsaVisible: boolean = false;
  jdmVisible: boolean = false;
  sfVisible: boolean = false;
  helpVisible: boolean = false;
  covidVisible: boolean = false;
  covidGuideVisible: boolean = false;
  isAuthenticated: boolean = false;
  resourceCenterVisible: boolean = false;
  topicAnalysisVisible: boolean = false;

  customWidgetsVisible: boolean = false;

  toolCount: number = 0;
  toolClass: string = 'col-lg-3';

  currentRoute: string;

  constructor(
    private _dragulaService: DragulaService,
    private _mbService: MembershipDataService,
    private _userProfileService: UserProfileService,
    private _heroService: HrHeroService,
    private _router: Router
  ) {
    this.selectedComponent = new Widget;

    this._dragulaService.dropModel.subscribe((value) => {
      this.onDropModel(value.slice(1));
    });
    let drake = this._dragulaService.find('dashboard');
    if(drake == null){
      this._dragulaService.setOptions('dashboard',{
        moves: (el,source,handle,sibling) => !el.classList.contains('no-drag')
      })
    }else{
      drake.moves = (el,source,handle,sibling) => !el.classList.contains('no-drag');
    }

  }

  ngOnDestroy() {
    try {
      this._dragulaService.destroy(this.currentRoute);
    } catch (ex) { }
  }

  ngOnInit() {
 
    this.userProfile = this._mbService.userProfile;
    this.parentUserProfile = this._mbService.parentUserProfile;
    if(this.parentUserProfile != null && this.parentUserProfile != undefined && this.parentUserProfile.Tools != null && this.parentUserProfile.Tools != undefined){
      if (this.parentUserProfile.DashboardLock && this._router.url.indexOf("/dashboard") > -1) {
        this.useParent = true;
      }
    }

    this.init();

  }

  private init() {

    this.sortBy = this.getSortBy();

    this.isAuthenticated = this._mbService.userIsHRAuthenticated;

    let currentUrl = this._router.url;

    if (currentUrl.indexOf("/tools") > -1) {
      this.currentRoute = "tools";
    }
    if (currentUrl.indexOf("/dashboard") > -1) {
      this.currentRoute = "dashboard";
    }

    this.customWidgetsVisible = true;


    if (currentUrl.indexOf("/tools") > -1) {
      this.currentRoute = "tools";
      if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Tools page widgets").length > 0) {
        this.customWidgetsVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Tools page widgets")[0].Visible;
      }
    }
    if (currentUrl.indexOf("/dashboard") > -1) {
      this.currentRoute = "dashboard";
      if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Home page widgets").length > 0) {
        this.customWidgetsVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Home page widgets")[0].Visible;
      }
    }



    if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show State Law Chart Builder").length > 0) {
      this.slcbVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show State Law Chart Builder")[0].Visible;

      if (this.slcbVisible) {  /*this.toolCount++; */ }
    }
    if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Employee Handbook Builder").length > 0) {
      this.ehbVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Employee Handbook Builder")[0].Visible;

      if (this.ehbVisible) { /*this.toolCount++;*/ }
    }
    if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Job Description Manager").length > 0) {
      this.jdmVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Job Description Manager")[0].Visible;

      if (this.jdmVisible) { /*this.toolCount++;*/ }
    }
    if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Job Classification & FLSA Audit").length > 0) {
      this.flsaVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Job Classification & FLSA Audit")[0].Visible;

      if (this.flsaVisible) { /*this.toolCount++;*/ }
    }
    if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Salary Finder").length > 0) {
      this.sfVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Salary Finder")[0].Visible;

      if (this.sfVisible) { /*this.toolCount++;*/ }
    }
    if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Help widget").length > 0) {
      this.helpVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Help widget")[0].Visible;

      if (this.helpVisible) { /*this.toolCount++;*/ }
    }
    if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Coronavirus Resources").length > 0) {
      this.covidVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Coronavirus Resources")[0].Visible;
      if (this.covidVisible) { /*this.toolCount++;*/ }
    }
    
    if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Employer's Guide to COVID-19").length > 0) {
        this.covidGuideVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Employer's Guide to COVID-19")[0].Visible;
        if (this.covidGuideVisible) { /*this.toolCount++;*/ }
    }
    if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Resource Center").length > 0) {
      this.resourceCenterVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Resource Center")[0].Visible;
    }

    if (this.userProfile.AdminPreferences.filter(f => f.Name == "Show Topic Analysis").length > 0) {
      this.topicAnalysisVisible = this.userProfile.AdminPreferences.filter(f => f.Name == "Show Topic Analysis")[0].Visible;
    }

    if (this._mbService.userProfile != null && this._mbService.userProfile.InheritableProperties != null) {

      this.jdmVisible = (this._mbService.getSettingValue('JDM') == "true") ? this.jdmVisible : false;
      this.slcbVisible = (this._mbService.getSettingValue('SLCB') == "true") ? this.slcbVisible : false;
      this.flsaVisible = (this._mbService.isMembershipLimited || this._mbService.hasFLSA) && (this._mbService.getSettingValue('FLSA') == "true") ? this.flsaVisible : false;
      this.ehbVisible = (this._mbService.isMembershipLimited || this._mbService.hasEHB) && (this._mbService.getSettingValue('EHB') == "true") ? this.ehbVisible : false;
      this.sfVisible = (this._mbService.getSettingValue('SF') == "true") ? this.sfVisible : false;
      this.helpVisible = (this._mbService.getSettingValue('HELP') == "true") ? this.helpVisible : false;
      this.covidVisible = (this._mbService.getSettingValue('COVID') == "true") ? this.covidVisible : false;
      this.covidGuideVisible = (this._mbService.getSettingValue('COVIDGUIDE') == "true") ? this.covidGuideVisible : false;
      this.resourceCenterVisible = (this._mbService.getSettingValue('RESOURCECENTER') == "true") ? this.resourceCenterVisible : false;
      this.topicAnalysisVisible = (this._mbService.getSettingValue('TOPICANALYSIS') == "true") ? this.topicAnalysisVisible : false;
    }
    let filteredBySF = this.userProfile.Tools.filter(f => f.Component == "Salary Finder");
    this.getCustomWidgets();

    this.available = true;
    let isDashboard = this.currentRoute == "dashboard"

      if (this.visibleHere(4,isDashboard) && this.jdmVisible) {
        this.toolCount++;
      }
      if (this.visibleHere(2,isDashboard) && this.slcbVisible) {
        this.toolCount++;
      }
      if (this.visibleHere(3,isDashboard) && this.flsaVisible) {
        this.toolCount++;
      }
      if (this.visibleHere(1,isDashboard) && this.ehbVisible) {
        this.toolCount++;
      }
      if (filteredBySF.length > 0 && this.visibleHere(5,isDashboard) && this.sfVisible) {
        this.toolCount++;
      }
      if (this.visibleHere(6,isDashboard) && this.helpVisible) {
        this.toolCount++;
      }
      if (this.visibleHere(7, isDashboard) && this.covidVisible) {
          this.toolCount++;
      }
      if (this.visibleHere(8, isDashboard) && this.covidGuideVisible) {
          this.toolCount++;
      }
      if (this.visibleHere(9, isDashboard) && this.resourceCenterVisible) {
        this.toolCount++;
      }
      //FLORY
      if (this.visibleHere(10, isDashboard) && this.topicAnalysisVisible) {
        this.toolCount++;
      }

  }
  visibleHere(toolId,dashboard){
    let tool = this.userProfile.Tools.filter(f => f.Id == toolId)[0];
    if(tool == null || tool == undefined){
      return false;
    }
    let parentTool = null;
    if(this.parentUserProfile != null && this.parentUserProfile != undefined && this.parentUserProfile.Tools != null && this.parentUserProfile.Tools != undefined){
      parentTool = this.parentUserProfile.Tools.filter(f => f.Id == toolId)[0];
    }
    if(dashboard){
      if(parentTool && !parentTool.VisibleOnHome){
        return false;
      }else{
        if(this.useParent){
          return parentTool.VisibleOnHome;
        }
        return tool.VisibleOnHome;
      }
    }else{
      if(parentTool && !parentTool.VisibleOnTools){
        return false;
      }else{
        return tool.VisibleOnTools;
      }
    }
  }

  private onDropModel(args) {

    let [el, target, source] = args;

    this.orderItems();

    this.userProfile.ProfileGUIUpdated = true;
    this._mbService.userProfile.ProfileGUIUpdated = true;

    this._mbService.userProfile = this.userProfile;
    this._userProfileService.saveUserProfile(this._mbService.user.CustomerId, JSON.stringify(this.userProfile), this._mbService.userProfileAPIToken, this._mbService.userProfileTypeId);
  }

  orderItems() {

    let _ths = this;
    let index = 0;
    let processed = [];
    let currentUrl = this._router.url;

    if (currentUrl.indexOf("/tools") > -1) {
      $(".bag-item").each(function (ind) {
        if (_ths.userProfile.Tools.filter(f => f.Id == parseInt(this.id)).length > 0 && processed.filter(p => p == this.id).length == 0) {
          processed.push(this.id);
          _ths.userProfile.Tools.filter(f => f.Id == parseInt(this.id))[0].PositionOnTools = index++;
        }
      });
    }
    if (currentUrl.indexOf("/dashboard") > -1) {
      $(".bag-item").each(function (ind) {
        if (_ths.userProfile.Tools.filter(f => f.Id == parseInt(this.id)).length > 0 && processed.filter(p => p == this.id).length == 0) {
          processed.push(this.id);
          _ths.userProfile.Tools.filter(f => f.Id == parseInt(this.id))[0].PositionOnHome = index++;
        }
      });
    }
  }

  getCustomWidgets() {
    this._heroService.getAllCustomWidgets().then(result => {
      if (result.length > 0) {
        this.customWidgets = result;

        //then update the tool counts
        if(this.customWidgetsVisible){
          for(let i=0; i < this.customWidgets.length; i++){
            let cw = this.customWidgets[i];
            let dash = this.currentRoute == "dashboard";
            let widgetItselfSetVisible = dash ? cw.VisibleOnDashboard : cw.VisibleOnTools;
            if(widgetItselfSetVisible && this.visibleHere(cw.CustomWidgetId,this.currentRoute == "dashboard")){
              this.toolCount++;
            }
          }
        }

      } else {
        this.customWidgets = null;
      }
    });
  }

  checkVisibility(item): boolean {
    if (this.isAuthenticated == false) {
      if (!(this._mbService.hasJDM && this._mbService.hasSLCB)) {
        if (this._mbService.hasJDM) {
          this._router.navigate(['job-description-manager']);
        }

        if (this._mbService.hasSLCB) {
          this._router.navigate(['state-law-chart-builder']);
        }
      }
    }

    if (item.Type == 'Custom-Widget' && this.customWidgets != null) {
      if (this.customWidgets.filter(f => f.CustomWidgetId == item.Id).length == 0 || this.customWidgets.find(f => f.CustomWidgetId == item.Id) == undefined || this.customWidgets.find(f => f.CustomWidgetId == item.Id) == null) {
        return false;
      }

      if (this.customWidgets.filter(f => f.CustomWidgetId == item.Id).length > 0) {
        if (this.currentRoute == 'tools' && !this.customWidgets.filter(f => f.CustomWidgetId == item.Id)[0].VisibleOnTools) {
          return false;
        }

        if (this.currentRoute == 'dashboard' && !this.customWidgets.filter(f => f.CustomWidgetId == item.Id)[0].VisibleOnDashboard) {
          return false;
        }
      }
    }

    return this.visibleHere(item.Id,this.currentRoute == 'dashboard');
    /*if (this.currentRoute == 'tools') {
      return item.VisibleOnTools;
    }
    else if (this.currentRoute == 'dashboard') {
      return item.VisibleOnHome;
    }*/

    //return false;
  }

  getSortBy() {
    if (this.currentRoute == 'tools') {
      return "'PositionOnTools'";
    }
    else if (this.currentRoute == 'dashboard') {
      return "'PositionOnHome'";
    }

    return "'PositionOnHome'";
  }

  hideComponent(id) {
    let currentUrl = this._router.url;

    if (this.userProfile.Tools.filter(f => f.Id == id).length > 0) {
      if (currentUrl.indexOf("/tools") > -1) {
        this.userProfile.Tools.filter(f => f.Id == id)[0].VisibleOnTools = false;
      } else if (currentUrl.indexOf("/dashboard") > -1) {
        this.userProfile.Tools.filter(f => f.Id == id)[0].VisibleOnHome = false;
      }
    }

    this._userProfileService.saveUserProfile(this._mbService.user.CustomerId, JSON.stringify(this.userProfile), this._mbService.userProfileAPIToken, this._mbService.userProfileTypeId);
  }

  hideWidget(item) {
    item.Visible = false;
  }

  getToolClass(item) {

    if (this.isAuthenticated == false) {
      if (this._mbService.hasJDM && this._mbService.hasSLCB && (item.Component == 'State Law Chart Builder' || item.Component == 'Job Description Manager')) {
        if(this.currentRoute == 'dashboard' && this.useParent){
          return 'col-lg-6 no-drag';
        }
        return 'col-lg-6';
      } else {
        return '';
      }
    }

    let currentUrl = this._router.url;

    if (!this.customWidgetsVisible && item.Type == 'Custom-Widget') {
      return '';
    }

    if(!this.visibleHere(item.Id,this.currentRoute == "dashboard")){
      return '';
    }
    /*if (this.currentRoute == 'tools' && !item.VisibleOnTools) {
      return '';
    }
    else if (this.currentRoute == 'dashboard' && !item.VisibleOnHome) {
      return '';
    }*/

    if (item.Type == 'Custom-Widget' && this.customWidgets != null) {

      if (this.customWidgets.filter(f => f.CustomWidgetId == item.Id).length > 0) {


        if (this.currentRoute == 'tools' && !this.customWidgets.filter(f => f.CustomWidgetId == item.Id)[0].VisibleOnTools) {
          return '';
        }

        if (this.currentRoute == 'dashboard' && !this.customWidgets.filter(f => f.CustomWidgetId == item.Id)[0].VisibleOnDashboard) {
          return '';
        }

        let widthclass = this.customWidgets.filter(f => f.CustomWidgetId == item.Id)[0].WidthClass;
        if(this.currentRoute == 'dashboard' && this.useParent){
          widthclass += ' no-drag';
        }
        if(!widthclass.includes('clamped-height')){ //not sure where this is multiplying, but hopefully this check stops it.
          widthclass += ' clamped-height';
        }
        return widthclass;
      } else {
        return '';
      }
    }

    //switch (this.toolCount) {
    //  case 1:
    //    this.toolClass = 'col-lg-12';
    //    break;
    //  case 2:
    //    this.toolClass = 'col-lg-6';
    //    break;
    //  case 3:
    //    this.toolClass = 'col-lg-4';
    //    break;
    //  case 4:
    //    this.toolClass = 'col-lg-3';
    //    break;
    //  case 5:
    //    this.toolClass = 'col-lg-3';
    //    break;
    //  case 6:
    //    this.toolClass = 'col-lg-3';
    //    break;
    //  default:
    //    this.toolClass = 'col-lg-3';
    //    break;
    //}
    let baseClass = this.toolClass;
    if(this.currentRoute == 'dashboard' && this.useParent){
      baseClass += ' no-drag';
    }
    baseClass += ' clamped-height';

    if (item.Component == 'Employee Handbook Builder' && !this.ehbVisible) {
      baseClass = '';
    } else if (item.Component == 'State Law Chart Builder' && !this.slcbVisible) {
      baseClass = '';
    } else if (item.Component == 'Job Classification & FLSA Audit' && !this.flsaVisible) {
      baseClass = '';
    } else if (item.Component == 'Job Description Manager' && !this.jdmVisible) {
      baseClass = '';
    } else if (item.Component == 'Salary Finder' && !this.sfVisible) {
      baseClass = '';
    } else if (item.Component == 'Help widget' && !this.helpVisible) {
      baseClass = '';
    } else if (item.Component == 'Coronavirus Resources' && !this.covidVisible) {
      baseClass = '';
    } else if (item.Component == 'Employers Guide to COVID-19' && !this.covidGuideVisible) {
      baseClass = '';
    } else if (item.Component == 'Resource Center' && !this.resourceCenterVisible) {
      baseClass = '';
    } else if (item.Component == 'Topic Analysis' && !this.topicAnalysisVisible) {
      baseClass = '';
  }

    return baseClass;
  }
}
