<ng-container *ngIf="available">
  <ng-container *ngIf="cm != null && cm.Header != '' && cm.Header != null" >
    <div class='widget-header' [innerHtml]="cm.Header">
    </div>
    <div [ngClass]="bagClass" [id]="id">
      <span *ngIf="!useParent" class='close' (click)='hideComponent(id)'><i class='fas fa-times'></i></span>
      <div [innerHtml]="cm.Body | safeHtml"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="cm != null && (cm.Header == '' || cm.Header == null)" >
    <div [ngClass]="bagClass" [id]="id">
      <span *ngIf="!useParent" class='close' (click)='hideComponent(id)'><i class='fas fa-times'></i></span>
      <div [innerHtml]="cm.Body | safeHtml"></div>
    </div>
  </ng-container>
</ng-container>
