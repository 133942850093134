import { Component, Provider, ElementRef, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { Router, NavigationEnd } from '@angular/router';

import { CustomWidget } from '../../shared/types/custom-widget.type'
import { UserProfileService } from '../../shared/services/user-profile.service';

@Component({
  selector:"custom-widget",
  templateUrl: './custom-widget.component.html'
})

export class CustomWidgetComponent {

  @Input() id: number;
  @Input() customWidgets: CustomWidget[];
  @Input() useParent: boolean;
  @Input() bagClass: string;

  visible: boolean = false;
  cm: CustomWidget;
  available: boolean = false;

  constructor(private _mbService: MembershipDataService, private _userProfileService: UserProfileService, private _router: Router) { }

  ngOnInit() {

    if (this.customWidgets == null) {
      return '';
    }

    let currentUrl = this._router.url;

    this.cm = this.customWidgets.filter(f => f.CustomWidgetId == this.id)[0];

    if (this.cm != null) {
      if (this.cm.Header == '' || this.cm.Header == null || this.cm.Header == undefined) {
        this.bagClass = 'white-well bag-item';
      } else {
        this.bagClass = 'white-well-radius-bottom-left-align bag-item';
      }
      if(this.useParent){
        this.bagClass += ' no-drag';
      }

      this.available = true;
    }

  }
  hideComponent(id) {
    let currentUrl = this._router.url;

    if (this._mbService.userProfile.Tools.filter(f => f.Id == id).length > 0) {
      if (currentUrl.indexOf("/tools") > -1) {
        this._mbService.userProfile.Tools.filter(f => f.Id == id)[0].VisibleOnTools = false;
      } else if (currentUrl.indexOf("/dashboard") > -1) {
        this._mbService.userProfile.Tools.filter(f => f.Id == id)[0].VisibleOnHome = false;
      }
    }

    this._userProfileService.saveUserProfile(this._mbService.user.CustomerId, JSON.stringify(this._mbService.userProfile), this._mbService.userProfileAPIToken, this._mbService.userProfileTypeId);
  }

  
}
