<div class="tool-image-container" style="text-align: center;">
  <br>
</div>
<div class="tool-image-container" style="text-align: center;">
  <br>
</div>
<div class="tool-image-container" style="text-align: center;">
  <br>
</div>
<div class="tool-image-container" style="text-align: center;">
  <div class="nav-sub-link">
    <br>
  </div>
  <div class="nav-sub-link">
    <img class="tool-image fr-fic fr-dii" src="/ClientApp/hero.hr/Hero.HR.Client/dist/assets/images/hrhelpicon.png">
    <br>
  </div>
  <div class="nav-sub-link">Help Resources</div>
  <div class="body-content-italic" style="text-align: center;">User Guide, Tutorial Videos, Feedback Survey, Contact Numbers, and more...</div>

  <p>
    <br>
  </p>

  <p>
    <br>
  </p>

  <p>
    <br>
  </p>
</div>
<div style="padding-top: 25px; text-align: center;"><a class="btn-blue-sm" href="https://interactive.blr.com/hr-hero-help-page" rel="noopener noreferrer" target="_blank">Get Started</a></div>
