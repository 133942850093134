import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MembershipDataService } from '../shared/services/membership.data.service'

import  'jquery';


@Component({
  selector: 'chart-builder',
  templateUrl: './chart-builder.component.html'
})

export class ChartBuilderComponent  {

  url: string = null;

  constructor(private mbDataService: MembershipDataService, public _router: Router) { }

  ngOnInit() {

    if (!this.mbDataService.hasSLCB) {
      this._router.navigate(['/tools']);
    }
    //refresh page if someone is on for longer than 24 hrs..
    //slcb will need a new token if this is the case
    setTimeout(function () { location.reload(true); }, 86400000);

    this.url = "../../assets/widgets/tools/slcb/statelaws-chart-builder-ui/index.html?userId=" + this.mbDataService.user.CustomerId + "&legacyUserId=" + this.mbDataService.user.LegacyUserId + "&legacyParentUserId=" + this.mbDataService.user.LegacyParentUserId + "&apiToken=" + this.mbDataService.slcbAPIToken

    $(function ($) {
      var lastHeight = 0, curHeight = 0, $frame = $('#chbIFrame');
      setInterval(function () {
        curHeight = $frame.contents().find('body').height();
        if (curHeight != lastHeight) {
          $frame.css('height', (lastHeight = curHeight)+ 50 + 'px');
        }
      }, 500);
    });
  }
  
}


