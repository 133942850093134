<div style="margin-bottom:20px;" class="body-content-label" >
  <i class="fa fa-folder-open" aria-hidden="true"></i> <span style="cursor:pointer" (click)="getBookmarks();">My Bookmarks</span> > {{bookmarkFolderLabel}}
</div>

<div *ngIf="loading" class="row" style="text-align:center;">
  <img src="../assets/images/loader.gif" />
</div>

<div class="row" [class.is-hidden]="loading">
  <div class="col-lg-3">
    <div class="outer-filter-panel">
      <div class="filter-panel" data-toggle="collapse" data-target="#date">
        Folders
      </div>
      <div id="date" class="collapse in" style="overflow-y:auto;">

        <table class="table table-striped" *ngIf="bookmarkFolders != null">
          <tbody>
            <tr style="cursor:pointer">
              <td (click)="getBookmarks();" [style.font-weight]="bookmarkFolderLabel == null ? 'bold' : 'normal'">My Bookmarks</td>
              <td [style.font-weight]="bookmarkFolderLabel == null ? 'bold' : 'normal'">All</td>
            </tr>
            <tr *ngFor="let item of bookmarkFolders" style="cursor:pointer;">
              <td (click)="getBookmarksByBookmarkFolder(item.BookmarkFolderId, item.Name);" [style.font-weight]="item.Name == bookmarkFolderLabel ? 'bold' : 'normal'">{{item.Name}} <a (click)="editBookmarkFolder(item,bookmarkFolderModal)" style="cursor:pointer;">edit</a></td>
              <td (click)="getBookmarksByBookmarkFolder(item.BookmarkFolderId, item.Name);" [style.font-weight]="item.Name == bookmarkFolderLabel ? 'bold' : 'normal'">{{item.BookmarkCount}}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
    <div style="margin-top:20px;">
      <a class="btn-white-grey btn-stretch" (click)="openBookmarkFolderModal(bookmarkFolderModal)" style="width:100%;">New Folder <img src="../../assets/images/blue-plus.PNG" /></a>
    </div>
  </div>
  <div class="col-lg-9 ">
    <div class="panel panel-default" [hidden]="dataAvailable != 1">
        <table datatable class="table table-striped" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
          <thead class="thead">
            <tr>
              <th width="12.5%">
                Type
              </th>
              <th width="22.5%">
                Topic
              </th>
              <th width="37.5%">
                Title
              </th>
              <th width="15%">
                Last Saved
              </th>
              <th style="width: 10%">Manage</th> 
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of bookmarks">
              <td [routerLink]="[item.URL]" style="cursor:pointer;">{{item.InformationTypeName}}</td>
              <td [routerLink]="[item.URL]" style="cursor:pointer;">{{item.TopicName}}</td>
              <td [routerLink]="[item.URL]" style="cursor:pointer;">{{item.Title}}</td>
              <td [routerLink]="[item.URL]" style="cursor:pointer;">{{item.DateTimeBookmarked | date:'shortDate'}}</td>
             <td><bookmarks (bookmarkSaved)="bookmarkSaved($event)" [bookmarkId]="item.BookmarkId" [bookmarkTitle]="item.Title" [bookmarkFolderId]="item.BookmarkFolderId" [informationId]="item.InformationId" [informationTypeId]="item.InformationTypeId" [topicId]="item.TopicId"></bookmarks></td> 
            </tr>
          </tbody>
        </table> 
      </div>
      <div [hidden]="dataAvailable == 1"><h2>No bookmarks exist in this folder.</h2></div>
  </div>
</div>

<!--bookmark folder modal-->
<ng-template #bookmarkFolderModal>
  <div class="modal-header modal-header-hr" *ngIf="bookmarkFolderName != '' && bookmarkFolderName != null">
    Manage Folder
  </div>
  <div class="modal-header modal-header-hr" *ngIf="!(bookmarkFolderName != '' && bookmarkFolderName != null)">
    New Folder
  </div>
  <div class="modal-body">
    <div class="body-content-label">Name</div>
    <input type="text" class="form-control" placeholder="Enter folder name" #name [value]="bookmarkFolderName" />
    <div style="margin-top:10px; color:red;">{{errorMessage}}</div>
  </div>
  <div class="modal-footer">
    <button *ngIf="bookmarkFolderName != '' && bookmarkFolderName != null" type="button" class="btn btn-danger" (click)="deleteBookmarkFolder()">Delete</button>
    <button type="button" class="btn btn-default" (click)="addBookmarkFolder(name.value)">Save</button>
    <button type="button" class="btn btn-default" (click)="closeBookmarkFolderModal()">Cancel</button>
  </div>
</ng-template>
