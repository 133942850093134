import { Component, Provider, OnInit, ViewChild, IterableDiffers, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'audio-presentations',
  templateUrl: './audio-presentations.html',
  styleUrls: ['./audio-presentations.css']
})

export class AudioPresentationsComponent {

  iframeUrl: string;
  tranningTitle: string;
  selectedVideoUrl: string;

  constructor(public _route: ActivatedRoute,
    private _router: Router) {
        
    }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.iframeUrl = params["url"];
    });

    this.iframeUrl = window.location.origin + '/' + this.iframeUrl;
    console.log(this.iframeUrl);
  }
}
