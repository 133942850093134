<div class="footer noPrint">
  <div class="container text-center footer-content">

    <div class="footer-image"><img alt="" width="79" height="49" [src]="footerLogo" /></div>
    <div class="footer-text">

      © {{year | date:'yyyy'}} BLR®, a division of <a href="http://www.simplifycompliance.com">Simplify Compliance LLC</a>. All rights reserved<br>{{ footerAddress }} | {{footerPhone}}

      <div class="footer-version">
        Hero 3.21.0/HR 3.21.0
      </div>

    </div>

  

  </div>

</div>
