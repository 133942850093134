import { Injectable, Component, Provider } from '@angular/core'
import { HttpClient, HttpHeaders  } from '@angular/common/http'
import { Router, ActivatedRoute } from '@angular/router';
import { Category } from '../types/category.type'
import { CategoryTopic } from '../types/categoryTopic.type'
import { Topic } from '../types/topic.type'
import { Jurisdition } from '../types/jurisdiction.type'
import { Information } from '../types/information.type'
import { Bookmark } from '../types/bookmark.type'
import { BookmarkFolder } from '../types/bookmark-folder.type'
import { Chapter } from '../types/chapter.type'
import { SearchModel } from '../types/search.type'
import { CustomWidget } from '../types/custom-widget.type'
import { User } from '../types/membershipbase-user'
import { InformationType } from '../types/information-type.type'
import { CCUser } from '../types/cc-user'
import { InformationAllModel } from '../types/information-all.type'
import { UrlLink } from '../types/urllink.type'
import { hrHeroConfig } from '../../../environments/environment'
import {AnalysisModel} from '../types/analysis-model.type'
import { MembershipDataService } from '../services/membership.data.service'
import { CookieService } from 'ngx-cookie';
import {CustomImage} from '../types/custom-image.type'
import { Account } from '../types/account.type';
import { CCCustomer } from '../types/cc-customer';
import {TrainingAllModel} from '../types/training/training-all'
import { map, share, catchError, tap, shareReplay  } from "rxjs/operators";
import { Observable, throwError  } from 'rxjs';
import { CustomTag } from '../types/custom-tag.type';


@Injectable({
  providedIn: 'root'  // <- ADD THIS
})

/*@Component({
    providers:[CookieService]
})*/

export class HrHeroService {

  //token: string;
  hrHeroCon: any;

  constructor(private http: HttpClient, private mbDataService: MembershipDataService, private _cookieService: CookieService, private router: Router) {

    if (this._cookieService.get("blr-api-token-set") != undefined) {

      //this.token = this.mbDataService.userProfileAPIToken;

    

    } else {
      let _ths = this;

      this.mbDataService.autoLogin(this.mbDataService.defaultUserProfile).pipe(map((account: Account) => {

        //authentication failed
        if (account == null || account.Authorized == false) {
          this.router.navigate(['login']);
          return false;
        }

        _ths.mbDataService.setUpAccount(account);

        return true;

      }));
    }
    
  }

  getCategories(language, infoTypes) {
    (language == 'Spanish') ? language = 1 : language = language;
    (language == 'English') ? language = 0 : language = language;


    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
    headers: headers
 }

    return this.http.get<Category[]>(hrHeroConfig.endpoint + '/Category?spanish=' + language + '&infoTypes=' + infoTypes, options)
      .toPromise();
  }

  getTopics(language, categoryId, infoTypes) {
    (language == 'Spanish') ? language = 1 : language = language;
    (language == 'English') ? language = 0 : language = language;
    (categoryId == 0) ? categoryId = null : categoryId = categoryId;

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
    headers: headers
 }

    return this.http.get<Topic[]>(hrHeroConfig.endpoint + '/Topic?spanish=' + language + '&categoryId=' + categoryId + '&infoTypes=' + infoTypes, options)
      .toPromise();
  }

  getCategoryTopics(infoTypes) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
    headers: headers
 }

    return this.http.get<CategoryTopic[]>(hrHeroConfig.endpoint + '/Topic?infoTypes=' + infoTypes, options)
      .toPromise();
  }
  getTopicsBySearchTerm(categoryId, infoTypes, searchTerm) {

    (categoryId == 0) ? categoryId = null : categoryId = categoryId;

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
    headers: headers
 }

    return this.http.get<CategoryTopic[]>(hrHeroConfig.endpoint + '/Topic?searchTerm=' + searchTerm + '&infoTypes=' + infoTypes, options)
      .toPromise();
  }

  getStates() {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
    headers: headers
 }

    //this if statement is added as a temporary solution for free trial users giving them access to all the states
    if (this.mbDataService.isMembershipLimited) {
        return this.http.get<Jurisdition[]>(hrHeroConfig.endpoint + '/States', options)
            .toPromise();
    }
    else {
        return this.http.get<Jurisdition[]>(hrHeroConfig.endpoint + '/States?list=' + this.mbDataService.libraries, options)
            .toPromise();
    }
  }

  getJurisdictions() {

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
    headers: headers
 }

    return this.http.get<Jurisdition>(hrHeroConfig.endpoint + '/Jurisdiction', options)
      .toPromise();
  }

  getInformationByUrl(url) {

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
    headers: headers
 }
    let callpath = hrHeroConfig.endpoint + '/Information/GetInformationByUrl?url=' + encodeURI(url);
    return this.http.get<Information>(callpath, options)
      .toPromise();
  }

  getInformationById(id) {

    let headers = new HttpHeaders({
    //  'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<Information>(hrHeroConfig.endpoint + '/Information?informationId=' + id + '&sourceInformationId=null&databaseId=null', options)
      .toPromise();
  }


  getInformationByUrlParts(type, category, topic, page) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<Information>(hrHeroConfig.endpoint + '/Information?informationType=' + type + '&category=' + category + '&topic=' + topic + '&pageName=' + page, options)
      .toPromise();
  }

  getInformationBySearchTerm(searchtext, allKeywords) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    //+ "&sourceDatabaseIds=1&libraries=" + this.mbDataService.getLibraries()

    return this.http.get<SearchModel>(hrHeroConfig.endpoint + '/Search?searchtext=' + encodeURIComponent(searchtext) + '&userId=' + this.mbDataService.user.CustomerId + '&allKeywords=' + allKeywords, options)
      .toPromise();
  }
  getSpellcheck(searchTerm: string) {
  
      let headers = new HttpHeaders({
      //  'Token': this.token,
        'Content-Type': 'application/json'
      });
      
      let options =  {
        headers: headers
    }
      return this.http.get<string>(hrHeroConfig.endpoint + '/Search/Spell?searchTerm=' + encodeURIComponent(searchTerm.replace("'", "")) + '&sourceDatabaseIds=1', options)
      .toPromise();
  }
  
  getSuggestions(searchTerm: string) {
  
      let headers = new HttpHeaders({
       // 'Token': this.token,
        'Content-Type': 'application/json'
      });
  
      let options =  {
        headers: headers
    }
      return this.http.get<Array<string>>(hrHeroConfig.endpoint + '/Search/Suggest?searchTerm=' + searchTerm.replace("'", "") + '&sourceDatabaseIds=1', options);
  }

  getInformationBySearchTermAndModel(searchtext, model) {

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<SearchModel>(hrHeroConfig.endpoint + '/Search?searchtext=' + encodeURIComponent(searchtext) + '&searchModel=' + JSON.stringify(model), options)
      .toPromise();
  }

  getAllInformation(infoTypeId) {

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<Information[]>(hrHeroConfig.endpoint + '/Information?infoTypeId=' + infoTypeId, options)
      .toPromise();
  }

  getAllInformationDesc(infoTypeId) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<Information[]>(hrHeroConfig.endpoint + '/Information?infoTypeId=' + infoTypeId + "&top=500", options)
      .toPromise();
  }

  getInformationTrainingNew(addDays = null) {

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<Information[]>(hrHeroConfig.endpoint + '/Information?infoTypeId=' + hrHeroConfig.trainingTypes.toString() + '&categoryId=' + null + '&topicId=' + null + '&jurisId=' + null + '&addDays=' + addDays, options)
      .toPromise();
  }

  getAllResources(resourcesTypeIds) {
      let headers = new HttpHeaders({ 
        //'Token': this.token, 
        'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      return this.http.get<InformationAllModel>(hrHeroConfig.endpoint + '/Information/GetResources?resourceIds=' + resourcesTypeIds + '&databaseIds=1&statesAbbr', options)
          .toPromise();
  }

  getComplianceDates(resourcesTypeIds, states) {
      let headers = new HttpHeaders({ 
        //'Token': this.token, 
        'Content-Type': 'application/json' });
      let options =  {
    headers: headers
 }

      return this.http.get<InformationAllModel>(hrHeroConfig.endpoint + '/Information/GetResources?resourceIds=' + resourcesTypeIds + '&databaseIds=1&statesAbbr=' + states + '&ignorePubDate=true', options)
          .toPromise();
  }

  getAllTraining() {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });
    //console.log(hrHeroConfig.endpoint + '/Information/AllTraining?sourceDatabaseIds=1');
    let options =  {
        headers: headers
    }
    return this.http.get<TrainingAllModel>(hrHeroConfig.endpoint + '/Information/AllTraining?sourceDatabaseIds=1', options)
    .toPromise();
  }

  saveResouceFilters(key, filters) {
    localStorage.setItem(key, JSON.stringify(filters));
  }
  popSavedFilters(key) {
    let filters = JSON.parse(localStorage.getItem(key));
    localStorage.removeItem('filters');
    return filters;
  }

  removeSavedFilters(key:string) {
      localStorage.removeItem(key);
  }

  getTrainingByInfoId(infoId) {
    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    let url = hrHeroConfig.endpoint + '/Information/AllTrainingDetails?infoId=' + infoId;

    return this.http.get<Information>(url, options)
      .toPromise();
  }
  getTrainingByUrlParts(type, category, topic, ptype, page, sourceDatabaseIds, infoId) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    let url = hrHeroConfig.endpoint + '/Information/AllTrainingDetails?informationTypeUrl=' + type + '&categoryUrl=' + category + '&topicUrl=' + topic + '&parentInformationTypeUrl=' + ptype + '&pageName=' + page + "&databaseId=" + sourceDatabaseIds + "&infoId=" + infoId;

    if (infoId > 0) {
      url = hrHeroConfig.endpoint + '/Information/AllTrainingDetails?informationTypeUrl=' + type + '&categoryUrl=' + category + '&topicUrl=' + topic + '&parentInformationTypeUrl=&pageName=' + page + "&databaseId=" + sourceDatabaseIds + "&infoId=" + infoId
    }

    return this.http.get<Information[]>(url, options)
      .toPromise();
  }
  getTrainingSupportingDocuments(infoId) {

    let headers = new HttpHeaders({
    //  'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<Information[]>(hrHeroConfig.endpoint + '/Information/AllTrainingSupportingDocuments?infoId=' + infoId, options)
      .toPromise();
  }

  getInformation(infoTypeId, categoryId, topicId, jurisdictionId, addDays = null, language = null) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

        let options =  {
        headers: headers
    }

    //console.log("api call", hrHeroConfig.endpoint + '/Information?infoTypeId=' + infoTypeId + '&categoryId=' + categoryId + '&topicId=' + topicId + '&jurisId=' + jurisdictionId + '&addDays=' + addDays + '&language=' + language)

    return this.http.get<Information[]>(hrHeroConfig.endpoint + '/Information?infoTypeId=' + infoTypeId + '&categoryId=' + categoryId + '&topicId=' + topicId + '&jurisId=' + jurisdictionId + '&addDays=' + addDays + '&language=' + language, options)
      .toPromise();
  }

  getInformationByType(infoId, type) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<Information[]>(hrHeroConfig.endpoint + '/Information?id=' + infoId + '&type=' + type, options)
      .toPromise();
  }

  getAnalysis(topicId, stateId) {

    let headers = new HttpHeaders({
       //'Token': this.token, 
       'Content-Type': 'application/json'});

    let options =  {
        headers: headers
    }
    return this.http.get<AnalysisModel>(hrHeroConfig.endpoint + '/Information/Analysis?topicId=' + topicId + '&stateId=' + stateId + '&exclusions=' + this.getExclusions() , options)
    .pipe(map(res=>res))  
    .toPromise();
  }

  getAnalysisById(analysisId) {
    let headers = new HttpHeaders({ 
      //'Token': this.token, 
      'Content-Type': 'application/json' });
    let options =  {
        headers: headers
    }

    return this.http.get(hrHeroConfig.endpoint + '/Information/Analysis?analysisId=' + analysisId + '&exclusions=' + this.getExclusions(), options)
    .pipe(map(res=>res))    
    .toPromise();
  }

  getExclusions() {
    let exclusions = "";
    exclusions += (this.mbDataService.getSettingValue('Training') != "true") ? "Training,": "";
    exclusions += (this.mbDataService.getSettingValue('ChecklistsForms') != "true") ? "ChecklistsForms," : "";
    exclusions += (this.mbDataService.getSettingValue('Calculators') != "true") ? "Calculators" : "";
    return exclusions;
  }

  getInformationMostPopular() {

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<Information[]>(hrHeroConfig.endpoint + '/Information/MostPopular', options)
      .toPromise();
  }

  getTopInformation(infoTypeId, top) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<Information[]>(hrHeroConfig.endpoint + '/Information?infoTypeId=' + infoTypeId + '&top=' + top, options)
      .toPromise();
  }

  getNews(infoId) {
    let headers = new HttpHeaders({
    //  'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    var result = this.http.get<Information>(hrHeroConfig.endpoint + '/Information?infoTypeId=1&infoId=' + infoId, options)
      .toPromise();
    
    return result;
  }

  saveNewsArticle(info: Information) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.post(hrHeroConfig.endpoint + '/Information', info, options)
      .toPromise();
  }

  getBookmarks(queryType, id) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    if (id == null) {
      id = this.mbDataService.getCustomerId();
    }

    return this.http.get<Bookmark[]>(hrHeroConfig.endpoint + '/Bookmark?id=' + id + '&queryType=' + queryType + '&applicationId=1', options).toPromise();
  }

  getBookmarkFolders() {

    let headers = new HttpHeaders({
    //  'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<BookmarkFolder[]>(hrHeroConfig.endpoint + '/BookmarkFolder?userId=' + this.mbDataService.getCustomerId() + '&applicationId=1', options)
      .toPromise();
  }

  updateMyBookmark(myBookmark: Bookmark) {

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });
    let options =  {
          headers: headers
      }
      
    myBookmark.ApplicationId = 1;



    return this.http.post(hrHeroConfig.endpoint + '/Bookmark', myBookmark, options)
      .toPromise();
  }

  addBookmarkFolder (name: string, bookmarkFolderId: number) : Observable<string> {

    let data: any;

    data = {
      "UserId": this.mbDataService.getCustomerId(),
      "Name": name,
      "BookmarkFolderId": bookmarkFolderId,
      "ApplicationId": 1
    };

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers,
        responseType: 'text' as 'json'
    };

    let body = JSON.stringify(data);

    return this.http.post<string>(hrHeroConfig.endpoint + '/BookmarkFolder', body, options);
  }

  deleteBookmarkFolder(bookmarkFolderId: number) {


    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.delete(hrHeroConfig.endpoint + '/BookmarkFolder?bookmarkFolderId=' + bookmarkFolderId, options)
    .toPromise();
  }

  deleteMyBookmark(bookmarkId: number) {

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.delete(hrHeroConfig.endpoint + '/Bookmark?bookmarkId=' + bookmarkId, options);
  }

  saveBookmark(informationId: number, title: string, userId: number, bookmarkFolderId: number, informationTypeId: number, categoryId: number, topicId: number, bookmarkId: number, stateName: string) :Observable<string> {
    let data: any;

    data = {
      "BookmarkId": bookmarkId,
      "Title": title,
      "InformationId": informationId,
      "BookmarkFolderId": bookmarkFolderId,
      "UserId": userId,
      "CategoryId": categoryId,
      "TopicId": topicId,
      "StateName": stateName,
      "InformationTypeId": informationTypeId,
      "ApplicationId": 1
    };

    let body = JSON.stringify(data);

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers,
        responseType: 'text' as 'json'
    }

    return this.http.post<string>(hrHeroConfig.endpoint + '/Bookmark', body, options);

  }

  getMyCustomWidgets(){
    let userId = this.mbDataService.getCustomerId();
    
    let headers = new HttpHeaders({
    //  'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get<CustomWidget[]>(hrHeroConfig.endpoint + '/CustomWidget?userId=' + userId, options)
      .toPromise();
  }
  getAllCustomWidgets() {
    let parentId = null;
    if (this.mbDataService.parentUserId != null && this.mbDataService.parentUserId > 0) {
      parentId = this.mbDataService.parentUserId;
    }else{
      return this.getMyCustomWidgets();
    }

    let userId = this.mbDataService.getCustomerId();
    
    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }
    let myWidgetsRequest = this.getMyCustomWidgets();
    let parentWidgetsRequest =  this.http.get<CustomWidget[]>(hrHeroConfig.endpoint + '/CustomWidget?userId=' + parentId, options)
        .toPromise();
    return Promise.all([myWidgetsRequest,parentWidgetsRequest]).then(values => {
      return values[0].concat(values[1]); //combine the results
    });
  }

  saveCustomWidget(customWidget) {

    let headers = new HttpHeaders({
      'Accept': 'application/json',
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }
    customWidget.ApplicationId = 1;
    return this.http.post(hrHeroConfig.endpoint + '/CustomWidget', customWidget, options);
  }

  deleteCustomWidget(customWidgetId: number) {

    let headers = new HttpHeaders({
      //'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.delete(hrHeroConfig.endpoint + '/CustomWidget?customWidgetId=' + customWidgetId, options);
  }


  getMembershipbaseUsers() {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    let userId = this.mbDataService.getLegacyId();

    if (this.mbDataService.parentUserId != null) {
      userId = this.mbDataService.parentUserId;
    }

    return this.http.get<User[]>(hrHeroConfig.endpoint + '/MembershipBase?userId=' + userId, options)
      .toPromise();

  }
  getCustomerCentralUsers() {

      let headers = new HttpHeaders({
        // 'Token': this.token,
          'Content-Type': 'application/json' });

    let options =  {
        headers: headers
    }

    //let membershipIds = this.mbDataService.getHeroMembershipAndAddOnIds();
    let membershipIds = this.mbDataService.getAllHeroMembershipIds();
    let addOnIds = this.mbDataService.getAddOnIds();

    let midsString = "";
    if (membershipIds.length > 0) {
      midsString = "" + membershipIds[0];
    }
    for (let i = 1; i < membershipIds.length; i++) {
      midsString += "," + membershipIds[i];
    }

    return this.http.get<CCCustomer[]>(hrHeroConfig.endpoint + '/CustomerCentral/GetChildrenHR?midsString=' + midsString + '&addOnIds=' + addOnIds, options)
    .toPromise();
  }

  getCustomerCentralUser(userId: number) {
      let headers = new HttpHeaders({
          //'Token': this.token,
          'Content-Type': 'application/json'
      });
      let options =  {
        headers: headers
    }
      return this.http.get<CCCustomer>(hrHeroConfig.endpoint + '/CustomerCentral/GetUser/' + userId + '/true', options)
      .toPromise();
  }

  getTopicInfo(topicId) {
      let headers = new HttpHeaders({ 
        //'Token': this.token, 
        'Content-Type': 'application/json' });

      let options =  {
        headers: headers
    }
      var result = this.http.get<Topic>(hrHeroConfig.endpoint + '/Topic?topicId=' + topicId, options)
          .toPromise();

      return result;
  }

  getTopicByUrl(url) {
      let headers = new HttpHeaders({ 
       // 'Token': this.token, 
        'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      var result = this.http.get<Topic>(hrHeroConfig.endpoint + '/Topic/GetTopicByUrl?topicUrl=' + url, options)
          .toPromise();
      return result;
  }

  getCategoryByUrl(categoryUrl) {
      let headers = new HttpHeaders({
        //'Token': this.token, 
         'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      var result = this.http.get<Category>(hrHeroConfig.endpoint + '/Category?url=' + categoryUrl, options)
          .toPromise();
      return result;
  }

  getInfoTypeByUrl(infoTypeUrl) { //hey this only works for checklists/forms/policies for some reason
      let headers = new HttpHeaders({
         //'Token': this.token,
          'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }
      let urlseg;
      if(infoTypeUrl == "policies"){
        urlseg = "resources/policies";
      } else if (infoTypeUrl == "podcasts") {
          urlseg = infoTypeUrl;
      } else {
        urlseg = "resources/" + infoTypeUrl;
      }

      var result = this.http.get<InformationType>(hrHeroConfig.endpoint + '/InformationType?url=' + urlseg, options)
          .toPromise();
      return result;
  }

  getTopicByName(topicName) {
      let headers = new HttpHeaders({ 
       // 'Token': this.token, 
        'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      var result = this.http.get<Topic>(hrHeroConfig.endpoint + '/Topic/GetTopicByName/' + topicName, options)
          .toPromise();
      return result; 
  }

  getTopicByTaxonomy(taxonomyId) {
      let headers = new HttpHeaders({ 
       // 'Token': this.token, 
        'Content-Type': 'application/json' });
      let options =  {
          headers: headers
      }

      var result = this.http.get<Topic>(hrHeroConfig.endpoint + '/Topic/GetTopicByTaxonomyId/' + taxonomyId, options)
          .toPromise();
      return result; 
  }

  getState(stateId) {
      let headers = new HttpHeaders({ 
      //  'Token': this.token,
         'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      var result = this.http.get<Jurisdition>(hrHeroConfig.endpoint + '/States?stateId=' + stateId, options)
          .toPromise();
      return result;
  }

  getStateByName(stateName) {
      let headers = new HttpHeaders({
       //  'Token': this.token, 
         'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      var result = this.http.get<Jurisdition>(hrHeroConfig.endpoint + '/States/GetStateByName/' + stateName, options)
          .toPromise();
      return result;
  }

  getBooks(informationTypeId) {
      let headers = new HttpHeaders({
         //'Token': this.token, 
         'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      var result = this.http.get<Information[]>(hrHeroConfig.endpoint + '/Information?infoTypeId=' + informationTypeId, options)
          .toPromise();
      return result;
  }

  getInfo(infoUrl) {
      let headers = new HttpHeaders({ 
       // 'Token': this.token, 
        'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      var result = this.http.get<Information>(hrHeroConfig.endpoint + '/Information/GetInformationByUrl?url=' + infoUrl, options)
          .toPromise();
      return result;
  }

  getChapters(bookUrl) {
      let headers = new HttpHeaders({
         //'Token': this.token,
          'Content-Type': 'application/json' });
      let options =  {
    headers: headers
 }

      var result = this.http.get<Chapter[]>(hrHeroConfig.endpoint + '/Chapter?bookUrl=' + bookUrl, options)
          .toPromise();
      return result;
  }

  getNewsletters(path) {
      let headers = new HttpHeaders({ 
        //'Token': this.token, 
        'Content-Type': 'application/json' });
      let options =  {
      headers: headers
  }

      var result = this.http.get(hrHeroConfig.endpoint + '/Chapter/GetNewsletters?path='+path , options)
          .toPromise();
      return result;
  }

  //login

  login() {

    let data: any;

    data = {
      "UserName": "blrnpd",
      "Password": "smiley",
      "AuthenticationSystem": "WA"
    };

    let body = JSON.stringify(data);

    let headers = new HttpHeaders({
      'Accept': 'application/json',
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.post(hrHeroConfig.endpoint + '/Login', body, options);
  }

  downloadPDF(url) {
    let options =  {
      responseTye: 'blob'
  }

      return this.http.get(url, { responseType: 'blob' }).pipe(map(
          res => {
              return new Blob([res], { type: 'application/pdf' })
          }));
  }

  getCustomImages() {
      let headers = new HttpHeaders({
       //   'Token': this.token,
          'Content-Type': 'application/json'
      });
      let options =  {
        headers: headers
    }

      return this.http.get<CustomImage>(hrHeroConfig.endpoint + '/SiteCustomization/GetImages?userId=' + this.mbDataService.user.UserId + '&applicationId=' + hrHeroConfig.applicationId,options)
      .toPromise();
  }

getCustomTags(membershipId:number,userId?:number) {
  let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
  });
  let options =  {
    headers: headers
}

if(userId)
{
  return this.http.get<CustomTag[]>(hrHeroConfig.endpoint + '/CustomTag?membershipid='+ membershipId + '&applicationId=1&userId=' + userId,options)
  .toPromise();
}
else
{
  return this.http.get<CustomTag[]>(hrHeroConfig.endpoint + '/CustomTag?membershipid='+ membershipId + '&applicationId=1' ,options)
  .toPromise();
}
  
}
saveCustomTags(customTags) {
  let headers = new HttpHeaders({
    'Accept': 'application/json',
    //'Token': this.token,
    'Content-Type': 'application/json'
  });

  let options =  {
      headers: headers
  }
  return this.http.post<any>(hrHeroConfig.endpoint + '/CustomTag', customTags,options).toPromise();
}
  saveCustomImage(customImage: FormData) {

      customImage.append('ApplicationId', hrHeroConfig.applicationId);
      customImage.append('UserId', this.mbDataService.user.UserId.toString());

      return this.http.post<UrlLink>(hrHeroConfig.endpoint + '/SiteCustomization/SaveImage', customImage)
      .toPromise();
  }

  validateEmail(email: string) {
    let headers = new HttpHeaders({ 
     // 'Token': this.token, 
      'Content-Type': 'application/json' });
    let options =  {
      headers: headers
  }

      return this.http.get(hrHeroConfig.endpoint + '/CustomerCentral/isEmailAvailable?email=' + email,options )
      .pipe(map(res=>res))  
          .toPromise();
  }

  isUsernameAvailable(username: string) {

    let headers = new HttpHeaders({ 
     // 'Token': this.token, 
      'Content-Type': 'application/json' });
    let options =  {
      headers: headers
  }
      return this.http.get<boolean>(hrHeroConfig.endpoint + '/CustomerCentral/isUsernameAvailable?username=' + username, options)
          .toPromise();
  }

  createChildAccount(user: CCUser) {
      var parentId = this.mbDataService.user.CustomerId;
      let headers = new HttpHeaders({
        // 'Token': this.token, 
         'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }
      var membershipId = user.MembershipId ? user.MembershipId : this.mbDataService.user.MembershipIds[0];
      var enc_password = encodeURIComponent(user.Password);
      return this.http.get<CCCustomer>(hrHeroConfig.endpoint + '/CustomerCentral/CreateChildAcount?username=' + user.username + '&password=' + enc_password +
          '&email=' + user.Email + '&firstName=' + user.FirstName + '&lastName=' + user.LastName + '&company=' + user.Company +
          '&parentMembershipId=' + membershipId,options )
          .toPromise();
  }

  createUserAndLink(username: string, password: string, customerId: number) {
      let headers = new HttpHeaders({
        'Accept': 'application/json', 
       // 'Token': this.token, 
        'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      return this.http.post(hrHeroConfig.endpoint + '/CustomerCentral/CreateUserAndLink?username=' + username + '&password=' + password +
          '&customerId=' + customerId, null, options)
          .pipe(map(res=>res))  
          .toPromise();

  }

  updateUser(customerId: number, profileAddress) {
      let headers = new HttpHeaders({
          'Accept': 'application/json',
         //'Token': this.token,
          'Content-Type': 'application/json'
      });

      let options =  {
        headers: headers
    }
      var body = JSON.stringify(profileAddress);

      return this.http.post(hrHeroConfig.endpoint + '/CustomerCentral/UpdateCustomerAddress?customerId=' + customerId, body, options)
      .pipe(map(res=>res))      
      .toPromise();
  }

  updateCustomerEmail(customerId: number, email:string) {
      let headers = new HttpHeaders({
         'Accept': 'application/json', 
         //'Token': this.token, 
         'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      return this.http.post(hrHeroConfig.endpoint + '/CustomerCentral/UpdateCustomerEmail?customerId=' + customerId + '&email=' + email, null, options)
      .toPromise();
  }

  updateUsername(userId: number, newUsername: string) {

      let headers = new HttpHeaders({ 
        'Accept': 'application/json', 
       // 'Token': this.token, 
        'Content-Type': 'application/json' });
      let options =  {
        headers: headers
    }

      return this.http.post(hrHeroConfig.endpoint + '/CustomerCentral/UpdateUsername?userId=' + userId + '&newUsername=' + newUsername, null, options)
      .toPromise();
  }

  updateMembership(customerId: number, currentMembershipId: number, newMembershipId: number) {

      let headers = new HttpHeaders({ 
        'Accept': 'application/json', 
        //'Token': this.token, 
        'Content-Type': 'application/json' });
      let options =  {
          headers: headers
      }

      return this.http.post(hrHeroConfig.endpoint + '/CustomerCentral/UpdateMembership?customerId=' + customerId +
          '&currentMembershipId=' + currentMembershipId + '&newMembershipId=' + newMembershipId, null, options)
          .toPromise();
  }

  linkCustomerToMembershipByEmail(email: string, membershipId?: number){
    let headers = new HttpHeaders({ 
      'Accept': 'application/json', 
      //'Token': this.token, 
      'Content-Type': 'application/json' });

    let options =  {
        headers: headers
    }

    membershipId = membershipId ? membershipId : this.mbDataService.user.MembershipIds[0];
    return this.http.post<number>(hrHeroConfig.endpoint + '/CustomerCentral/LinkAccountToMembership?email=' + email 
        + '&membershipId=' + membershipId,null,options)
        .toPromise();
    }

  removeChildAcct(parentMembershipId: number, childCustomerId: number) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
      headers: headers
  }

      return this.http.get(hrHeroConfig.endpoint + '/CustomerCentral/RemoveChildAcct?parentMembershipId=' + parentMembershipId +
          '&childCustomerId=' + childCustomerId, options)
          .pipe(map(res=>res))  
          .toPromise();
  }
  reindexType(sourceInfoTypeId) {

    let headers = new HttpHeaders({
     // 'Token': this.token,
      'Content-Type': 'application/json'
    });

    let options =  {
        headers: headers
    }

    return this.http.get(hrHeroConfig.endpoint + '/Information/BuildIndex?site=hr&databaseIds=1&informationTypeId=' + sourceInfoTypeId + '&destructive=true', options)
    .pipe(map(res=>res))    
    .toPromise();
  }

  downloadInformationFile(id,fileName,documentType)
  {
    let headers: any = {
     // 'Token': this.token,
      'Content-Type': 'application/json'
    }


    let params: any = {id: id};
    if (fileName)
    {
       params = {id: id, fileName: fileName};
    }

  let options =  {
      headers: headers,
      params: params,
      observe: "response" as 'body',
      responseType: 'blob' as 'json'
   }
      
          return this.http.get<any>(hrHeroConfig.endpoint + '/RenderDocument', options).subscribe(respData => {
            this.processDownloadResponse(respData,documentType);
          }, error => {
        
          });
        
    }
    downloadResourceFile(fileName,documentType)
    {
      let headers: any = {
       // 'Token': this.token,
        'Content-Type': 'application/json'
      }


      let params: any = {fileName: fileName};
  

    let options =  {
        headers: headers,
        params: params,
        observe: "response" as 'body',
        responseType: 'blob' as 'json'
    }
        
            return this.http.get<any>(hrHeroConfig.endpoint + '/RenderDocument/GetResource', options).subscribe(respData => {
              this.processDownloadResponse(respData,documentType);
            }, error => {
          
            });
        
    }
    private processDownloadResponse(respData,documentType)
    {
      var headers = respData.headers;
      var contentDisposition = headers.get('content-disposition');
      var downloadName="download." + documentType;
      if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) { 
          downloadName = matches[1].replace(/['"]/g, '');
        }
      }
      
      this.downLoadFile(respData.body, documentType, downloadName);
    }
  private downLoadFile(data: any, type: string, fileName: string) {
    var blob = new Blob([data], { type: type.toString() });
    var downloadURL = window.URL.createObjectURL(data);
    var link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.href = downloadURL;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(downloadURL);
    link.remove();
  }
}
