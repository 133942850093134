import { Component, Provider, ElementRef, OnInit, Input, Compiler, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { HrHeroService } from '../../shared/services/hr-hero.service'
import { Information } from '../../shared/types/information.type'

@Component({
    templateUrl: './resource-details.html',
    styleUrls: ['./resource-details.css']
})

export class ResourceDetailsComponent {
    informationSelected: Information;
    sanitizedBody: any;

    constructor(public _route: ActivatedRoute, public _router: Router, private _heroService: HrHeroService, private _location: Location, private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this._route.params.subscribe(params => {
            if (params["category"] != null && params["topic"] != null && params["title"] != null) {
                //data urls in InformationTypeId and Information use the old url, checklists-forms.
                if (this._route.snapshot.url[0].path == 'policies') {
                  this.selectInformationByURL('policies', params["category"], params["topic"], params["title"]);
                }
                else {
                    this.selectInformationByURL('resources/' + params["type"], params["category"], params["topic"], params["title"]);
                }
            }
        });
    }

    selectInformationByURL(type, category, topic, page) {
        this.sanitizedBody = "";
      if (type == "resources/policies") {
            type = "resources/policies";
        }
        this._heroService.getInformationByUrlParts(type, category, topic, page).then(information => {
            if (information != null) {
                this.informationSelected = information;
                (this.informationSelected as any).SanitizedBody = this.sanitizer.bypassSecurityTrustHtml(this.informationSelected.Body);

                this.sanitizedBody = this.sanitizer.bypassSecurityTrustHtml(this.informationSelected.Body);
            } else {
                this._router.navigate(['/error/404']);
            }
        });
    }

    linkType(informationSelected: Information) {

        if (informationSelected.FilePath == null || informationSelected.FilePath == '') {
            return 1;
        }
        else if (informationSelected.FilePath.includes(':')) {
            return 2;
        }
        else {
            return 3;
        }
    }
}
