import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.css']

})
export class FooterComponent {

  year = Date.now();
  
  @Input() footerLogo: string;
  @Input() footerAddress: string;
  @Input() footerPhone: string;

  constructor(
    private router: Router
  ) { }


  gotToTop() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
  }

}
