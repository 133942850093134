
<div *ngIf="information != null">

  <div *ngFor="let article of information">

    <div style="font-weight:bold;"><a style="cursor:pointer;" [routerLink]="[article.Url]" class="link">{{article.Title}}</a></div>
    <div>{{article.PubDate  | date: 'EEEE, MMMM d, y'}}</div><br />
    <div [innerHTML]="article.Teaser"></div>

    <div style="margin-top:10px; margin-bottom:15px;">
      <a [routerLink]="[article.Url]" class="link-bold">Read More</a>
    </div>

    <hr />
  </div>
</div>
