<div><p style="text-align: center;"><br></p><p style="text-align: center;"><img src="/ClientApp/hero.hr/Hero.HR.Client/dist/assets/images/TopicAnalysis.png" class="fr-fic fr-dii" style="width: 105px;"></p>

  <div class="nav-sub-link" style="text-align: center;">Federal & State Topic Analysis</div>
  
  <div class="blue-tool-line-container"><div class="blue-tool-line" style="text-align: center;"><br></div></div>
  
  <div class="body-content-italic" style="text-align: center;">Side-by-side plain-English analysis on every key HR Topic</div>
  <p>
    <br>
  </p>

  <p>
    <br>
  </p>

  
  <div style="padding-top: 27px; text-align: center;"><a class="btn-blue-sm" [routerLink]="['/federal-state-topic-analysis']">Get Started</a></div>

</div>
