
<div  class="row" style="text-align:center;">
  <img src="../../assets/images/HR_Podcasts.png" style="width:1130px;" />
</div>

<div *ngIf="showDetails == 0" style="margin-top:20px; margin-bottom:20px;">
  <div *ngIf="podcasts != null">
    <h1>RECENT EPISODES</h1>
    <p style="font-size:13px;"><b>The mission of HR Works is to provide clear, relevant, actionable information on topics that matter to HR professionals through engaging and insightful 
      interviews with experts and thought leaders.</b> </p>
    <div class="container top20">
      <div *ngFor="let podcast of podcasts">
        <div id="podcastThumbnail" style="display:block; float:left; width:200px; margin-right:30px;">
          <img *ngIf="podcast.Thumbnail" src="{{podcast.Thumbnail}}" style="max-height:146px; max-width:195px; padding-bottom:20px;" />
          <img *ngIf="!podcast.Thumbnail" src="../../assets/images/podcast_default.jpg" style="max-height:146px; max-width:195px; padding-bottom:20px;" />
        </div>
        <div id="podcastDetails">
          <h2> 
            <a (click)="selectPodcast(podcast)" class="link">{{podcast.Title}}</a>
          </h2>
          <div class="entry-meta">
            {{podcast.PubDate  | date: 'MMMM d, y'}} | {{podcast.TopicDesc}}
          </div><br />
          <div class="row" style="margin-left:0px !important;" [innerHTML]="podcast.Teaser"></div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</div>

<div *ngIf="showDetails == 1" style="margin-top:20px;">
  <div *ngIf="selectedPodcast != null">
    <div *ngIf="selectedPodcast != null" style="display:none;">Unified ID: {{selectedPodcast.SourceInfoId}}</div>
    <div class="white-well">
      <h2>{{selectedPodcast.Title}}</h2>

      <div class="entry-meta">
        <span *ngIf="selectedPodcast.Author!=null">By {{selectedPodcast.Author}} |</span> {{selectedPodcast.PubDate  | date: 'MMMM d, y'}} | {{selectedPodcast.TopicDesc}} | HR Works Podcast
      </div><br />
      <div [innerHTML]="selectedPodcast.SanitizedBody" style="margin-bottom:20px;"></div>
        <div class="document-btns" style="padding-top:15px; padding-bottom:15px;">
        <bookmarks [bookmarkTitle]="selectedPodcast.Title" [informationId]="selectedPodcast.InformationId" [informationTypeId]="selectedPodcast.InformationTypeId" [topicId]="selectedPodcast.TopicId"></bookmarks>
      </div>
    </div>
  </div>
</div>