import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, PlatformLocation } from '@angular/common';
import { DomSanitizer} from '@angular/platform-browser';

import { HrHeroService } from '../../shared/services/hr-hero.service'
import { Information } from '../../shared/types/information.type'

@Component({
    selector: 'podcasts',
    templateUrl: './podcasts.html',
    styleUrls: ['./podcasts.css']
})

export class Podcasts {
    podcasts: Information[];
    selectedPodcast: Information;
    showDetails: number = 0;
    podcastInfoType = 40;

    constructor(private sanitizer: DomSanitizer, private _heroService: HrHeroService, public _route: ActivatedRoute, private _location: Location, _platformLocation: PlatformLocation,
        private router: Router) {
        _platformLocation.onPopState(() => {
            this.showDetails = 0;
        });
    }

    ngOnInit() {
        this._route.params.subscribe(params => {
            if (params["category"] != null && params["topic"] != null && params["title"] != null) {
                this.showDetails = 1;

                this._heroService.getInformationByUrlParts("podcasts", params["category"], params["topic"], params["title"]).then(item => {
                    (item as any).SanitizedBody = this.sanitizer.bypassSecurityTrustHtml(item.Body);
                    (item as any).SanitizedTeaser = this.sanitizer.bypassSecurityTrustHtml(item.Teaser);
                    this.selectedPodcast = item;
                });
            }
            else if (params["category"] != null && params["topic"] != null) {
                this.selectPodcastByCategoryTopic(params["category"], params["topic"]);
            }
            else {
                this._heroService.getAllInformationDesc(this.podcastInfoType).then(data => {
                    data.forEach(item => {
                        (item as any).SanitizedBody = this.sanitizer.bypassSecurityTrustHtml(item.Body);
                        (item as any).SanitizedTeaser = this.sanitizer.bypassSecurityTrustHtml(item.Teaser);
                    });
                    this.podcasts = data;
                });
            }
        });
    }

    selectPodcast(podcastSelected: Information) {
        this.showDetails = 1;
        //this.router.navigateByUrl(podcastSelected.Url);
        this.router.navigate([podcastSelected.Url]);
    }

    selectPodcastByCategoryTopic(categoryURL, topicURL) {
        var infoTypeUrl = "podcasts";

        this._heroService.getTopicByUrl(topicURL).then(topic => {
            this._heroService.getCategoryByUrl(categoryURL).then(category => {
                this._heroService.getInfoTypeByUrl(infoTypeUrl).then(infoType => {
                    if (infoType) {
                        this._heroService.getInformation(infoType.InformationTypeId, category.CategoryId, topic.TopicId, null, null, null).then(data => {
                            data.forEach(item => {
                                (item as any).SanitizedBody = this.sanitizer.bypassSecurityTrustHtml(item.Body);
                                (item as any).SanitizedTeaser = this.sanitizer.bypassSecurityTrustHtml(item.Teaser);
                            });
                            this.podcasts = data;
                      });
                    }
                });
            });
        });
    }
}