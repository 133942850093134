import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  templateUrl: './unauthorized.html',
  styleUrls: ['./unauthorized.css']
})

export class Unauthorized {

  constructor(
    public _route: ActivatedRoute
  ) { }

  ngOnInit() {
  }
  
}
