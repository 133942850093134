import { Component, Provider, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HrHeroService } from '../../shared/services/hr-hero.service'
import { Information } from '../../shared/types/information.type'
import { hrHeroConfig } from '../../../environments/environment'

@Component({
  selector: 'training-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})

export class TrainingNewDashboardComponent {

  information: Information[];

  constructor(
    private _heroService: HrHeroService,
    public _router: Router, 
  ) { }

  ngOnInit() {

    this._heroService.getInformation(hrHeroConfig.trainingTypes.toString(), null, null, null, -90).then(information => {

      if (information != null) {
        this.information = information;
        this.information.sort((a:Information,b:Information) => {
          return a.PubDate > b.PubDate ? -1 : (a.PubDate < b.PubDate ? 1 : 0);
        });
        console.log("sorted new trainings");
      }

    });

  }

  selectInformation(informationItem: Information) {
    this._router.navigate([informationItem.Url]);
  }

}
