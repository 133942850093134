export const environment = {
  production: true
};

export var hrHeroConfig = {
  "endpoint": "https://hero.blr.com/api",
  "trainingTypes": [2,3,4,5,6,7,8,9,10,11,48],
  "resourcesTypes": [12,14,18,34,41,37,47],
  "applicationId": "198a42e5-1bd7-430b-8e44-13f5e73432f8",
  "origin": window.location.hostname,
  "isSecure": "true",
  "ccLogoutUrl": "https://accounts.blr.com",
  "ehbURL": "https://handbookbuilder.blr.com/Login.aspx",
  "flsaURL":"https://flsa.smarthrmanager.com/Login.aspx",
  "trainingTodayUrl": "https://gold.trainingtoday.com/account/login",
  "usesInquiry":true
}

export var authConfig = {
  "endpoint": "https://auth.blr.com/"
}

export var config = {
  "profileManagerService": "https://userprofileapi.blr.com",
  "applicationId": "5DFB4010-E889-4BA6-BED7-F69884981EEC"
}
