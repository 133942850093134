import { Component, TemplateRef, ElementRef, OnInit, Input, Compiler, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
//import { BsModalComponent } from 'ng2-bs3-modal';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

import { HrHeroService } from '../../../shared/services/hr-hero.service'
import { AnalysisSection } from '../../../shared/types/analysis-section.type'
import { MembershipDataService } from '../../../shared/services/membership.data.service'
import { Topic } from '../../../shared/types/topic.type'

import { SearchTerms } from '../../../shared/types/search-terms'
import { CategoryTopic } from '../../../shared/types/categoryTopic.type'
import { hrHeroConfig } from '../../../../environments/environment';

import  'jquery';


@Component({
  templateUrl: './topic-analysis.html',
  styleUrls: ['./topic-analysis.css']
})

export class TopicAnalysisComponent {
  dataAvailable: number = 0;
  category: any;
  topicInfo: Topic;
  analysisSummary: any;
  nationalAnalysis: any;
  stateAnalysis: any;
  stateInfo: any;
  refreshTopic: any;
  relatedContent: any;
  toggleAllButtonLabel: string = "Expand";
  information: any;
  headerLogo: string;
  printVersion: boolean = false;
  showFederal: boolean = true;
  showState: boolean = true;
  errorMessage: string = "";
  stateId: number = 0;
  stateName: string = "";

  hasAllResources: boolean;
  printOptionsModal: BsModalRef;

  tags: SearchTerms[];
  toggleCategoryButtonLabel: string = "Expand";
  toggleAtoZButtonLabel: string = "Show";
  searchResults: string = "";
  aToZcategoryTopics: CategoryTopic[];
  categoryTopics: CategoryTopic[];
  aToZLabel = "";
  searchInput: string = "";
  stateInfoLoading: boolean = false;

  constructor(private route: ActivatedRoute, public _router: Router, private _heroService: HrHeroService, private _mbService: MembershipDataService, private elRef: ElementRef, private modalService: BsModalService)
  { }

  ngOnInit() {
    var topicId;
    let currentUrl = this._router.url;
    this.hasAllResources = this._mbService.getSettingValue('ChecklistsForms') == "true";
    this.route.params.subscribe(params => {
      this.stateId = (this._mbService.selectedState == -1 && this._mbService.userProfile.CurrentStateId != 0) ? this._mbService.userProfile.CurrentStateId : (this._mbService.selectedState == 0 || this._mbService.userProfile.CurrentStateId == 0 ? 52 : this._mbService.selectedState);

      if (this.route.snapshot.queryParams["printVersion"] != null && this.route.snapshot.queryParams["federal"] != null && this.route.snapshot.queryParams["state"] != null) {
        this.printVersion = this.route.snapshot.queryParams["printVersion"] == 'true' ? true : false;

        if (this.printVersion) {
          if (this.route.snapshot.queryParams["federal"] == 'false') {
            this.showFederal = false;
          }
          if (this.route.snapshot.queryParams["state"] == 'false') {
            this.showState = false;
          }
          else {
            this.stateId = this.route.snapshot.queryParams["state"];
          }
          this.headerLogo = this._mbService.getSettingValue('HeaderLogo').replace("//", "\\");
        }
      }

      if (params["state"] != null) {
        this.stateName = params["state"];
        this._heroService.getStateByName(this.stateName).then(state => {
          this.stateId = state.JurisdictionId;
          this._mbService.selectedState = this.stateId;
        });
      }
      if (params["category"] != null) {
        this.category = params["category"];
      }
      else {
        this._router.navigate(['/error/404']);
      }
      if (params["topic"] != null) {
        let topicUrl = params["topic"];
        this._heroService.getTopicByUrl(topicUrl).then(topic => {
          this.topicInfo = topic;
          if (this.topicInfo == undefined)
          {
            this._router.navigate(['dashboard']);
          }
          if (this.topicInfo.TopicId != null && this.topicInfo.TopicId > 0) {
            this.GetState(this.stateId);
            this.GetAnalysis(this.topicInfo.TopicId, this.stateId);
          }
          else {
            console.log("in else, invalid url missing query string");
          }
        });
      }
      else {
        this._router.navigate(['/error/404']);
      }

      //}

      this.getCategoryTopics();
      $('#aToZPanel').hide();
      this.toggleAtoZButtonLabel = "Show";

      if (this.printVersion) {
        $("#app").hide();
        $("#mainNavBar").hide();
        window.setTimeout(function () {
          $("#drift-widget-container").hide();
        }, 3000)
      }
    });
  }

  GetAnalysis(topicId, stateId) {
    this._heroService.getAnalysis(topicId, stateId).then(analysis => {
      if (analysis != null) {

        this.analysisSummary = analysis.AnalysisDataList[0].Summary;

        this.information = analysis.Information;
        this.category = this.information.CategoryDesc;

        var nationalAnalysis = analysis.AnalysisDataList[0].TransformedData;
        this.nationalAnalysis = this.TransformFederalAndSummaryToArray(nationalAnalysis);

        if (analysis.AnalysisDataList[1] != null) {
          var stateAnalysis = analysis.AnalysisDataList[1].TransformedData;
          this.stateAnalysis = this.TransformXMLToArray(stateAnalysis);
        }

        var resources = analysis.Resources;
        //this.relatedContent = resources.filter(r => r.JurisId == stateId);
        this.relatedContent = this.condenseRelatedContent(resources);
        //resources.filter(r => r.Name == "Forms" || r.Name == "Checklists" || r.Name == "Policies" || r.Name == "Calculators" || r.Name == "PowerPoints");
        /*this.relatedContent = this.relatedContent.map(item => {
          switch(item.Name){
            case "Federal Labor Standards Act":
            case "Benefits":
            case "Book":
          }
        });*/
        this.dataAvailable = 1;
      }
    });
  }

  GetStateAnalysis(topicId, stateId) {
    this.stateInfoLoading = true;
      this._heroService.getAnalysis(topicId, stateId).then(analysis => {

          if (analysis.AnalysisDataList[1] != null &&
              (analysis.Information.ExpireDate == null || new Date(analysis.Information.ExpireDate) >= new Date())) {
            var stateAnalysis = analysis.AnalysisDataList[1].TransformedData;
            this.stateAnalysis = this.TransformXMLToArray(stateAnalysis);
          }
          else {
              this.stateAnalysis = null;
          }
        this.stateInfoLoading = false;
      this.dataAvailable = 1;
    });
  }

  GetTopicInfo(topicId) {
    this._heroService.getTopicInfo(topicId).then(topicInfo => {
      if (topicInfo != null) {
        this.topicInfo = topicInfo;
      } else { }
    });
  }

  TransformFederalAndSummaryToArray(xmlInput) {
    var sectionArr = [];
    var summarySectionObj = new AnalysisSection();
    summarySectionObj.Title = "Summary";
    summarySectionObj.Body = this.analysisSummary;
    sectionArr.push(summarySectionObj);

    var parser = new DOMParser();
    var xmlData = parser.parseFromString(xmlInput, "application/xml");

    Array.prototype.forEach.call(xmlData.childNodes[0].childNodes, function (node) {
      var sectionObj = new AnalysisSection();
      sectionObj.Title = node.childNodes[0].childNodes[0].childNodes[1].textContent;

      if (node.childNodes[1].innerHTML && node.childNodes[1].innerHTML != null) {
        sectionObj.Body = node.childNodes[1].innerHTML;
      }
      else {
        var s = new XMLSerializer();
        sectionObj.Body = s.serializeToString(node.childNodes[1]);
        //sectionObj.Body = node.childNodes[1].textContent;
      }

      sectionArr.push(sectionObj);
    });

    return sectionArr;
  }

  TransformXMLToArray(xmlInput) {
    var sectionArr = [];
    if (xmlInput) {
      var parser = new DOMParser();
      var xmlData = parser.parseFromString(xmlInput, "application/xml");

      Array.prototype.forEach.call(xmlData.childNodes[0].childNodes, function (node) {
        var sectionObj = new AnalysisSection();
        sectionObj.Title = node.childNodes[0].childNodes[0].childNodes[1].textContent;

        if (node.childNodes[1].innerHTML && node.childNodes[1].innerHTML != null) {
          sectionObj.Body = node.childNodes[1].innerHTML;
        }
        else {
          //sectionObj.Body = node.childNodes[1].textContent;
          var s = new XMLSerializer();
          sectionObj.Body = s.serializeToString(node.childNodes[1]);
        }
        sectionArr.push(sectionObj);
      });
    }

    return sectionArr;
  }

  toggleClass(index) {
    if ($('#plusMinus_' + index).hasClass('fa-plus')) {
      $('#plusMinus_' + index).removeClass('fa-plus');
      $('#plusMinus_' + index).addClass('fa-minus');
    } else if ($('#plusMinus_' + index).hasClass('fa-minus')) {
      $('#plusMinus_' + index).removeClass('fa-minus');
      $('#plusMinus_' + index).addClass('fa-plus');
    }
  }

  toggleStateClass(index) {
    if ($('#statePlusMinus_' + index).hasClass('fa-plus')) {
      $('#statePlusMinus_' + index).removeClass('fa-plus');
      $('#statePlusMinus_' + index).addClass('fa-minus');
    } else if ($('#statePlusMinus_' + index).hasClass('fa-minus')) {
      $('#statePlusMinus_' + index).removeClass('fa-minus');
      $('#statePlusMinus_' + index).addClass('fa-plus');
    }
  }

  toggleAllSections() {
    if (this.toggleAllButtonLabel == "Expand") {
      this.toggleAllButtonLabel = "Collapse";

      $('.panel-collapse').addClass('in');
      $('.plus-minus').addClass('fa-minus');
      $('.plus-minus').removeClass('fa-plus');
      $('.panel-collapse').css("height", "100%");
    } else if (this.toggleAllButtonLabel == "Collapse") {
      this.toggleAllButtonLabel = "Expand";

      $('.panel-collapse.in');
      $('.panel-collapse').removeClass('in');
      $('.plus-minus').addClass('fa-plus');
      $('.plus-minus').removeClass('fa-minus');
    }
  }

  showTopics() {
    $('#topicsAZComponent').toggle(500);
  }

  onStateSelectionComplete() {
    if (this._mbService.selectedState == "null" || this._mbService.selectedState == 52) {
      this.stateAnalysis = null;
      this.stateInfo = null;
    }
    else {
      this.GetState(this._mbService.selectedState);
      this.stateName = this._mbService.selectedStateName;
      this._mbService.specialInquiryRequest("STATE_CHANGE",this.stateName);
      this.GetStateAnalysis(this.topicInfo.TopicId, this._mbService.selectedState);
    }
  }

  GetState(stateId) {
    this._heroService.getState(stateId).then(state => {
      this.stateInfo = state;
    });
  }

  onNotifyToRefresh(catTopicObj) {
    this.category = catTopicObj.categoryName;
    this._heroService.getTopicByUrl(catTopicObj.topicURL).then(topic => {
      this.topicInfo = topic;

      if (this.topicInfo.TopicId != null && this.topicInfo.TopicId > 0) {
        this.GetAnalysis(this.topicInfo.TopicId, this._mbService.selectedState);
      }
      else {
        console.log("in else, invalid url missing query string");
      }
    });
    $('#topicsAZComponent').toggle(500);
    window.scrollTo(0, 0);

    if (catTopicObj.categoryURL && catTopicObj.topicURL) {
      var url = '/analysis/' + catTopicObj.categoryURL + '/' + catTopicObj.topicURL;
      this._router.navigate([url]);
    }
  }
  condenseRelatedContent(resrcs:any[]){
    let books = null;
    let trainings = null;
    let filtered = [];
    resrcs.forEach(resrc => {
      if (resrc.ResourceId == 15 || (this.hasAllResources && (resrc.ResourceId == 12 || resrc.ResourceId == 14 || resrc.ResourceId == 18 ||  resrc.ResourceId == 34 || resrc.ResourceId == 40 || resrc.ResourceId == 41 || resrc.ResourceId == 37 || resrc.ResourceId == 47))) {
          filtered.push(resrc);
      } else if ( hrHeroConfig.trainingTypes.includes(resrc.ResourceId)  ) {
        if (trainings == null) {
          trainings = resrc;
          trainings.ResourceId = -1; //collective type ID for all trainings
          trainings.Name = "Trainings";
          filtered.push(resrc);
        } else {
          trainings.Count += resrc.Count;
        }
      } else if (resrc.ResourceId >= 27 && resrc.ResourceId <= 29) {
        if (books == null) {
          books = resrc;
          books.ResourceId = -2; //collective type ID for all books
          books.Name = "Books";
          filtered.push(resrc);
        } else {
          books.Count += resrc.Count;
        }
      }
    });

    return filtered;
  }

  goToRelatedContent(content) {
    switch (content) {
      case "Forms":
      case "Checklists":
      case "Guidance Documents":
      case "Letters":
      case "Notices":
      case "Policies":
      case "New Hire Packets":
        var infoUrl = this.information.InfoUrl.endsWith('/') ? this.information.InfoUrl.slice(0, -1) : this.information.InfoUrl;
        var tempArr = infoUrl.split("/");
        var topicUrl = tempArr[tempArr.length - 1];
        var categoryUrl = tempArr[2];
        var url = 'resources/' + content.replace(/ /g, '-').toLowerCase() + '/' + categoryUrl + '/' + topicUrl;
        this._router.navigate([url]);
        break;
      case "Podcasts":
            var infoUrl = this.information.InfoUrl.endsWith('/') ? this.information.InfoUrl.slice(0, -1) : this.information.InfoUrl;
            var tempArr = infoUrl.split("/");
            var topicUrl = tempArr[tempArr.length - 1];
            var url = content.replace(/ /g, '-').toLowerCase() + '/All/' + topicUrl;
            this._router.navigate([url]);
          break;
      case "Checklists":
        var infoUrl = this.information.InfoUrl.endsWith('/') ? this.information.InfoUrl.slice(0, -1) : this.information.InfoUrl;
        var tempArr = infoUrl.split("/");
        var topicUrl = tempArr[tempArr.length - 1];

        var url = 'resources/checklists/All/' + topicUrl;
        this._router.navigate([url]);
        break;
      case "Calculators":
        this._router.navigate(['calculators']);
        break;
      case "Books":
          this._router.navigate(['ReferenceGuides']);
          break;
      case "Trainings":
        //localStorage.setItem('languageId', 'null');
        //localStorage.setItem('categoryId', this.information.CategoryId);
        //localStorage.setItem('topicId', this.information.TopicId);
        //localStorage.setItem('go', 'true');

        this._router.navigate(['training/related/' + this.information.TopicId]);
        break;
    }
  }

  openPrintOptionsModal(template: TemplateRef<any>) {
    this.printOptionsModal = this.modalService.show(template);  
  }

  printPreview(federalChecked, stateChecked) {
    this.errorMessage = "";
    if (federalChecked == false && stateChecked == false) {
      this.errorMessage = "Must check at least one option."
    }
    else {
      var state = stateChecked ? (this._mbService.selectedState == -1 ? this._mbService.userProfile.CurrentStateId : this._mbService.selectedState) : false;
      var url = "/hr" + this.information.InfoUrl.replace("Analysis", "analysis") + "?printVersion=true&federal=" + federalChecked + "&state=" + state;

      window.open(url, "_blank");
      this.closeModal()
    }
  }

  print() {
    $("#drift-widget-container").hide();
    $("#drift-widget-container").addClass("noPrint");
    window.print();
  }

  closeModal() {
    this.printOptionsModal.hide();
  }

  getCssClass(index) {
    var cssClass = "";

    if (index == 0) {
      cssClass = this.printVersion ? 'panel-collapse collapse in collapsable-content toggle noLeftRightBorder' : 'panel-collapse collapse in collapsable-content toggle';
    }
    else {
      cssClass = this.printVersion ? 'panel-collapse collapse collapsable-content toggle noLeftRightBorder' : 'panel-collapse collapse collapsable-content toggle';
    }
    return cssClass
  }

  getCategoryTopics() {

      this._heroService.getCategoryTopics(16).then(ct => {
          if (ct != null) {
              this.categoryTopics = ct;
          }
      });
  }

  toggleAtoZ() {
      if (this.toggleAtoZButtonLabel == "Show") {

          this.toggleAtoZButtonLabel = "Hide";
          this.aToZcategoryTopics = this.categoryTopics;
          this.searchResults = "";
          this.tags = null;
          $('#aToZPanel').show(500);

      } else {

          this.toggleAtoZButtonLabel = "Show";
          this.aToZcategoryTopics = null;
          this.searchResults = "";
          this.tags = null;
          $('#aToZPanel').hide(500);
          window.scrollTo(0, 0);

      }
  }

  goToAnalysisURL(item: CategoryTopic) {
      this._router.navigate(["/analysis/" + item.CategoryURL + "/" + item.TopicURL]);
  }

  displayAtoZLabel(char) {
      if (char != this.aToZLabel) {
          this.aToZLabel = char;
          return true;
      }
      return false;
  }

  search() {
      if ($(".tag-class").val() != null &&
          $(".tag-class").val() != "" &&
          $(".tag-class").val() != undefined) {
          if (this.tags == null || this.tags == undefined) {
              this.tags = new Array<SearchTerms>();
          }

          let model = new SearchTerms();
          model.display = $(".tag-class").val().toString();
          model.value = $(".tag-class").val().toString();
          this.tags.push(model);
          $(".tag-class").val("")

          this.itemUpdated();
      }
  }

  itemUpdated() {
      let searchText = '';
      searchText = this.tags.map(function (e) {
          return e.value;
      }).join(', ');

      if (searchText == '') searchText = 'null';
      this._heroService.getTopicsBySearchTerm(null, 16, searchText).then(searchItems => {
          if (searchItems != null && searchItems.length > 0) {
              this.aToZLabel = "";
              this.aToZcategoryTopics = searchItems;
              this.searchResults = "Search Results";
              this.toggleAtoZButtonLabel = "Show";

          } else {
              this.aToZLabel = "";
              this.aToZcategoryTopics = searchItems;
              this.searchResults = "No Results";
              this.toggleAtoZButtonLabel = "Show";

              if (searchText == 'null') {
                  this.aToZcategoryTopics = null;
                  this.searchResults = "";
              }
          }
      });
  }

  clearSearch() {
      this.aToZLabel = "";
      this.aToZcategoryTopics = null;
      this.searchResults = "";
      this.toggleAtoZButtonLabel = "Show";
      this.tags = null;
      $('#aToZPanel').hide(500);
      this.searchInput = null;
  }
}
