import { Component, TemplateRef } from '@angular/core';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';
import { HrHeroService } from '../../shared/services/hr-hero.service'
import { Information } from '../../shared/types/information.type'
import { MembershipDataService } from '../../shared/services/membership.data.service'

@Component({
    templateUrl: './reference-guides.html',
    styleUrls: ['./reference-guides.css']
})

export class ReferenceGuidesComponent {
    leaveDisabilityInfoTypeId: number = 27;
    FLSAInfoTypeId: number = 28;
    benefitsInfoTypeId: number = 29;
    hasApplication: boolean = false;

    leaveDisabilityBooks: Information[];
    flsaBooks: Information[];
    benefitsBooks: Information[];
    modal: BsModalRef;

    constructor(private _heroService: HrHeroService, private _mbService: MembershipDataService, private modalService: BsModalService) { }

    ngOnInit() {
        this.hasApplication = this._mbService.hasBenefits;
        this.getBooks();
    }

    getBooks() {
        this.getLeaveDisabilityBooks();
        this.getFLSABooks();
        this.getBenefitsBooks();
    }

    getLeaveDisabilityBooks() {
        this._heroService.getBooks(this.leaveDisabilityInfoTypeId).then(books => {
            this.leaveDisabilityBooks = books;
        });
    }

    getFLSABooks() {
        this._heroService.getBooks(this.FLSAInfoTypeId).then(books => {
            this.flsaBooks = books;
        });
    }

    getBenefitsBooks() {
        this._heroService.getBooks(this.benefitsInfoTypeId).then(books => {
            this.benefitsBooks = books;
        });
    }

    openModal(template: TemplateRef<any>) {
        this.modal = this.modalService.show(template);
    }
    
    closeModal()  {
        this.modal.hide();
    }
}