import { Category } from '../types/category.type'
import { Topic } from '../types/topic.type'
import { Jurisdition } from '../types/jurisdiction.type'
import { InformationType } from '../types/information-type.type'
import { Language } from '../types/language.type'
import { Information } from '../types/information.type'
import { DateRange } from '../types/date-range.type'

export class InformationAllModel {
  Categories: Category[];
  Topics: Topic[];
  Jurisdictions: Jurisdition[];
  DocumentTypes: InformationType[];
  Information: Information[];
  Languages: Language[];
  FromDate: Date;
  ToDate: Date;
  PubDateCategory: any;
  AllTimeTotal: number;
  NewItems: number;
  Upcoming: number;
}