import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';

@Component({
    selector: 'widget-tools-covid',
    styles: ['>>> .modal-xl { width: 1000px; }'],
    templateUrl: './widget-tools-covid.html'
})

export class WidgetToolsCovid {
    isLimitedAccess: boolean;

    constructor() { 
    }

    ngOnInit() {

    }

}

