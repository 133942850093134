import { Component } from '@angular/core';
import { MembershipDataService } from '../shared/services/membership.data.service'

//types
import { Widget } from '../shared/types/widget.type'
import { UserProfile } from '../shared/types/user-profile.type'

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../../../node_modules/dragula/dist/dragula.css', './dashboard.component.css']
})

export class DashBoardComponent {

  userProfile: UserProfile;
  left: Widget[];
  right: Widget[];
  selectedComponent: Widget;
  customWidgetsVisible: boolean = false;
  trainingVisible: boolean = true;
  resourcesVisible: boolean = true;

  constructor(
    private _mbService: MembershipDataService,
  ) 
  {
    this.selectedComponent = new Widget;
  }
 
  ngOnInit() {

    if (this._mbService.userProfileTypeId == 1) {
      this.customWidgetsVisible = true;
    }
    this.trainingVisible = this._mbService.getSettingValue('Training').toLowerCase() != "false";
    this.resourcesVisible = this._mbService.getSettingValue('Resources').toLowerCase() != "false";
  }

  hideWidget(item){
    item.Visible = false;
  }

  removeItem(item: Widget, placement) {
    item.VisibleOnHome = false;
  
  }


}
