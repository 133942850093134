import { Directive, HostListener, ElementRef } from "@angular/core";
import { Router } from '@angular/router';
import { HrHeroService } from "../services/hr-hero.service";
@Directive({ selector: '[secureDownload]' })
export class SecureDownloadDirective {
    constructor(private el: ElementRef,private router: Router,private _heroService: HrHeroService ) { }
    @HostListener('click', ['$event']) onClick($event){        
        
       // $event.stopPropagation();
        if ($event.target instanceof HTMLAnchorElement) {
            const element = $event.target as HTMLAnchorElement;
            const route = element?.getAttribute('href');
            if(route.indexOf('app_repository/HR/Resources/') !== -1) 
            {
                $event.preventDefault();
                var type = route.indexOf('docx')!==-1? "docx":"pdf"
                console.log("using api to download instead")
                this._heroService.downloadResourceFile(/[^/]*$/.exec(route)[0],type);
            }
        }
    }
    
}