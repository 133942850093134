import { Component, Provider, ElementRef, OnInit, TemplateRef} from '@angular/core';
//import { BsModalComponent } from 'ng2-bs3-modal';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'

@Component({
  selector: 'widget-tools-sf',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './widget-tools-sf.html'
})

export class WidgetToolsSF implements OnInit {
  isLimitedAccess: boolean;
  modal: BsModalRef;
  constructor(private _mbService: MembershipDataService, private modalService: BsModalService) { }

  ngOnInit() {

    this.isLimitedAccess = this._mbService.isLimitedAccess();
  }

  navigate() {
    if (this._mbService.userIsHRAuthenticated) {
      console.log("if i knew where Salary Finder was, this log would be replaced by navigating to it. (navigate)");
    } else {
      location.href = "/login";
    }
  }
    openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.show(template);
  }
  closeModal()  {
    this.modal.hide();
  }
}

