import { Topic } from '../../types/topic.type'
import { InformationType } from '../../types/information-type.type'
import { Language } from '../../types/language.type'
import { TrainingInformation } from '../../types/Training/training-information'

export class TrainingAllModel {
  DocumentTypes: InformationType[];
  Topics: Topic[];
  Information: TrainingInformation[];
  Languages: Language[];
  DatabaseIds: number;
  PageNumber: number;
  PageSize: number;
}
