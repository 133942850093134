
<a *ngIf="bookmarkId == null" class="btn-white" (click)="openBookmarkModal(bookmarkModal)">Bookmark</a>
<a *ngIf="bookmarkId > 0" (click)="openBookmarkModal(bookmarkModal)" class="default-link">Manage</a>

<!--bookmark modal-->
<ng-template #bookmarkModal>
  <div class="modal-header modal-header-hr" *ngIf="bookmarkId == null">
    Add Bookmark
  </div>
  <div class="modal-header modal-header-hr" *ngIf="bookmarkId != null">
    Manage Bookmark
  </div>
  <div class="modal-body" *ngIf="bookmarkFolders != null">
    <div style="margin-bottom:20px;" class="body-content-label" *ngIf="selectedBookmarkFolder != null && bookmarkTitle != null">
      <i class="fa fa-folder-open" aria-hidden="true"></i> My Bookmarks > {{selectedBookmarkFolder.Name}} > {{bookmarkTitle}}
    </div>
    <div class="body-content-label" style="margin-bottom:5px;">Bookmark Title:*</div>
    <input type="text" class="form-control" [(ngModel)]="bookmarkTitle" placeholder="Enter bookmark title" />
    <div class="body-content-label" style="margin-bottom:5px; margin-top:20px;">Bookmark Folder:</div>
    <select class="form-control" [(ngModel)]="selectedBookmarkFolder">
      <option *ngFor="let item of bookmarkFolders" [ngValue]="item">{{item.Name}}</option>
    </select>
    <div style="margin-top:10px; color:red;">{{errorMessage}}</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="deleteBookmark(bookmarkId)" *ngIf="bookmarkId != null">Delete</button>
    <button type="button" class="btn btn-default" (click)="saveBookmark()">Save</button>
    <button type="button" class="btn btn-default" (click)="dismissBookmarkModal()">Cancel</button>
  </div>
</ng-template>
