import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor ,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';


import { MembershipDataService } from '../services/membership.data.service'
import { hrHeroConfig } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private mbDataService: MembershipDataService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {     
        const isLoggedIn = this.mbDataService?.userProfileAPIToken;
        const isApiUrl = request.url.startsWith(hrHeroConfig.endpoint);
        if ((isLoggedIn  && isApiUrl) || (isLoggedIn &&  request.url.indexOf("app_repository")>-1)) {
            request = request.clone({
                headers: request.headers.set('token', this.mbDataService.userProfileAPIToken)
            });
        }        

        return next.handle(request);
    }
}