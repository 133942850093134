import { Component, TemplateRef, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DragulaService, dragula } from 'ng2-dragula'
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

import { User } from '../../shared/types/membershipbase-user'
import { UserProfile } from '../../shared/types/user-profile.type';

import { HrHeroService } from '../../shared/services/hr-hero.service';
import { UserProfileService } from '../../shared/services/user-profile.service';
import { MembershipDataService } from '../../shared/services/membership.data.service'

import { CCCustomer } from '../../shared/types/cc-customer';
import { CCUser } from '../../shared/types/cc-user';
import { prependListener } from 'cluster';
import { CustomTag } from '../../shared/types/custom-tag.type';

@Component({
    selector: 'edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.css']
})

export class EditUserComponent {

    form: FormGroup;
    ccUser: CCCustomer;
    customerId: number;
    initialCustomerId: number;
    userProfile: UserProfile = null;
    User: CCUser;
    internalPrefs: any;
    toolSettings: any;
    customWidgets: any;
    emailAvailable: boolean = true;
    emailLinkable: boolean = false;
    usernameAvailable: boolean = true;
    hasAccess: boolean = false;
    parentMemberships: any;
    membershipsAvailable: boolean = false;
    addOns: any;
    saveInProgress: boolean = false;
    customTags: CustomTag[]
    browserIsIE: boolean = (window.navigator.userAgent.indexOf('MSIE ') > 0) || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    modal: BsModalRef;

    constructor( public _route: ActivatedRoute, private _router: Router, private fb: FormBuilder, private _heroService: HrHeroService,
                private _up: UserProfileService, private _mb: MembershipDataService, private modalService: BsModalService ) {

        this.hasAccess = this._mb.getVisibility('User Management') && this._mb.AutherizationType==1;

        this.form = this.fb.group({
            Email: null,
            UserName: null,
            FirstName: null,
            LastName: null,
            Institution: null,
            MembershipId:0,
            AddOnId: 0
        });
        this.User = new CCUser();
        

        this._route.params.subscribe(params => {
            if (params["cid"] != null) {
                this.customerId = params["cid"];
                this.initialCustomerId = this.customerId;
                this.getCcUsers(params["cid"]);
                
                this._up.getUserProfile(params["cid"], this._mb.userProfileAPIToken, 2).then(result => {
                    if (result == null) {
                        this._up.saveUserProfile(params["cid"], JSON.stringify(this._mb.defaultUserProfile), this._mb.userProfileAPIToken, 2);
                        this.userProfile = this._mb.defaultUserProfile;
                    } else {
                        this.userProfile = JSON.parse(result.UserProfileJSON);
                    }
                   
                    this.internalPrefs = this.userProfile.AdminPreferences.filter(pref => pref.Id == 1 || pref.Id == 3); //custom widgets and user management
                    this.toolSettings = this.userProfile.AdminPreferences.filter(pref => (pref.Id >= 4 && pref.Id <= 7) || pref.Id == 10 || pref.Id == 11 || pref.Id == 12 || pref.Id == 13 || pref.Id == 14 || pref.Id == 15); //flsa, jdm, ehb, slcb, sf, help, covid, covidguide
                    this.customWidgets = this.userProfile.AdminPreferences.filter(pref => pref.Id == 8 || pref.Id == 9); //home and tools
                });
            }
        });
    }

    ngOnInit() {
        var parentId = this._mb.user.CustomerId;
        this.getMemberships(parentId);
        
    }
    
    getCustomTags(membershipId){            
         this._heroService.getCustomTags( membershipId, this.customerId).then(result => {
                this.customTags = result;
          });          
    }
    
    isChildAccount() {
        return this._mb.userProfileTypeId == 2;
    }

    getUser(userId: number) {
        if (this._mb.childCustomers != null) {
            let cust = this._mb.childCustomers.find(f => f.id == userId);
            if (cust != null)
                return cust;
        }
        else
            return this._heroService.getCustomerCentralUser(userId);
    }

    getCcUsers(cid) {

        if (this._mb.childCustomers == undefined || this._mb.childCustomers == null) {
            this._heroService.getCustomerCentralUsers().then(result => {
                this._mb.populateChildCustomers(result);
                this.filterChildrenToGetChild(cid);
            });
        } else {
            this.filterChildrenToGetChild(cid);
        }
    }

    filterChildrenToGetChild(cid) {

        if (this._mb.childCustomers.filter(f => f.id == cid).length > 0) {
            this.ccUser = this._mb.childCustomers.filter(f => f.id == cid)[0];

            let formobj = {
                UserName: this.ccUser.users[0].username,
                Email: this.ccUser.email,
                FirstName: this.ccUser.profileAddress ? this.ccUser.profileAddress.first_name : '',
                LastName: this.ccUser.profileAddress ? this.ccUser.profileAddress.last_name : '',
                Institution: this.ccUser.profileAddress ? this.ccUser.profileAddress.company : '',
                MembershipId: this.ccUser.memberships ? this.ccUser.memberships[0].id : 0,
                AddOnId: this.ccUser.addOns ? this.ccUser.addOns[0].id : 0
            };

            this.User.id = this.ccUser.users[0].id;
            this.User.username = this.ccUser.users[0].username;
            this.User.Email = this.ccUser.email;
            this.User.FirstName = this.ccUser.profileAddress ? this.ccUser.profileAddress.first_name : '';
            this.User.LastName = this.ccUser.profileAddress ? this.ccUser.profileAddress.last_name : '';
            this.User.Company = this.ccUser.profileAddress ? this.ccUser.profileAddress.company : '';
            this.User.ProfileAddressId = this.ccUser.profileAddress ? this.ccUser.profileAddress.id : 0; 
            this.User.MembershipId = this.ccUser.memberships ? this.ccUser.memberships[0].id : 0;
            this.User.AddOnId = this.ccUser.addOns ? this.ccUser.addOns[0].id : 0;
  
            //console.log("form object is %o", formobj);

            this.form = this.fb.group(formobj);
        } else {
            this.ccUser = null;
            //console.log("no customer found by cid " + cid);
        }
    }

    displayName(option) {
        switch (option.Id) {
            case 4: return "State Law Chart Builder";
            case 5: return "Employee Handbook Builder";
            case 6: return "Job Description Manager";
            case 7: return "Job Classification & FLSA Audit";
            case 8: return "Home page";
            case 9: return "Tools page";
            case 10: return "Salary Finder";
            case 11: return "Help widget";
            case 12: return "Coronavirus Resources";
            case 13: return "Employer's Guide to COVID-19";
            case 14: return "Resource Center";
            case 15: return "Topic Analysis";
            default: return option.Name;
        }
    }
    adminPrefShouldShow(id) {
        switch (id) {
            case 4: return this.getSettingBool("SLCB");
            case 5: return this.getSettingBool("EHB");
            case 6: return this.getSettingBool("JDM");
            case 7: return this.getSettingBool("FLSA");
            case 9: return this.getSettingBool("Tools");
            case 10: return this.getSettingBool("SF");
            case 11: return this.getSettingBool("HELP");
            case 12: return this.getSettingBool("COVID");
            case 13: return this.getSettingBool("COVIDGUIDE");
            case 14: return this.getSettingBool("RESOURCECENTER");
            case 15: return this.getSettingBool("TOPICANALYSIS");
            default: return true;
        }
    }
    getSettingBool(id) {
        let mbSetting = this._mb.getSettingValue(id);
        if (mbSetting) {
            return this._mb.getSettingValue(id).toLowerCase() != "false";
        } else {
            return false;
        }
    }

    saveProfile() {
        this._up.saveUserProfile(this.customerId, JSON.stringify(this.userProfile), this._mb.userProfileAPIToken, 2).then((data) => {
            this._router.navigate(['/user-management']);
        });
    }

    validateEmail(email) {
        this._heroService.validateEmail(email).then(result => {
            if(result == "TAKEN"){
                this.emailAvailable = false;
                this.emailLinkable = false;
            }else if(result == "LINKABLE"){
                this.emailAvailable = false;
                this.emailLinkable = true;
            }else if(result == "AVAILABLE"){ 
                this.emailAvailable = true;
            }else{
                this.emailAvailable = false;
                this.emailLinkable = false;
                console.log("Bad response from email validation: " + result);
            }
        });
        this.User.username = email;
    }

    validateUsername(username, UsernameValid) {
        if (UsernameValid) {
            this._heroService.isUsernameAvailable(username).then(resultUsername => {
                this.usernameAvailable = resultUsername;
            });
        }
        else {
            this.usernameAvailable = false;
        }
    }

    getMemberships(customerId: number) {
        let addOnIds = this._mb.user.AddOnsIds.join(',');

        this._mb.getMemberships(customerId).then(result => {
            this.parentMemberships = result.filter(item => this._mb.user.MembershipIds.some(f => f == item.id));
            this.addOns = result.filter(item => this._mb.user.AddOnsIds.some(f => f == item.id));
            if(this.User.MembershipId==null)
            {
                 this.User.MembershipId = this.parentMemberships[0].id;    
            }
            this.getCustomTags(this.User.MembershipId);
            this.membershipsAvailable = true;
            if(this.initialCustomerId == 0){
                let idvaluetoset = this.parentMemberships[0].id;
                let membershipdropdown = document.getElementsByName("MembershipId")[0] as HTMLSelectElement;
                let linkingdropdown = document.getElementsByName("linkingMembershipId")[0] as HTMLSelectElement;
                let thisuser = this.User;
                window.setTimeout(function(){
                  if (membershipdropdown) {
                    membershipdropdown.value = "0: " + idvaluetoset;
                  }
                  if (linkingdropdown) {
                    linkingdropdown.value = "0: " + idvaluetoset;
                  }
                    thisuser.MembershipId = idvaluetoset;
                },1000);
            }
        });
    }

    saveUser() {
        this.saveInProgress = true;
        var originalUser = this.form.value;

        if (this.customerId == 0) {
            this.User.username = this.User.Email;
            this._heroService.createChildAccount(this.User).then(result => {
                if (result) {
                    this.customerId = result.id;
                    if (this.User.AddOnId>0) {
                        this._heroService.updateMembership(this.customerId, 0, this.User.AddOnId)
                    }
                    if (this.customerId > 0) {
                        this.saveProfile();
                        this.customTags.forEach(element => element.UserId=result.id);
                        this.saveCustomTags();
                    }
                }
            });
        }
        else {
            if (originalUser.FirstName.toLowerCase() != this.User.FirstName.toLowerCase() || originalUser.LastName.toLowerCase() != this.User.LastName.toLowerCase() ||
                originalUser.Institution.toLowerCase() != this.User.Company.toLowerCase()) {
                if (this.User.ProfileAddressId>0) {
                    let profileAddress: any = {
                        "id": this.User.ProfileAddressId,
                        "first_name": this.User.FirstName,
                        "last_name": this.User.LastName,
                        "company": this.User.Company,
                    }
                    this._heroService.updateUser(this.customerId, profileAddress).then(result => {
                        this.saveProfile();
                    });
                }
            }
            if (originalUser.Email.toLowerCase() != this.User.Email.toLowerCase()) {
                this._heroService.updateCustomerEmail(this.customerId, this.User.Email).then(result => {
                    this.saveProfile();
                });
            }
            if (originalUser.UserName.toLowerCase() != this.User.username.toLowerCase()) {
                this._heroService.updateUsername(this.User.id, this.User.username).then(result => {
                    this.saveProfile();
                });
            }

            if (originalUser.MembershipId != this.User.MembershipId) { 
         
                this._heroService.updateMembership(this.customerId, originalUser.MembershipId, this.User.MembershipId).then(result => {
                    this.saveProfile();
                });
            }

            if (originalUser.AddOnId != this.User.AddOnId) {
                this._heroService.updateMembership(this.customerId, originalUser.AddOnId, this.User.AddOnId).then(result => {
                    this.saveProfile();
                });
            }

            if (originalUser.FirstName.toLowerCase() == this.User.FirstName.toLowerCase() && originalUser.LastName.toLowerCase() == this.User.LastName.toLowerCase() &&
                originalUser.Institution.toLowerCase() == this.User.Company.toLowerCase() && originalUser.Email.toLowerCase() == this.User.Email.toLowerCase() &&
                originalUser.UserName.toLowerCase() == this.User.username.toLowerCase() && originalUser.MembershipId == this.User.MembershipId &&
                originalUser.AddOnId == this.User.AddOnId ) {
                this.saveProfile();
            }
            this.saveCustomTags();
        }
    }
    linkUserByEmail(email,membershipId){
        if(!membershipId || membershipId == 0){
            //do nothing
            return;
        }
        this.saveInProgress = true;
        //console.log("email: " +email);
        //console.log("membershipid: " + membershipId);

        this._heroService.linkCustomerToMembershipByEmail(email,membershipId).then(result => {
            if(result){
                this._heroService.getCustomerCentralUser(result).then(res => {
                    if (res && res.CustomerId>0) {
                        this.ccUser = res;
                        this.customerId = this.ccUser.CustomerId;
                        this._up.saveUserProfile(this.customerId, JSON.stringify(this.userProfile), this._mb.userProfileAPIToken, 2, this._mb.user.UserName).then((data) => {
                            this.closeModal();
                            this._router.navigate(['/user-management']);
                        });
                    }
                }).catch(error => {
                    this.closeModal();
                    this._router.navigate(['/user-management']);
                });
                //this._router.navigate(['/user-management']);
            }else{
                this.saveInProgress = false;
            }
        });
    }

    openModal(template: TemplateRef<any>) {
        this.modal = this.modalService.show(template);
      }
      closeModal()  {
        this.modal.hide();
      }

      saveCustomTags()
      {
        this._heroService.saveCustomTags(this.customTags);
      }
}
