<h2>User Management</h2>

  <div *ngIf="loading" style="text-align:center;" class="imgloader">
    <h2>Loading</h2>
    <img style="height:60px;width:60px;" src="../../assets/images/loading-dots.gif" />
  </div>

<div *ngIf="hasAccess" [class.is-hidden]="loading">



  <div>
    <div>

      <div class="panel panel-default">
        <table id="parentMemberships" class="table table-bordered table-hover ">
          <thead class="thead">
            <tr>
              <th colspan="3" style="text-align:center;">Membership Summary</th>
              <th colspan="3" style="text-align:center;">Additional Users</th>
            </tr>
            <tr>
              <th>Membership Id</th>
              <th>Membership</th>
              <th>Expiration Date</th>
              <th>Purchased</th>
              <th>Used</th>
              <th>Available</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let membership of parentMemberships">
              <td>{{membership.id ? membership.id : ''}}
              <td>{{membership.plan ? membership.plan.name : ''}}</td>
              <td>{{membership.expiration_date ? membership.expiration_date : getExpirationDate(membership) | date: 'yyyy-MM-dd'  }}</td>
              <td>{{membership.child_accounts}}</td>
              <td>{{membership.usedChildren}}</td>
              <td>{{membership.remaining_child_accounts}} </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3>Manage Users</h3>
      <table datatable class="table table-bordered table-hover compact" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead class="thead">
          <tr>
            <th style="min-width: 60px;">
              <a class="text-nowrap" style="cursor:pointer">First Name <span></span> </a>
            </th>
            <th>
              <a class="text-nowrap" style="cursor:pointer">Last Name <span></span> </a>
            </th>
            <th>
              <a class="text-nowrap" style="cursor:pointer">Email <span></span> </a>
            </th>
            <th>
              <a class="text-nowrap" style="cursor:pointer">Username <span></span> </a>
            </th>
            <th>
              <a class="text-nowrap" style="cursor:pointer">Company <span></span> </a>
            </th>
            <th style="min-width:160px">
              <a class="text-nowrap" style="cursor:pointer">Membership <span></span> </a>
            </th>
            <th style="width: 70px">Manage</th>
            <th style="width: 55px">Select</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of childCustomers">
            <td style="word-wrap: break-word">{{item.profileAddress ? item.profileAddress.first_name : ''}}</td>
            <td style="word-wrap: break-word">{{item.profileAddress ? item.profileAddress.last_name: ''}}</td>
            <td style="word-wrap: break-word">{{item.email}}</td>
            <td style="word-wrap: break-word">{{item.users[0].username}}</td>
            <td style="word-wrap: break-word">{{item.profileAddress ? item.profileAddress.company: ''}}</td>
            <td style="word-wrap: break-word">
              {{item.memberships?item.memberships[0].plan.name:''}}{{item.addOns ? ',' : ''}}
              {{item.addOns ? item.addOns[0].plan.name : ''}}
            </td>
            <td><a [routerLink]="['/edit-user', item.id,item.users[0].id]">Edit</a></td>
            <td> <input type="checkbox" [(ngModel)]="item.IsSelected" (change)="setSelectedFlag($event)" /> </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btn-toolbar">
      <a class="btn btn-success" [routerLink]="['/edit-user',0,0]">ADD NEW USER</a>
      <button class="btn btn-primary" (click)="removeUsers()" [disabled]="selectedCount<=0">REMOVE USER(S) </button>
    </div>
  </div>
</div>

<div *ngIf="!hasAccess">
  <h2>You do not have access to this view.</h2>
</div>
