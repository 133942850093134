<div class="container">
   <div class="row">
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[0] == 'RUNNING', 'reindex-done' : states[0] == 'DONE', 'reindex-error' : states[0] == 'ERROR'}">
            <h2>News</h2>
            <p>Unified type {{typeIds[0]}}</p>
            <button [disabled]="states[0] == 'RUNNING'" (click)="indexType(0)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[0] != 'RUNNING'" (click)="cancelType(0)">Cancel</button>
            <p *ngIf="states[0] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[0] == 'RUNNING'">Reindexing... (Running for {{timestrings[0]}})</p>
            <p *ngIf="states[0] == 'STOPPED'">Cancelled after {{timestrings[0]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[0] == 'DONE'">Reindexed after {{timestrings[0]}}.</p>
            <p *ngIf="states[0] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[0]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[1] == 'RUNNING', 'reindex-done' : states[1] == 'DONE', 'reindex-error' : states[1] == 'ERROR'}">
            <h2>Trainer's Guides</h2>
            <p>Unified type {{typeIds[1]}}</p>
            <button [disabled]="states[1] == 'RUNNING'" (click)="indexType(1)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[1] != 'RUNNING'" (click)="cancelType(1)">Cancel</button>
            <p *ngIf="states[1] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[1] == 'RUNNING'">Reindexing... (Running for {{timestrings[1]}}.)</p>
            <p *ngIf="states[1] == 'STOPPED'">Cancelled after {{timestrings[1]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[1] == 'DONE'">Reindexed after {{timestrings[1]}}.</p>
            <p *ngIf="states[1] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[1]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[2] == 'RUNNING', 'reindex-done' : states[2] == 'DONE', 'reindex-error' : states[2] == 'ERROR'}">
            <h2>Powerpoints</h2>
            <p>Unified type {{typeIds[2]}}</p>
            <button [disabled]="states[2] == 'RUNNING'" (click)="indexType(2)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[2] != 'RUNNING'" (click)="cancelType(2)">Cancel</button>
            <p *ngIf="states[2] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[2] == 'RUNNING'">Reindexing... (Running for {{timestrings[2]}}.)</p>
            <p *ngIf="states[2] == 'STOPPED'">Cancelled after {{timestrings[2]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[2] == 'DONE'">Reindexed after {{timestrings[2]}}.</p>
            <p *ngIf="states[2] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[2]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[3] == 'RUNNING', 'reindex-done' : states[3] == 'DONE', 'reindex-error' : states[3] == 'ERROR'}">
            <h2>Toolbox Talks</h2>
            <p>Unified type {{typeIds[3]}}</p>
            <button [disabled]="states[3] == 'RUNNING'" (click)="indexType(3)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[3] != 'RUNNING'" (click)="cancelType(3)">Cancel</button>
            <p *ngIf="states[3] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[3] == 'RUNNING'">Reindexing... (Running for {{timestrings[3]}}.)</p>
            <p *ngIf="states[3] == 'STOPPED'">Cancelled after {{timestrings[3]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[3] == 'DONE'">Reindexed after {{timestrings[3]}}.</p>
            <p *ngIf="states[3] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[3]}}.</p>
        </div>
   </div>
   <div class="row">
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[4] == 'RUNNING', 'reindex-done' : states[4] == 'DONE', 'reindex-error' : states[4] == 'ERROR'}">
            <h2>Training Meetings</h2>
            <p>Unified type {{typeIds[4]}}</p>
            <button [disabled]="states[4] == 'RUNNING'" (click)="indexType(4)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[4] != 'RUNNING'" (click)="cancelType(4)">Cancel</button>
            <p *ngIf="states[4] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[4] == 'RUNNING'">Reindexing... (Running for {{timestrings[4]}}.)</p>
            <p *ngIf="states[4] == 'STOPPED'">Cancelled after {{timestrings[4]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[4] == 'DONE'">Reindexed after {{timestrings[4]}}.</p>
            <p *ngIf="states[4] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[4]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[5] == 'RUNNING', 'reindex-done' : states[5] == 'DONE', 'reindex-error' : states[5] == 'ERROR'}">
            <h2>Handouts</h2>
            <p>Unified type {{typeIds[5]}}</p>
            <button [disabled]="states[5] == 'RUNNING'" (click)="indexType(5)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[5] != 'RUNNING'" (click)="cancelType(5)">Cancel</button>
            <p *ngIf="states[5] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[5] == 'RUNNING'">Reindexing... (Running for {{timestrings[5]}}.)</p>
            <p *ngIf="states[5] == 'STOPPED'">Cancelled after {{timestrings[5]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[5] == 'DONE'">Reindexed after {{timestrings[5]}}.</p>
            <p *ngIf="states[5] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[5]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[6] == 'RUNNING', 'reindex-done' : states[6] == 'DONE', 'reindex-error' : states[6] == 'ERROR'}">
            <h2>Quizzes</h2>
            <p>Unified type {{typeIds[6]}}</p>
            <button [disabled]="states[6] == 'RUNNING'" (click)="indexType(6)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[6] != 'RUNNING'" (click)="cancelType(6)">Cancel</button>
            <p *ngIf="states[6] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[6] == 'RUNNING'">Reindexing... (Running for {{timestrings[6]}}.)</p>
            <p *ngIf="states[6] == 'STOPPED'">Cancelled after {{timestrings[6]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[6] == 'DONE'">Reindexed after {{timestrings[6]}}.</p>
            <p *ngIf="states[6] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[6]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[7] == 'RUNNING', 'reindex-done' : states[7] == 'DONE', 'reindex-error' : states[7] == 'ERROR'}">
            <h2>Speaker's Notes</h2>
            <p>Unified type {{typeIds[7]}}</p>
            <button [disabled]="states[7] == 'RUNNING'" (click)="indexType(7)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[7] != 'RUNNING'" (click)="cancelType(7)">Cancel</button>
            <p *ngIf="states[7] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[7] == 'RUNNING'">Reindexing... (Running for {{timestrings[7]}}.)</p>
            <p *ngIf="states[7] == 'STOPPED'">Cancelled after {{timestrings[7]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[7] == 'DONE'">Reindexed after {{timestrings[7]}}.</p>
            <p *ngIf="states[7] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[7]}}.</p>
        </div>
   </div>
   <div class="row">
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[8] == 'RUNNING', 'reindex-done' : states[8] == 'DONE', 'reindex-error' : states[8] == 'ERROR'}">
            <h2>Training Exercises</h2>
            <p>Unified type {{typeIds[8]}}</p>
            <button [disabled]="states[8] == 'RUNNING'" (click)="indexType(8)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[8] != 'RUNNING'" (click)="cancelType(8)">Cancel</button>
            <p *ngIf="states[8] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[8] == 'RUNNING'">Reindexing... (Running for {{timestrings[8]}}.)</p>
            <p *ngIf="states[8] == 'STOPPED'">Cancelled after {{timestrings[8]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[8] == 'DONE'">Reindexed after {{timestrings[8]}}.</p>
            <p *ngIf="states[8] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[8]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[9] == 'RUNNING', 'reindex-done' : states[9] == 'DONE', 'reindex-error' : states[9] == 'ERROR'}">
            <h2>Training Resources</h2>
            <p>Unified type {{typeIds[9]}}</p>
            <button [disabled]="states[9] == 'RUNNING'" (click)="indexType(9)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[9] != 'RUNNING'" (click)="cancelType(9)">Cancel</button>
            <p *ngIf="states[9] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[9] == 'RUNNING'">Reindexing... (Running for {{timestrings[9]}}.)</p>
            <p *ngIf="states[9] == 'STOPPED'">Cancelled after {{timestrings[9]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[9] == 'DONE'">Reindexed after {{timestrings[9]}}.</p>
            <p *ngIf="states[9] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[9]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[10] == 'RUNNING', 'reindex-done' : states[10] == 'DONE', 'reindex-error' : states[10] == 'ERROR'}">
            <h2>Forms</h2>
            <p>Unified type {{typeIds[10]}}</p>
            <button [disabled]="states[10] == 'RUNNING'" (click)="indexType(10)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[10] != 'RUNNING'" (click)="cancelType(10)">Cancel</button>
            <p *ngIf="states[10] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[10] == 'RUNNING'">Reindexing... (Running for {{timestrings[10]}}.)</p>
            <p *ngIf="states[10] == 'STOPPED'">Cancelled after {{timestrings[10]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[10] == 'DONE'">Reindexed after {{timestrings[10]}}.</p>
            <p *ngIf="states[10] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[10]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[11] == 'RUNNING', 'reindex-done' : states[11] == 'DONE', 'reindex-error' : states[11] == 'ERROR'}">
            <h2>Certificates</h2>
            <p>Unified type {{typeIds[11]}}</p>
            <button [disabled]="states[11] == 'RUNNING'" (click)="indexType(11)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[11] != 'RUNNING'" (click)="cancelType(11)">Cancel</button>
            <p *ngIf="states[11] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[11] == 'RUNNING'">Reindexing... (Running for {{timestrings[11]}}.)</p>
            <p *ngIf="states[11] == 'STOPPED'">Cancelled after {{timestrings[11]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[11] == 'DONE'">Reindexed after {{timestrings[11]}}.</p>
            <p *ngIf="states[11] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[11]}}.</p>
        </div>
   </div>
   <div class="row">
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[12] == 'RUNNING', 'reindex-done' : states[12] == 'DONE', 'reindex-error' : states[12] == 'ERROR'}">
            <h2>Checklists</h2>
            <p>Unified type {{typeIds[12]}}</p>
            <button [disabled]="states[12] == 'RUNNING'" (click)="indexType(12)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[12] != 'RUNNING'" (click)="cancelType(12)">Cancel</button>
            <p *ngIf="states[12] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[12] == 'RUNNING'">Reindexing... (Running for {{timestrings[12]}}.)</p>
            <p *ngIf="states[12] == 'STOPPED'">Cancelled after {{timestrings[12]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[12] == 'DONE'">Reindexed after {{timestrings[12]}}.</p>
            <p *ngIf="states[12] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[12]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[13] == 'RUNNING', 'reindex-done' : states[13] == 'DONE', 'reindex-error' : states[13] == 'ERROR'}">
            <h2>Calculators</h2>
            <p>Unified type {{typeIds[13]}}</p>
            <button [disabled]="states[13] == 'RUNNING'" (click)="indexType(13)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[13] != 'RUNNING'" (click)="cancelType(13)">Cancel</button>
            <p *ngIf="states[13] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[13] == 'RUNNING'">Reindexing... (Running for {{timestrings[13]}}.)</p>
            <p *ngIf="states[13] == 'STOPPED'">Cancelled after {{timestrings[13]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[13] == 'DONE'">Reindexed after {{timestrings[13]}}.</p>
            <p *ngIf="states[13] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[13]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[14] == 'RUNNING', 'reindex-done' : states[14] == 'DONE', 'reindex-error' : states[14] == 'ERROR'}">
            <h2>Analysis</h2>
            <p>Unified type {{typeIds[14]}}</p>
            <button [disabled]="states[14] == 'RUNNING'" (click)="indexType(14)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[14] != 'RUNNING'" (click)="cancelType(14)">Cancel</button>
            <p *ngIf="states[14] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[14] == 'RUNNING'">Reindexing... (Running for {{timestrings[14]}}.)</p>
            <p *ngIf="states[14] == 'STOPPED'">Cancelled after {{timestrings[14]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[14] == 'DONE'">Reindexed after {{timestrings[14]}}.</p>
            <p *ngIf="states[14] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[14]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[15] == 'RUNNING', 'reindex-done' : states[15] == 'DONE', 'reindex-error' : states[15] == 'ERROR'}">
            <h2>Policies</h2>
            <p>Unified type {{typeIds[15]}}</p>
            <button [disabled]="states[15] == 'RUNNING'" (click)="indexType(15)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[15] != 'RUNNING'" (click)="cancelType(15)">Cancel</button>
            <p *ngIf="states[15] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[15] == 'RUNNING'">Reindexing... (Running for {{timestrings[15]}}.)</p>
            <p *ngIf="states[15] == 'STOPPED'">Cancelled after {{timestrings[15]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[15] == 'DONE'">Reindexed after {{timestrings[15]}}.</p>
            <p *ngIf="states[15] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[15]}}.</p>
        </div>
   </div>
   <div class="row">
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[16] == 'RUNNING', 'reindex-done' : states[16] == 'DONE', 'reindex-error' : states[16] == 'ERROR'}">
            <h2>Podcasts</h2>
            <p>Unified type {{typeIds[16]}}</p>
            <button [disabled]="states[16] == 'RUNNING'" (click)="indexType(16)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[16] != 'RUNNING'" (click)="cancelType(16)">Cancel</button>
            <p *ngIf="states[16] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[16] == 'RUNNING'">Reindexing... (Running for {{timestrings[16]}}.)</p>
            <p *ngIf="states[16] == 'STOPPED'">Cancelled after {{timestrings[16]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[16] == 'DONE'">Reindexed after {{timestrings[16]}}.</p>
            <p *ngIf="states[16] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[16]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[17] == 'RUNNING', 'reindex-done' : states[17] == 'DONE', 'reindex-error' : states[17] == 'ERROR'}">
            <h2>Guidance Documents</h2>
            <p>Unified type {{typeIds[17]}}</p>
            <button [disabled]="states[17] == 'RUNNING'" (click)="indexType(17)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[17] != 'RUNNING'" (click)="cancelType(17)">Cancel</button>
            <p *ngIf="states[17] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[17] == 'RUNNING'">Reindexing... (Running for {{timestrings[17]}}.)</p>
            <p *ngIf="states[17] == 'STOPPED'">Cancelled after {{timestrings[17]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[17] == 'DONE'">Reindexed after {{timestrings[17]}}.</p>
            <p *ngIf="states[17] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[17]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[18] == 'RUNNING', 'reindex-done' : states[18] == 'DONE', 'reindex-error' : states[18] == 'ERROR'}">
            <h2>Letters</h2>
            <p>Unified type {{typeIds[18]}}</p>
            <button [disabled]="states[18] == 'RUNNING'" (click)="indexType(18)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[18] != 'RUNNING'" (click)="cancelType(18)">Cancel</button>
            <p *ngIf="states[18] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[18] == 'RUNNING'">Reindexing... (Running for {{timestrings[18]}}.)</p>
            <p *ngIf="states[18] == 'STOPPED'">Cancelled after {{timestrings[18]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[18] == 'DONE'">Reindexed after {{timestrings[18]}}.</p>
            <p *ngIf="states[18] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[18]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[19] == 'RUNNING', 'reindex-done' : states[19] == 'DONE', 'reindex-error' : states[19] == 'ERROR'}">
            <h2>Questions & Answers</h2>
            <p>Unified type {{typeIds[19]}}</p>
            <button [disabled]="states[19] == 'RUNNING'" (click)="indexType(19)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[19] != 'RUNNING'" (click)="cancelType(19)">Cancel</button>
            <p *ngIf="states[19] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[19] == 'RUNNING'">Reindexing... (Running for {{timestrings[19]}}.)</p>
            <p *ngIf="states[19] == 'STOPPED'">Cancelled after {{timestrings[19]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[19] == 'DONE'">Reindexed after {{timestrings[19]}}.</p>
            <p *ngIf="states[19] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[19]}}.</p>
        </div>
   </div>
   <div class="row">
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[20] == 'RUNNING', 'reindex-done' : states[20] == 'DONE', 'reindex-error' : states[20] == 'ERROR'}">
            <h2>Audiovisual Presentations</h2>
            <p>Unified type {{typeIds[20]}}</p>
            <button [disabled]="states[20] == 'RUNNING'" (click)="indexType(20)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[20] != 'RUNNING'" (click)="cancelType(20)">Cancel</button>
            <p *ngIf="states[20] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[20] == 'RUNNING'">Reindexing... (Running for {{timestrings[20]}}.)</p>
            <p *ngIf="states[20] == 'STOPPED'">Cancelled after {{timestrings[20]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[20] == 'DONE'">Reindexed after {{timestrings[20]}}.</p>
            <p *ngIf="states[20] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[20]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[21] == 'RUNNING', 'reindex-done' : states[21] == 'DONE', 'reindex-error' : states[21] == 'ERROR'}">
            <h2>Notices</h2>
            <p>Unified type {{typeIds[21]}}</p>
            <button [disabled]="states[21] == 'RUNNING'" (click)="indexType(21)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[21] != 'RUNNING'" (click)="cancelType(21)">Cancel</button>
            <p *ngIf="states[21] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[21] == 'RUNNING'">Reindexing... (Running for {{timestrings[21]}}.)</p>
            <p *ngIf="states[21] == 'STOPPED'">Cancelled after {{timestrings[21]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[21] == 'DONE'">Reindexed after {{timestrings[21]}}.</p>
            <p *ngIf="states[21] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[21]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[22] == 'RUNNING', 'reindex-done' : states[22] == 'DONE', 'reindex-error' : states[22] == 'ERROR'}">
            <h2>Professional Development</h2>
            <p>Unified type {{typeIds[22]}}</p>
            <button [disabled]="states[22] == 'RUNNING'" (click)="indexType(22)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[22] != 'RUNNING'" (click)="cancelType(22)">Cancel</button>
            <p *ngIf="states[22] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[22] == 'RUNNING'">Reindexing... (Running for {{timestrings[22]}}.)</p>
            <p *ngIf="states[22] == 'STOPPED'">Cancelled after {{timestrings[22]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[22] == 'DONE'">Reindexed after {{timestrings[22]}}.</p>
            <p *ngIf="states[22] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[22]}}.</p>
        </div>
        <div class="col-sm-2 reindex-cell" [ngClass]="{ 'reindex-running' : states[20] == 'RUNNING', 'reindex-done' : states[20] == 'DONE', 'reindex-error' : states[20] == 'ERROR'}">
            <h2>New Hire Packets</h2>
            <p>Unified type {{typeIds[23]}}</p>
            <button [disabled]="states[23] == 'RUNNING'" (click)="indexType(23)">Reindex</button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button hidden [disabled]="states[23] != 'RUNNING'" (click)="cancelType(23)">Cancel</button>
            <p *ngIf="states[23] == 'STANDBY'">Standing by...</p>
            <p *ngIf="states[23] == 'RUNNING'">Reindexing... (Running for {{timestrings[23]}}.)</p>
            <p *ngIf="states[23] == 'STOPPED'">Cancelled after {{timestrings[23]}}. (The process will run to completion on the back end.)</p>
            <p *ngIf="states[23] == 'DONE'">Reindexed after {{timestrings[23]}}.</p>
            <p *ngIf="states[23] == 'ERROR'">Reindex unexpectedly failed after {{timestrings[23]}}.</p>
        </div>
   </div>
</div>