import { Component, OnInit, OnDestroy } from '@angular/core';
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { UserProfile } from '../../shared/types/user-profile.type'
import { UserProfileService } from '../../shared/services/user-profile.service';
import { HrHeroService } from '../../shared/services/hr-hero.service';
import { Subject} from 'rxjs';

import { CustomWidget } from '../../shared/types/custom-widget.type'

import  'jquery';


@Component({
  selector: 'preferences',
  templateUrl: './preferences.component.html',
  styles: ['.thead { background-color: #1a8cd8; color: white;}']
})

export class PreferencesComponent implements OnDestroy, OnInit{

  userProfile: UserProfile = null;
  filteredTools: any = null;
  isChild: boolean = false;
  useParent: boolean = false;
  customWidgets: CustomWidget[] = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _mbService: MembershipDataService,
    private _userProfileService: UserProfileService,
    private _heroService: HrHeroService
  ) {

      this.userProfile = this._mbService.userProfile;
    
  }

  ngOnInit() {

    $(".alert").hide();
    
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      searching: false,
      ordering: false,
      info: false,
      language : {
        lengthMenu: '_MENU_',
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
      dom:  '<<t><"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
    };
    let parentUserProfile = this._mbService.parentUserProfile;
    if(parentUserProfile != null && parentUserProfile != undefined && parentUserProfile.Tools != null && parentUserProfile.Tools != undefined){
      this.isChild = true;
      if(parentUserProfile.DashboardLock){
        this.useParent = true;   
      }
    }

    this._heroService.getAllCustomWidgets().then(result => {
      if (result.length > 0) {
        this.customWidgets = result;
      } else {
        this.customWidgets = null;
      }

      if(this.userProfile != null && this.userProfile.Tools != null){
        this.filterTools();
      }
      this.dtTrigger.next();

    });
    
  }
  filterTools(){
    let self = this;

    this.filteredTools = this.userProfile.Tools.filter(item => {
      let toolCheck = self.toolCheckable(item.Id,"tools");
      let dashCheck = self.toolCheckable(item.Id,"dashboard")
      let widgetCheck = self.widgetExistsInDB(item.Id,item.Type);
      return self.isVisible(item) && (dashCheck || toolCheck) && widgetCheck;
    });
    this.filteredTools.sort(
      (t1,t2) => 
        t1.Type < t2.Type ? 
          1 : 
          (t2.Type < t1.Type ? 
            -1 :
            (t1.Component.toLowerCase() > t2.Component.toLowerCase() ? 1 : (t2.Component.toLowerCase() > t1.Component.toLowerCase() ? -1 : 0)))
    );
  }

  widgetVisible(id:number, type:string, page:string) {

    if (type == "Tool" || this.customWidgets == null) {
      return true;
    }

    if (this.customWidgets.filter(f => f.CustomWidgetId == id).length > 0) {
      if (page == 'tools') {
        return this.customWidgets.filter(f => f.CustomWidgetId == id)[0].VisibleOnTools;
      } else if (page == 'dashboard') {
        return this.customWidgets.filter(f => f.CustomWidgetId == id)[0].VisibleOnDashboard;
      }
    }

  }
  widgetExistsInDB(id:number,type:string){
    if (type == "Tool") {
      return true;
    }
    if(this.customWidgets == null){
      return false;
    }
    if (this.customWidgets.filter(f => f.CustomWidgetId == id).length > 0) {
      return true;
    }
    return false;
  }
  toolCheckable(id:number, page:string){
    let widgetItselfIsVisible = true;
    
    if(this.customWidgets != null){ //once the widgets load in...
      if (this.customWidgets.filter(f => f.CustomWidgetId == id).length > 0) {
        let cwidget = this.customWidgets.filter(f => f.CustomWidgetId == id)[0];
        widgetItselfIsVisible = page == 'tools' ? cwidget.VisibleOnTools : cwidget.VisibleOnDashboard;
      }else{
        widgetItselfIsVisible = true; //this is a tool
      }
    }
    if(this._mbService.userProfileTypeId == 1){
      return widgetItselfIsVisible;
    }

    let toolsMatchingId = this._mbService.parentUserProfile.Tools.filter(f => f.Id == id);
    if (toolsMatchingId.length > 0) {
      let tool = toolsMatchingId[0];
      if (page == 'tools') {
        return tool.VisibleOnTools && widgetItselfIsVisible;
      }
      if (page == 'dashboard') {
        return tool.VisibleOnHome && widgetItselfIsVisible;
      }
    }else{
      return true; //you're a child account and this is one of your own widgets
    }
  }

  check(checked:boolean, id:number, type:string) {

    if (this.userProfile.Tools.filter(f => f.Id == id).length > 0) {
      if (type == 'tools') {
        this.userProfile.Tools.filter(f => f.Id == id)[0].VisibleOnTools = checked;
      }
      if (type == 'dashboard') {
        this.userProfile.Tools.filter(f => f.Id == id)[0].VisibleOnHome = checked;
      }

      this._userProfileService.saveUserProfile(this._mbService.user.CustomerId, JSON.stringify(this.userProfile), this._mbService.userProfileAPIToken, this._mbService.userProfileTypeId);

      $("#toolAlert").show();

      setTimeout(function () { $("#toolAlert").hide(500); }, 1000);
    }
  }
  saveDashboardLock(checked:boolean){
    this.userProfile.DashboardLock = checked;
    this._userProfileService.saveUserProfile(this._mbService.user.CustomerId, JSON.stringify(this.userProfile), this._mbService.userProfileAPIToken, this._mbService.userProfileTypeId);
    $("#lockAlert").show();
    setTimeout(function () { $("#lockAlert").hide(500); }, 1000);
  }

  onStateSelectionComplete() {
    $("#stateAlert").show();

    setTimeout(function () { $("#stateAlert").hide(500); }, 1000);
  }

  isVisible(item) {
    let name = "";
    var visible = true;
    
    //if (this._mbService.parentUserId != null && item.Type == 'Custom-Widget' && this.userProfile.AdminPreferences.filter(f => f.Name == "Custom Widgets").length > 0 && //this.userProfile.AdminPreferences.filter(f => f.Name == "Custom Widgets")[0].Visible == false) {
    //  return false;
    //} //we don't care about that admin preference for this page, that should only apply to the Custom Settings page
    
    if(item.Type != 'Custom-Widget'){
      switch (item.Component) {
        case "Employee Handbook Builder":
            name = "Show Employee Handbook Builder";
            visible = this._mbService.hasEHB && (this._mbService.getSettingValue('EHB') == "true") ? true : false;
          break;
        case "State Law Chart Builder":
            name = "Show State Law Chart Builder";
            visible = this._mbService.hasSLCB && (this._mbService.getSettingValue('SLCB') == "true") ? true : false;
          break;
        case "Job Classification & FLSA Audit":
            name = "Show Job Classification & FLSA Audit";
            visible = this._mbService.hasFLSA && (this._mbService.getSettingValue('FLSA') == "true") ? true : false;
          break;
        case "Job Description Manager":
            name = "Show Job Description Manager";
            visible = this._mbService.hasJDM && (this._mbService.getSettingValue('JDM') == "true") ? true : false;
          break;
        case "Salary Finder":
            name = "Show Salary Finder";
            visible = this._mbService.hasSF && (this._mbService.getSettingValue('SF') == "true") ? true : false;
          break;
        case "Help widget":
            name = "Show Help widget";
            visible = this._mbService.hasHelp && (this._mbService.getSettingValue('HELP') == "true") ? true : false;
            break;
        case "Coronavirus Resources":
            name = "Show Coronavirus Resources";
            visible = this._mbService.hasCovid && (this._mbService.getSettingValue('COVID') == "true") ? true : false;
            break;
        case "Resource Center":
          name = "Show Resource Center";
          visible = this._mbService.getSettingValue('RESOURCECENTER') == "true" ? true : false;
          break;     
        case "Topic Analysis":
            name = "Show Topic Analysis";
            visible = this._mbService.getSettingValue('TOPICANALYSIS') == "true" ? true : false;
         break;                  
        case "Employers Guide to COVID-19":
            name = "Show Employer's Guide to COVID-19";
            visible = this._mbService.hasCovidGuide && (this._mbService.getSettingValue('COVIDGUIDE') == "true") ? true : false;
            break;
      }
    }

    if (visible && this.userProfile.AdminPreferences.filter(f => f.Name == name).length > 0) {
      visible = this.userProfile.AdminPreferences.filter(f => f.Name == name)[0].Visible;
      return this.userProfile.AdminPreferences.filter(f => f.Name == name)[0].Visible;
    }
    return visible;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
