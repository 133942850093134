<h1>Trainer's Toolbox</h1>
<div class="body-content seperator body-content-margin-override">Download these helpful documents to use them with all of HR Hero training presentations</div>

<div *ngIf="information != null" class="panel panel-default">
    <table class="table table-striped">
      <thead class="thead">
        <tr>
          <th>
            Title
          </th>
          <th>
            Language
          </th>
          <th>
            Document Type
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of information">
          <td><a style="cursor:pointer;" (click)="selectInformation(item)" class="link" [innerHtml]="item.Title"></a></td>
          <td>{{item.Language}}</td>
          <td *ngIf="!isLimitedAccess && item.DocumentType == 'pdf'"><a target="_blank" role="=button" class="link" (click)="download(item.InformationId,item.DocumentType,item.Title)"><img src="../../assets/images/white-pdf.PNG" /> PDF</a></td>
          <td *ngIf="!isLimitedAccess && item.DocumentType == 'doc'"><a target="_blank" role="button" class="link" (click)="download(item.InformationId,item.DocumentType,item.Title)"><img src="../../assets/images/white-doc.PNG" /> Word</a></td>

          <td *ngIf="isLimitedAccess && item.DocumentType == 'pdf'"><a class="link" (click)="openModal(limitedModal)"><img src="../../assets/images/white-pdf.PNG" /> PDF</a></td>
          <td *ngIf="isLimitedAccess && item.DocumentType == 'doc'"><a class="link" (click)="openModal(limitedModal)"><img src="../../assets/images/white-doc.PNG" /> Word</a></td>
        </tr>
      </tbody>
    </table>

  <!--Limited Access modal-->
  <ng-template #limitedModal>
    <div class="modal-header modal-header-hr"> Limited Access </div>
    <div class="modal-body">
      <h1> Downloads are reserved for HR Hero subscribers. </h1>

      <div class="body-content">
        <a href="http://store.blr.com/hrhero" target="_blank">Upgrade now</a> or <a href="http://interactive.blr.com/hrhero-demo-request" target="_blank">request a demo</a> for immediate access to a hundreds of downloadable forms, checklists, and instructor led training materials.
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="closeModal()">Close</button>
    </div>
  </ng-template>
</div>
