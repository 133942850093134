import { Component, TemplateRef, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

import { HrHeroService } from '../shared/services/hr-hero.service'
import { MembershipDataService } from '../shared/services/membership.data.service';
import { Information } from '../shared/types/information.type'
import { SearchModel } from '../shared/types/search.type'
import { SearchTerms } from '../shared/types/search-terms'
import { DateRange } from '../shared/types/date-range.type'
import { WindowService } from '../shared/services/window.service'
import { hrHeroConfig } from '../../environments/environment'

@Component({
  selector: 'news',
  templateUrl: './search.html',
  styleUrls: ['./search.css']
})

export class Search {

  searchItems: SearchModel;
  informationMaster: Information[];
  searchTermValue: string = null;
  tags: SearchTerms[];
  loading: boolean = false;
  selectedDateRange: string = "";
  hasBenefits: boolean = false;
  isLimitedAccess: boolean = false;
  allKeywords: boolean = true;
  spellingCorrection: string = "";

  stateCollapsed: boolean = false;
  languageCollapsed: boolean = false;
  categoryCollapsed: boolean = false;
  topicCollapsed: boolean = false;
  docCollapsed: boolean = false;
  modal: BsModalRef;

  @ViewChild('filters') elementView: ElementRef;
  filtersHeight: number;

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private _router: Router,
    private _heroService: HrHeroService,
    private _mbService: MembershipDataService,
    private _window: WindowService, 
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['searchTerm'] != null) {
        this.searchTermValue = params['searchTerm']

        this.search(params['searchTerm'], true);

        this.tags = new Array<SearchTerms>();

        let tags = new Array<string>();

        tags = params['searchTerm'].split(',');

        if (tags.length == 1) {
          let term: SearchTerms = new SearchTerms;

          term.value = tags[0];
          term.display = tags[0];

          this.tags.push(term);
        }
        else {
          for (var i = 0; i < tags.length; i++) {

            let term: SearchTerms = new SearchTerms;

            term.value = tags[i];
            term.display = tags[i];

            this.tags.push(term);
          }
        }
        this.hasBenefits = this._mbService.hasBenefits;
        this.isLimitedAccess = this._mbService.isLimitedAccess();

        //this.search(params['searchTerm'], false);
      }
    });
  }

  async search(searchTerm, pageLoad = false) {

    this.loading = true;
    this.expandAllFilters();

    let searchItems = await this._heroService.getInformationBySearchTerm(searchTerm, this.allKeywords);

      if (searchItems != null && searchItems.Information.length > 0) {
        this.searchItems = searchItems;

        this.informationMaster = searchItems.Information;
        searchItems.Languages.sort((a,b)=> {
          return a.Language.toLowerCase() < b.Language.toLowerCase() ? -1 : 1;
        })
        searchItems.InformationTypes.sort((a,b)=> {
          return a.InformationTypeName.toLowerCase() < b.InformationTypeName.toLowerCase() ? -1 : 1;
        })
        this.selectedDateRange = 'All';
      } else {
        this.searchItems = null;
        this.spellingCorrection = await this._heroService.getSpellcheck(searchTerm);
      }
      //if (window.navigator.userAgent.toLowerCase().indexOf('firefox') == -1){
        window["UserLeap"]("track","Search Survey Trigger");
      //}
      this.loading = false;


    if (!pageLoad) {
      if (searchTerm == 'null') {
        searchTerm = '';
        this._router.navigateByUrl("/search");
      } else {
        this._router.navigateByUrl("/search/" +  encodeURIComponent(searchTerm));
      }
    }
  }

  clearAll() {
    this.searchItems.Languages.forEach(item => {
      item.Checked = false;
    });
    this.searchItems.Jurisdictions.forEach(item => {
      item.Checked = false;
    });
    this.searchItems.Categories.forEach(item => {
      item.Checked = false;
    });
    this.searchItems.Topics.forEach(item => {
      item.Checked = false;
    });
    this.searchItems.InformationTypes.forEach(item => {
      item.Checked = false;
    });
    this.searchItems.Information = this.informationMaster;
    this.filterSelections(1);
  }

  filterDate(dr: DateRange) {
    this.selectedDateRange = dr.DateRangeDescription

    this.filter(1);

    this.filterSelections(1);
  }

  filterLanguage(language, checked) {

    this.searchItems.Languages.filter(f => f.Language == language || language == 'all').forEach((item) => {
      item.Checked = checked;
    });

    this.filter(2);
    this.filterSelections(2);
  }

  filterJurisdiction(jurisdictionId, checked) {

    this.searchItems.Jurisdictions.filter(f => f.JurisdictionId == jurisdictionId || jurisdictionId == 'all').forEach((item) => {
      item.Checked = checked;
    });

    this.filter(3);
    this.filterSelections(3);
  }

  filterCategory(categoryId, checked) {

    this.searchItems.Categories.filter(f => f.CategoryId == categoryId || categoryId == 'all').forEach((item) => {
      item.Checked = checked;
    });

    this.filter(4);
    this.filterSelections(4);
  }

  filterTopic(topicId, checked) {

    this.searchItems.Topics.filter(f => f.TopicId == topicId || topicId == 'all').forEach((item) => {
      item.Checked = checked;
    });

    this.filter(5);
    this.filterSelections(5);
  }

  filterDocument(informationTypeId, checked) {

    this.searchItems.InformationTypes.filter(f => f.InformationTypeId == informationTypeId || informationTypeId == 'all').forEach((item) => {
      item.Checked = checked;
    });

    this.filter(6);
    this.filterSelections(6);
  }

  filter(selector) {
    this.searchItems.Information = this.getFilterResult("");
  }

  getFilterResult(section) {
    return this.informationMaster.filter(item => (
      (this.searchItems.Languages.every(f => (!f.Checked)) || this.searchItems.Languages.some(f => (f.Language == item.Language && f.Checked)) || section == 'languages') &&
      (this.searchItems.Jurisdictions.every(f => (!f.Checked)) || this.searchItems.Jurisdictions.some(f => (f.JurisdictionId == item.JurisdictionId && f.Checked)) || section == 'jurisdictions') &&
      (this.searchItems.Categories.every(f => (!f.Checked)) || this.searchItems.Categories.some(f => (f.CategoryId == item.CategoryId && f.Checked)) || section == 'categories') &&
      (this.searchItems.Topics.every(f => (!f.Checked)) || this.searchItems.Topics.some(f => (f.TopicId == item.TopicId && f.Checked)) || section == 'topics') &&
      (this.searchItems.InformationTypes.every(f => (!f.Checked)) || this.searchItems.InformationTypes.some(f => (f.InformationTypeId == item.InformationTypeId && f.Checked)) || section == 'types'))
    );
  }

  filterSelections(selector) {
    if (selector != 2) {
      this.searchItems.Languages.forEach(obj => {
        this.searchItems.Languages.filter(f => f.Language == obj.Language)[0].Count = this.getFilterResult('languages').filter(f => f.Language == obj.Language).length;
      });
    }
    if (selector != 3) {
      this.searchItems.Jurisdictions.forEach(obj => {
        this.searchItems.Jurisdictions.filter(f => f.JurisdictionId == obj.JurisdictionId)[0].Count = this.getFilterResult('jurisdictions').filter(f => f.JurisdictionId == obj.JurisdictionId).length;
      });
    }
    if (selector != 4) {
      this.searchItems.Categories.forEach(obj => {
        this.searchItems.Categories.filter(f => f.CategoryId == obj.CategoryId)[0].Count = this.getFilterResult('categories').filter(f => f.CategoryId == obj.CategoryId).length;
      });
    }
    if (selector != 5) {
      this.searchItems.Topics.forEach(obj => {
        this.searchItems.Topics.filter(f => f.TopicId == obj.TopicId)[0].Count = this.getFilterResult('topics').filter(f => f.TopicId == obj.TopicId).length;
      });
    }
    if (selector != 6) {
      this.searchItems.InformationTypes.forEach(obj => {
        this.searchItems.InformationTypes.filter(f => f.InformationTypeId == obj.InformationTypeId)[0].Count = this.getFilterResult('types').filter(f => f.InformationTypeId == obj.InformationTypeId).length;
      });
    }
    setTimeout(() => { this.filtersHeight = this.elementView.nativeElement.offsetHeight; }, 0);
  }

  itemUpdated() {

    let searchText = '';

    searchText = this.tags.map(function (e) {
      return e.value;
    }).join(', ');

    if (searchText == '') searchText = 'null';

    this.search(searchText, false);

  }

  select(selectedItem: Information, includeState) {

    if (includeState) {
      //this._router.navigate([selectedItem.Url + "/" + selectedItem.JurisdictionName]);
      let extraSlash = selectedItem.InformationTypeId != 16; //analysis records have no slash on the end of the url for some reason
      this._window.nativeWindow.open("/hr" + selectedItem.Url + (extraSlash ? "/" : "") + selectedItem.JurisdictionName);
    }
    else {
      this._heroService.getInformationById(selectedItem.InformationId).then(information => {
        if (information != null) {
          //this._router.navigate([selectedItem.Url]);         
          if(hrHeroConfig.trainingTypes.includes(information.InformationTypeId)){
            this._window.nativeWindow.open("/hr" + selectedItem.Url + "/" + information.InformationId);
          }else if(information.InformationTypeId >= 27 && information.InformationTypeId <= 30){
            this._window.nativeWindow.open("/hr/" + selectedItem.Url);
          }else{
            this._window.nativeWindow.open("/hr" + selectedItem.Url);
          }

        } else {
          this._router.navigate(['/error/404']);
        }
      });
    }
  }

  expandAllFilters() {
      this.stateCollapsed = false;
      this.languageCollapsed = false;
      this.categoryCollapsed = false;
      this.topicCollapsed = false;
      this.docCollapsed = false;
  }

  openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.show(template);
  }
  closeModal()  {
    this.modal.hide();
  }

}
