import { Component, Provider, ElementRef, OnInit, Input, Compiler, Output, EventEmitter } from '@angular/core';
import { HrHeroService } from '../../../shared/services/hr-hero.service'

import { Jurisdition } from '../../types/jurisdiction.type'

import { UserProfileService } from '../../services/user-profile.service';
import { MembershipDataService } from '../../services/membership.data.service'

@Component({
  selector: "state-selector",
  styleUrls: ['./state.component.css'],
  templateUrl: './state.component.html'
})

export class StateSelectorComponent {

  @Input() currentStateId: number = -1;
  @Input() saveDefaultState: boolean = false;
  @Input() topicAnalysis: boolean = false;

  @Output() onSelectionComplete = new EventEmitter<any[]>();

  options: any;
  defaultText: string = "All";
  states: Jurisdition[];

  constructor(
    private _mbService: MembershipDataService,
    private _heroService: HrHeroService,
    private _userProfileService: UserProfileService) { }
    //private _notificationsService: NotificationsService)

  ngOnInit() {
    this._heroService.getStates().then(states => {

      this.states = states;
      if(this.topicAnalysis){ //national shouldn't show up in the state selector on the topic analysis page
        this.states = this.states.filter(item => item.JurisdictionId != 52);
        this.defaultText = "--Select a state--";
      }
      this.currentStateId = this.currentStateId > -1 ? this.currentStateId : this._mbService.userProfile.CurrentStateId;

      //console.log(this.currentStateId)
      //console.log(this.states.filter(f => f.JurisdictionId == this.currentStateId).length)
      //console.log(this.states)
      if (this.states.filter(f => f.JurisdictionId == this.currentStateId).length == 0) {
        this.currentStateId = 0;
      }

    });
  }

  onStateChange(stateId) {
    if (stateId == null) {
      return;//not sure how it's even happening, but don't save it if it does happen.
    }
    this._mbService.selectedState = stateId;
    //get the name and store it for bookmark purposes
    let matchingStates = this.states.filter( item => item.JurisdictionId == stateId);
    if(matchingStates.length > 0){
      this._mbService.selectedStateName = matchingStates[0].JurisdictionName;
    }else{
      this._mbService.selectedStateName = "";
    }

    if (this.saveDefaultState) {
      this._mbService.userProfile.CurrentStateId = stateId;
      this._mbService.setUserProfile(this._mbService.userProfile, this._mbService.parentUserProfile, this._mbService.parentUserId); //not sure what we're doing here but i'll leave it alone i guess

      this._mbService.userProfile.CurrentStateId = stateId;
      this._userProfileService.saveUserProfile(this._mbService.user.CustomerId, JSON.stringify(this._mbService.userProfile), this._mbService.userProfileAPIToken, this._mbService.userProfileTypeId);

    }
    else {
      console.log("in onStateChange, in else");
    }

    this.onSelectionComplete.emit();
  }

}
