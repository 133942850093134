import { Widget } from '../../shared/types/widget.type'
import { AdminPreferences } from '../../shared/types/AdminPreferences.type'
import { InheritableProperties } from '../../shared/types/application-settings'

export class UserProfile {
  ProfileGUIUpdated: boolean;
  Tools: Widget[];
  AdminPreferences: AdminPreferences[];
  CurrentStateId: number;
  DashboardLock: boolean;
  InheritableProperties: InheritableProperties = new InheritableProperties;
}
