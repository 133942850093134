import { Component, Provider, ElementRef, OnInit, Input, Compiler, ViewChild } from '@angular/core';
import { VirtualScrollComponent } from 'angular2-virtual-scroll';

import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { HrHeroService } from '../../shared/services/hr-hero.service'
import { Information } from '../../shared/types/information.type'

import { SafeHtmlPipe } from '../../shared/pipes/safe-html'

@Component({
  selector: 'news',
  templateUrl: './news.html',
  styleUrls:['./news.css'],
  providers: [SafeHtmlPipe]
})

export class News {

  newsArticles: Information[];

  selectedArticle: Information;
  individualArticleByURL: Information;

  details: boolean = false;
  category: string;
  topic: string;
  title: string;
  newsLoading: boolean = false;

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private router: Router,
    private _heroService: HrHeroService
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      let url = '/news/';

      if (params['category'] != null) {
        url = url + params['category'];
      }
      if (params['topic'] != null) {
        url = url + "/" + params['topic'];
      }
      if (params['title'] != null) {
        url = url + "/" + params['title'];
      }
      if (this.newsArticles == null) {
          this.newsLoading = true;
          this._heroService.getAllInformationDesc(1).then(information => {

              if (information != null) {
                  this.newsArticles = information;
                
                  if (this.newsArticles.find(w => w.Url == url || w.Url == url + '/') != null) {
                      this.viewArticle(this.newsArticles.find(w => w.Url == url || w.Url == url + '/'));
                    window.scrollTo(0, 0);
                  }
                  else if (url != '/news/' && url != '/news' && url != 'news/') {
                    this._heroService.getInformationByUrl(url).then(inf => {
                      this.individualArticleByURL = inf;
                      this.viewArticle(this.individualArticleByURL);
                      window.scrollTo(0, 0);
                      this.newsLoading = false;
                    });
                  }
                  this.newsArticles.forEach(info => {
                      if (info.Thumbnail != null) {
                          info.Thumbnail = info.Thumbnail.replace("http:", "https:");
                      }
                  });
                this.newsLoading = false;
              }

          });
      }
      else {
          this.viewArticle(this.newsArticles.find(w => w.Url == url || w.Url == url + '/'));
          window.scrollTo(0, 0);
      }
    });
  }

  changeArticle(info: Information) {
      this.router.navigate([info.Url]);
  }

  viewArticle(info: Information) {
   // this.router.navigate([info.Url]);
    this.selectedArticle = info;
    this.details = true;
  }

  next() {

    let index = this.newsArticles.findIndex(item => item.InformationId == this.selectedArticle.InformationId)

    if (this.newsArticles.length > index) {
      let next = index + 1;
      var currentTarget = document.getElementById(this.newsArticles[index].InformationId.toString());
      var nextTarget = document.getElementById(this.newsArticles[next].InformationId.toString());
      this.animate(document.getElementById('articleScroll'), "scrollTop", "", currentTarget.offsetTop, nextTarget.offsetTop, 250, true);

      this.viewArticle(this.newsArticles[next]);
      
      window.scrollTo(0, 0);
    }
  }

  previous() {

    let index = this.newsArticles.findIndex(item => item.InformationId == this.selectedArticle.InformationId)

    if (index != 0) {
      let next = index - 1;
      var currentTarget = document.getElementById(this.newsArticles[index].InformationId.toString());
      var nextTarget = document.getElementById(this.newsArticles[next].InformationId.toString());
      this.animate(document.getElementById('articleScroll'), "scrollTop", "", currentTarget.offsetTop, nextTarget.offsetTop, 250, true);

      this.viewArticle(this.newsArticles[next]);

      window.scrollTo(0, 0);
    }
  }

  animate(elem, style, unit, from, to, time, prop) {

    if (!elem) {
      return;
    }
    var start = new Date().getTime(),
      timer = setInterval(function () {
        var step = Math.min(1, (new Date().getTime() - start) / time);
        if (prop) {
          elem[style] = (from + step * (to - from)) + unit;
        } else {
          elem.style[style] = (from + step * (to - from)) + unit;
        }
        if (step === 1) {
          clearInterval(timer);
        }
      }, 25);
    if (prop) {
      elem[style] = from + unit;
    } else {
      elem.style[style] = from + unit;
    }
  }
}
