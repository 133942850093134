
<div id="zenDesk"></div>

<div class="options">

  <div class="section group row">
    <div class="col col1o3 col-md-4"><img src="../assets/images/hrhotline/HRHotline_emailicon.png" alt="" /><br /><strong>Email</strong></div>
    <div class="col col2o3 col-md-4">Submit your question through email, and we will get back to you within one (1) business day on average.* Questions are guaranteed to be answered within three (3) business days.</div>
    <div class="col col3o3 col-md-4" *ngIf="!isLimitedAccess"><a class="btn-green" href="mailto:hrhotline@blr.com?body=Please%20enter%20the%20following%20information%20to%20help%20our%20team%20better%20assist%20you%20with%20your%20question%3A%0D%0A%0D%0ATopic%3A%0D%0A%0D%0AJurisdiction%20%28State%29%3A%0D%0A%0D%0AQuestion%3A">Email Now</a></div>
    <div class="col col3o3 col-md-4" *ngIf="isLimitedAccess"><a class="btn-green" (click)="openModal(modal)"><i class="fas fa-lock"></i>&nbsp;&nbsp;Email Now</a></div>
  </div>
</div>
<div class="standardText" style="text-align: center; padding-top:10px; padding-bottom:20px;">
  A "single" question pertains to one subject and does not require a multipart answer. Questions are answered within one (1) business day on average* and are guaranteed to be answered within three (3) business days. For more information on the Terms and Conditions governing BLR's HR Hotline service, see <br/><a href="http://www.blr.com/About/Legal-Terms">http://www.blr.com/About/Legal-Terms</a>.<br/><br/>*based on typical HR Hero subscriber response time for submitted HR Hotline questions.

</div>

<!--bookmark modal-->
<ng-template #modal>
  <div class="modal-header modal-header-hr">
    Limited Access
  </div>
  <div class="modal-body">
    <div class="body-content">

      <h1>
        Full access to the HR Hotline is reserved for HR Hero subscribers.
      </h1>

      <div>
        <a href="http://store.blr.com/hrhero" target="_blank">Upgrade now</a> or <a href="http://interactive.blr.com/hrhero-demo-request" target="_blank">request a demo</a> for immediate access to a team of employment law attorneys, available through phone, chat, or email to answer your most pressing questions.
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="closeModal()">Close</button>
  </div>
</ng-template>
