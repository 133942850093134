import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { SafeHtmlPipe } from "../../shared/pipes/safe-html";
import { MembershipDataService } from "../../shared/services/membership.data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TrainingAllModel } from "../../shared/types/training/training-all";
import { TrainingInformation } from "../../shared/types/Training/training-information";
import { HrHeroService } from '../../shared/services/hr-hero.service';
import { DataTableDirective } from 'angular-datatables';

import { Subject} from 'rxjs';
import { setuid } from 'process';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'all-training-component',
  templateUrl: './all-training.component.html',
  styleUrls: ['./all-training.component.css'],
  providers: [SafeHtmlPipe]
})

export class RebuiltTrainingAllComponent implements OnDestroy, OnInit {

  resourceItems: TrainingAllModel;
  resourceMaster: TrainingInformation[];
  supportingDocuments: TrainingInformation[];
  loading: boolean = false;
  fromDate: Date = null;
  toDate: Date = null;
  informationSelected: any = null;
  tags: any = [];
  showBody: boolean = true;
  pageNumber: number = 1;

  langCollapsed: boolean = false;
  topicsCollapsed: boolean = false;
  dtCollapsed: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  trainingInformation: TrainingInformation[];

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    private router: Router,
    private _heroService: HrHeroService,
    private _mbService: MembershipDataService,


  ) { }

  ngOnInit() {

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      searching: false,
      ordering: true,
      order: [[1,"asc"]],
      info: false,
      autoWidth: false,
      lengthMenu:[15,25,50],
      language : {
        lengthMenu: '_MENU_',
        paginate: {
              first: '<<',
              last: '>>',
              next: '>',
              previous: '<'
          }
        },
        dom:  '<"input-group col-md-3"<"col pull-left" f>><"" t<"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
    };

    if (this._mbService.getSettingValue("Training").toLowerCase() == 'false')
      this.router.navigate(["error/404"]);
    this.route.params.subscribe(params => {

      this.loading = true;
      let presetTopic = params["topic"];

      this._heroService.getAllTraining().then(data => {

        if (data != null) {

          this.resourceItems = data;
          this.trainingInformation = this.eliminateDuplicates(this.resourceItems.Information);

          this.overrideTypeNames();
          this.suppressInvalidTypes();
          this.resourceMaster = this.trainingInformation;
          this.loading = false;
          this.rerender();
          
          if(presetTopic == null){
            let languages = this.route.snapshot.queryParams['languages']?.split(',');
            let docTypes = this.route.snapshot.queryParams['docTypes']?.split(',').map(Number);
            let topics = this.route.snapshot.queryParams['topics']?.split(',').map(Number);

            if((languages!=null && languages.length>0) || (docTypes!=null && docTypes.length>0) || (topics!=null && topics.length>0))
            {        
              if (languages != null && languages.length>0) {
                  this.resourceItems.Languages.filter(f => languages.includes(f.Language)).forEach((item) => {
                      this.filterLanguages(item.Language, true, item.Language,false,false)});
              }
              if (docTypes!= null && docTypes.length>0) {
                  this.resourceItems.DocumentTypes.filter(f => docTypes.includes(f.InformationTypeId)).forEach((item) => {
                      this.filterDocumentTypes(item.InformationTypeId, true, item.InformationTypeName,false)});
              }
              if (topics != null && topics.length>0) {
        
                  this.resourceItems.Topics.filter(f => topics.includes(f.TopicId)).forEach((item) => {
                      this.filterTopic(item.TopicId, true, item.TopicName,false)});
              }
        
              this.saveFilters();
              this.filter();
            }
            else
            {
                let savedFilters = this._heroService.popSavedFilters("all-hr-training-filters");

                if (savedFilters != null) {

                  this.resourceItems.Topics.forEach(f => f.Checked = savedFilters.Topics.some(s => s.TopicId == f.TopicId));
                  this.resourceItems.DocumentTypes.forEach(f => f.Checked = savedFilters.DocumentTypes.some(s => s.InformationTypeId == f.InformationTypeId));
                  this.resourceItems.Languages.forEach(f => f.Checked = savedFilters.Languages.some(s => s.Language == f.Language));

                  this.resourceItems.Topics.forEach(f => {
                    if (savedFilters.Topics.some(s => s.TopicId == f.TopicId)) {
                      this.tags.push({ Id: f.TopicId, Filter: "Topic", Name: f.TopicName })
                    }
                  });

                  this.resourceItems.DocumentTypes.forEach(f => {
                    if (savedFilters.DocumentTypes.some(s => s.InformationTypeId == f.InformationTypeId)) {
                      this.tags.push({ Id: f.InformationTypeId, Filter: "DocumentType", Name: (f.InformationTypeName == "Toolbox Talks" ? "Training Talks" : f.InformationTypeName ) })
                    }
                  });

                  this.resourceItems.Languages.forEach(f => {
                    if (savedFilters.Languages.some(s => s.Language == f.Language)) {
                      this.tags.push({ Id: f.Language, Filter: "Language", Name: f.Language })
                    }
                  });

                  this.filter();
                  this.setAllUrl(savedFilters.Languages.map(function(item){return item.Language;}).toString(),
                  savedFilters.DocumentTypes.map(function(item){return item.InformationTypeId;}).toString(),
                  savedFilters.Topics.map(function(item){return item.TopicId;}).toString());

                }
            }
          }else{
            let topicName = "";
            this.resourceItems.Topics.filter(f => f.TopicId == presetTopic).forEach((item) => {
              item.Checked = true;
              topicName = item.TopicName;
            });
            this.filterTopic(presetTopic,true,topicName,true);
          }
          this.filterSelections(8);
        }
      });

    });
  }


  setAllUrl(languages,docTypes,topics)
  { 
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { languages: languages?languages:null, docTypes:docTypes?docTypes:null, topics:topics?topics:null},
        queryParamsHandling: 'merge'
      });
  }  
  
  setTopicsUrl(topics)
  {
   
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: {topics: topics?topics:null},
        queryParamsHandling: 'merge'
      });
  }
  setDocTypesUrl(docTypes)
  {     
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: {docTypes: docTypes?docTypes:null},
        queryParamsHandling: 'merge'
      });
  }

  setLanguagesUrl(languages)
  {
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: {languages: languages?languages:null},
        queryParamsHandling: 'merge'
      });
  }

  anyDocumentTypeChecked() {
    return (this.resourceItems.DocumentTypes.length > this.resourceItems.DocumentTypes.filter(a => a.Checked).length);
  }

  anyTopicChecked() {
    return (this.resourceItems.Topics.length > this.resourceItems.Topics.filter(a => a.Checked).length);
  }

  anyLanguageChecked() {
    return (this.resourceItems.Languages.length > this.resourceItems.Languages.filter(a => a.Checked).length);
  }

  clearAll() {
    this.tags = [];

    this.resourceItems.Topics.forEach((item) => {
      item.Checked = false;
    });
    this.resourceItems.DocumentTypes.forEach((item) => {
      item.Checked = false;
    });
    this.resourceItems.Languages.forEach((item) => {
      item.Checked = false;
    });
    this.setAllUrl(null,null,null);
    this.route.params.subscribe(params => {

      this.loading = true;

      this._heroService.getAllTraining().then(data => {

        if (data != null) {

          this.resourceItems = data;
          this.trainingInformation = this.eliminateDuplicates(this.resourceItems.Information);
          this.overrideTypeNames();
          this.suppressInvalidTypes();
          this.resourceMaster = this.trainingInformation;
          this.loading = false;

          this.filter();
          this.filterSelections(8);
        }
      });

    });
  }


  getPage(page: number) {

    this.pageNumber = page;

  }

  filterTopic(topicId, checked, name, refresh, setUrl=true) {
    this.resourceItems.Topics.filter(f => f.TopicId == topicId || topicId == 'all').forEach((item) => {
      item.Checked = checked;
    });

    if (topicId !== "all")
      if (checked) this.tags.push({ Id: topicId, Filter: "Topic", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === topicId && item.Filter === "Topic"));
      if (refresh)
      {
        this.filter();
        this.filterSelections(4);
      }

    if(setUrl)
    {
      this.setTopicsUrl(this.resourceItems.Topics.reduce((a, b) => { if (b.Checked) return a + b.TopicId + ","; else return a; }, "").slice(0, -1)); 
    }
    
  }


  filterDocumentTypes(documentTypeId, checked, name, refresh, setUrl=true) {
    console.log(setUrl)
    this.resourceItems.DocumentTypes.filter(f => f.InformationTypeId == documentTypeId || documentTypeId == 'all').forEach((item) => {
      item.Checked = checked;
    });
    if (documentTypeId !== "all")
      if (checked) this.tags.push({ Id: documentTypeId, Filter: "DocumentType", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === documentTypeId && item.Filter === "DocumentType"));
      if (refresh)
      {
        this.filter();
        this.filterSelections(5);
      }
    if(setUrl)
    {
      this.setDocTypesUrl(this.resourceItems.DocumentTypes.reduce((a, b) => { if (b.Checked) return a + b.InformationTypeId + ","; else return a; }, "").slice(0, -1)); 
    }
  }

  filterLanguages(language, checked, name,refresh, seturl=true) {
    console.log(language);
    console.log(name);
    this.resourceItems.Languages.filter(f => f.Language == language || language == 'all').forEach((item) => {
      item.Checked = checked;
    });
    if (language !== "all")
      if (checked) this.tags.push({ Id: language, Filter: "Language", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === language && item.Filter === "Language"));
      if (refresh)
      {
        this.filter();
        this.filterSelections(6);
      }
    if(seturl)
      this.setLanguagesUrl(this.resourceItems.Languages.reduce((a, b) => { if (b.Checked) return a + b.Language + ","; else return a; }, "").slice(0, -1)); 

  }

  filter() {
    this.trainingInformation = this.getFilterResult("");
    this.saveFilters();
    this.rerender();
  }

  getFilterResult(section) {
    return this.resourceMaster.filter(item => (
      (this.resourceItems.Topics.every(f => (!f.Checked)) || this.resourceItems.Topics.some(f => (f.TopicId == item.TopicId && f.Checked)) || section == 'topics') &&
      (this.resourceItems.DocumentTypes.every(f => (!f.Checked)) || this.resourceItems.DocumentTypes.some(f => (f.InformationTypeId == item.DocumentTypeId && f.Checked)) || section == 'documentTypes') &&
      (this.resourceItems.Languages.every(f => (!f.Checked)) || this.resourceItems.Languages.some(f => (f.Language == item.Language && f.Checked)) || section == 'languages'))  
    );
    //return this.eliminateDuplicates(withDupes);
  }
  eliminateDuplicates(recordArray){
    let resultSet = new Set();
    let cleaned = recordArray.filter(function(record){
      let realId = record.InformationChildId;
      let isNew = !resultSet.has(realId);
      if(isNew){
        resultSet.add(realId);
      }
      return isNew;
    });
    return cleaned;
  }
  overrideTypeNames(){
      for(let i = 0; i < this.trainingInformation.length; i++){
        let info = this.trainingInformation[i];
        if(info.DocumentTypeName == "Toolbox Talks"){
          info.DocumentTypeName = "Training Talks";
        }
      }
  }
  suppressInvalidTypes(){
    this.trainingInformation = this.trainingInformation.filter(rec => {
      return rec.DocumentTypeName != "Training Resources";
    });
  }

  clearTag(tag) {
    switch (tag.Filter) {
      case "Topic": this.filterTopic(tag.Id, false, tag.Name,true); break;
      case "DocumentType": this.filterDocumentTypes(tag.Id, false, tag.Name, true); break;
      case "Language": this.filterLanguages(tag.Id, false, tag.Name, true); break;
    }
  }

  filterSelections(selector) {

    if (selector != 4) {
      this.resourceItems.Topics.forEach(obj => {
        this.resourceItems.Topics.filter(f => f.TopicId == obj.TopicId)[0].Count = this.getFilterResult('topics').filter(f => f.TopicId == obj.TopicId).length;
      });
    }
    if (selector != 5) {
      this.resourceItems.DocumentTypes.forEach(obj => {
        this.resourceItems.DocumentTypes.filter(f => f.InformationTypeId == obj.InformationTypeId)[0].Count = this.getFilterResult('documentTypes').filter(f => f.DocumentTypeId == obj.InformationTypeId).length;
      });
    }
    if (selector != 6) {
      this.resourceItems.Languages.forEach(obj => {
        this.resourceItems.Languages.filter(f => f.Language == obj.Language)[0].Count = this.getFilterResult('languages').filter(f => f.Language == obj.Language).length;
      });
    }
  }

  linkType(informationSelected) {

    if (informationSelected.FilePath == null || informationSelected.FilePath == '') {
      return 1;
    }
    else if (informationSelected.FilePath.includes(':')) {
      return 2;
    }
    else {
      return 3;
    }

  }

  openDetails(information) {
    this.saveFilters();
    //return information.Url;
    this.router.navigate([information.Url + "/" + information.InformationChildId], { relativeTo: this.route });
  }

  navigateToDetailsByUrl(url, databaseId) {
    this.saveFilters();
    //let source = (databaseId == 3) ? "safety" : "enviro";
    this.router.navigate([url], { relativeTo: this.route });
  }

  saveFilters() {
    var filters = new TrainingAllModel();
    filters.Topics = this.resourceItems.Topics.filter(f => f.Checked);
    filters.DocumentTypes = this.resourceItems.DocumentTypes.filter(f => f.Checked);
    filters.Languages = this.resourceItems.Languages.filter(f => f.Checked);
    this._heroService.saveResouceFilters("all-hr-training-filters", filters);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    if(this.dtElement && this.dtElement.dtInstance)
    {           
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });  
        
      }  
      else if (this.dtElement)
      {      
          this.dtTrigger.next();     
      }
    }
}
