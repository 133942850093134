import { Component, Provider, ElementRef, OnInit, TemplateRef } from '@angular/core';
//import { BsModalComponent } from 'ng2-bs3-modal';
import { BsModalService , BsModalRef } from 'ngx-bootstrap/modal';

import { MembershipDataService } from '../../../../app/shared/services/membership.data.service'

@Component({
  selector: 'widget-tools-slcb',
  styles: ['>>> .modal-xl { width: 1000px; }'],
  templateUrl: './widget-tools-slcb.html'
})

export class WidgetToolsSLCB implements OnInit {
  modal: BsModalRef;
  isLimitedAccess: boolean;
  hasApplication: boolean = false;

  constructor(private _mbService: MembershipDataService, private modalService: BsModalService) { }

  ngOnInit() {

    this.isLimitedAccess = this._mbService.isLimitedAccess();

    //if (this.isLimitedAccess != true) {
      this.hasApplication = this._mbService.hasSLCB;
    //}

  }

  navigate() {

    if (this._mbService.isAuthenticated()) {
      location.href = "/state-law-chart-builder";
    } else {
      location.href = "/login";
    }

  }
    openModal(template: TemplateRef<any>) {
    this.modal = this.modalService.show(template);
  }

  closeModal()  {
    this.modal.hide();
  }
}
