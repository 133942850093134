<style>
    #loginForm {
      margin: 0 auto;
      text-align: center;
    }
  </style>
  
  <div class="container" >
    <div class="row">
      <div class="col-md-6 col-md-offset-3">
  
        <div id="loginForm">
  
          <div style="text-align:center;">
            <h1>Login</h1>
            <h4>Please login to HR Essentials Online</h4>
          </div>
  
          <input (keyup.enter)="login(userName.value, password.value)" type="text" placeholder="Username" [value]="userNameValue" #userName class="form-control" /><br />
          <input (keyup.enter)="login(userName.value, password.value)" type="password" placeholder="Password" [value]="passwordValue" #password class="form-control" />
  
        </div>
  
        <br />
  
        <div style="text-align:center;" id="infoBox">
  
          <input class="btn btn-default" type="button" (click)="login(userName.value, password.value)" value="Login" style="width:100px;margin-bottom: 10px;" id="btnSubmit" />
          <br />
          <div class="error" style="color:red;">{{loginResponse}}</div>
          <br />
          <b>Forgot your password?</b>
          <br />
          Use our <a href="https://account.blr.com/ForgotPassword_SN.aspx" target="_blank">password recovery feature</a> to have it emailed to you.
          <br />
  
        </div>
  
        <div style="text-align:center; visibility:hidden;" class="imgloader">
  
          <h1>Loading</h1>
  
          <span ><img style="margin-left:5px; height:60px;width:60px;" src="../../assets/images/loading-dots.gif" /></span>
  
        </div>
        
      </div>
    </div>
  </div>
  
  <!--<a (click)="iframeClick()">login iframe test</a>
  
  <iframe id="myiFrame" data-src="http://my.url" src="about:blank" (onLoad)="logIframeEvent(this.contentWindow.location);"></iframe>-->
  