import { Component, Provider, ElementRef, OnInit, Input, Compiler, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { HrHeroService } from '../../shared/services/hr-hero.service'
import { Information } from '../../shared/types/information.type'

@Component({
  templateUrl: './details.html',
  styleUrls: ['./details.css']
})

export class TrainingDetailsComponent {

  information: Information[];
  informationSelected: Information = null;
  supportingDocuments: Information[];


  languageId: string = null;
  categoryId: number = 0;
  topicId: number = 0;
  informationId: number = 0;
  initialLaunch: number = 1;

  loadSelectors: boolean = false;

  @ViewChild('ctlComponent') ctlComponent;

  constructor(
    public _route: ActivatedRoute,
    public _router: Router,
    private _heroService: HrHeroService,
    private _location: Location
  ) { }

  ngOnInit() {

    this._route.params.subscribe(params => {

      if (params["id"] != null) {

        this.selectInformationByInfoId(params["id"]);

      }else if (params["category"] != null && params["topic"] != null && params["title"] != null) {

        this.selectInformationByURLAll('training/' + params["type"], params["category"], params["topic"],  params["title"], null);

      }

    });
  }

  selectInformationByURL(type, category, topic, page) {

    this._heroService.getInformationByUrlParts(type, category, topic, page).then(information => {

      if (information != null) {
        
        this.informationSelected = information;

        this.getSupportingDocuments(this.informationSelected.InformationId);

        //set selector values
        this.languageId = this.informationSelected.Language;
        this.categoryId = this.informationSelected.CategoryId;
        this.topicId = this.informationSelected.TopicId;

        this.loadSelectors = true;

      } else {
       // this._router.navigate(['/error/404']);
      }

    });

  }
  selectInformationByURLAll(type, category, topic, page, infoId) {
    //this shouldn't happen anymore- should just be by i
    /*this._heroService.getTrainingByUrlParts(type, category, topic, null, page, 1, infoId).then(information => {

      if (information != null) {

        this.informationSelected = information.filter(f => f.IsDetail)[0];
        this.supportingDocuments = information.filter(f => f.IsChild && !f.IsDetail);

      } else {
        // this._router.navigate(['/error/404']);
       }

    });*/

  }
  selectInformationByInfoId(infoId){
    this._heroService.getTrainingByInfoId(infoId).then(information => {

      if (information != null) {

        this.informationSelected = information;//.filter(f => f.IsDetail)[0];
        //this.supportingDocuments = information;//.filter(f => f.IsChild && !f.IsDetail);
        this.informationId = infoId;
        this._heroService.getTrainingSupportingDocuments(infoId).then(docs => {
          this.supportingDocuments = this.eliminateDuplicates(docs);          
        });
        if (this.informationSelected.InformationTypeId == 11)
          this.informationSelected.FilePath = this.informationSelected.FilePath.replace("http://hr.blr.com/hr_docs/","");
          this.informationSelected.FilePath = this.informationSelected.FilePath.replace("https://hr.blr.com/hr_docs/","");
          this.informationSelected.FilePath = "/hr-audio/" + this.informationSelected.FilePath;
      } else {
        // this._router.navigate(['/error/404']);
       }

    });
  }
  eliminateDuplicates(recordArray){
    let resultSet = new Set();
    let cleaned = recordArray.filter(function(record){
      let realId = record.ChildInformationId;
      let isNew = !resultSet.has(realId);
      if(isNew){
        resultSet.add(realId);
      }
      return isNew;
    });
    return cleaned;
  }

  getSupportingDocuments(infoId) { //unused
    this._heroService.getInformationByType(infoId, 'child-docs').then(ts => {

      this.supportingDocuments = ts;

      console.log("sp", this.supportingDocuments);

    });
  }

}
