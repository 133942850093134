import { Directive, HostListener, ElementRef } from "@angular/core";
import { Router } from '@angular/router';
@Directive({ selector: '[dynamicAnchor]' })
export class DynamicAnchorDirective {
    constructor(private el: ElementRef,private router: Router) { }
    @HostListener('click', ['$event']) onClick($event){        
        
       // $event.stopPropagation();
        if ($event.target instanceof HTMLAnchorElement) {
            const element = $event.target as HTMLAnchorElement;
            const route = element?.getAttribute('href');
            if(/^#/.test(route)) 
            {
                $event.preventDefault();
                this.scrollToElement(route);
                
            }
        }
    }
    scrollToElement(element): void {
        let elementToScroll = this.el.nativeElement.querySelector(element);
        if(elementToScroll)
        {
            elementToScroll.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
      }
}