import { Component, OnInit, OnDestroy,  ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';

import { Information } from '../../shared/types/information.type'
import { InformationAllModel } from '../../shared/types/information-all.type'

import { HrHeroService } from '../../shared/services/hr-hero.service'
import { MembershipDataService } from '../../shared/services/membership.data.service'
import { Subject } from 'rxjs';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'compliance-dates',
    templateUrl: './compliance-dates.html',
    styleUrls: ['./compliance-dates.css']
})

export class ComplianceDates implements OnDestroy, OnInit {

    topicsCollapsed: boolean = false;

    documentTypeIds = 44;
    complianceDates: InformationAllModel;
    
    filters: InformationAllModel;
    information: Information[];
    informationCounts: Information[];

    tags: any = [];
    fromDate: String;
    toDate: String;  

    loading: boolean = true;
    filterKey: string = "all-hr-compliance-dates-filters";

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();
    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;

    constructor(public _route: ActivatedRoute, public _router: Router, private _heroService: HrHeroService, private _mbService: MembershipDataService) { }

    ngOnInit() {

        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 15,
            searching: false,
            ordering: true,
            order: [[2,"desc"]],
            info: false,
            lengthMenu:[15,25,50], 
            language : {
              emptyTable: "No results found to match your search criteria. Please adjust your filters and try again",    
              lengthMenu: '_MENU_',
              paginate: {
                    first: '<<',
                    last: '>>',
                    next: '>',
                    previous: '<'
                }
              },
              columnDefs:[{ targets: [2], type:'date' }],
              dom:  '<"input-group col-md-3"<"col pull-left" f>><"" t<"row dt-length-menu-margin"<"col pull-left" p><"col pull-right" l>>>'
            };

        this.getComplianceDatesData();
    }

    getComplianceDatesData() {
        this._heroService.getComplianceDates(this.documentTypeIds, this._mbService.states).then(information => {
            this.complianceDates = information;
            this.loading = false;
            this.loadFilters();
            this.filterInformation();
        });
    }

    loadFilters(){
        this.filters = this._heroService.popSavedFilters(this.filterKey);
        if (this.filters === null)
        {
            this.filters = this.complianceDates;
            this.filters.FromDate = null;
            this.filters.ToDate = null;
            this.toDate = null;
            this.fromDate = null;
            this.filters.PubDateCategory = { Recent: false, Upcoming: false, All: true, selectedFilter: 'none' };
        }
        else
        {
            if ((this.filters.FromDate) && (this.filters.FromDate !== null)){
                this.fromDate = this.formatDateforDatePicker(this.filters.FromDate);
                
            }

            if ((this.filters.ToDate) && (this.filters.ToDate !== null)){
                this.toDate = this.formatDateforDatePicker(this.filters.ToDate);
            }

            this.filters.Topics.filter(f=>f.Checked).forEach((item)  => {
                this.tags.push({ Id: item.TopicId, Filter: "Topic", Name: item.TopicName });
            });

            this.filters.Jurisdictions.filter(f=>f.Checked).forEach((item)  => {
                this.tags.push({ Id: item.JurisdictionId, Filter: "State", Name: item.JurisdictionName });
            });
        }
    }

    filterInformation(){
        var topicFiltered = this.filters.Topics.some(f=>f.Checked);
        var stateFiltered = this.filters.Jurisdictions.some(f=>f.Checked);
       
        var chkFromDate = null;
        var chkToDate = null;
        if ((this.filters.FromDate) && (this.filters.FromDate !== null)){
            chkFromDate = this.formatDate(this.filters.FromDate);
        }

        if ((this.filters.ToDate) && (this.filters.ToDate !== null)){
            chkToDate = this.formatDate(this.filters.ToDate);
        }

        this.informationCounts = this.complianceDates.Information.filter(item => (
            ((!topicFiltered) || (this.filters.Topics.some(f => (f.TopicId == item.TopicId && f.Checked)))) &&
            ((!stateFiltered) || (this.filters.Jurisdictions.some(f => (f.JurisdictionId == item.JurisdictionId && f.Checked)))) 
            ));
        
        this.information = this.informationCounts.filter(item => (
            ((chkFromDate === null) || (item.PubDate >= chkFromDate)) &&
            ((chkToDate === null) || (item.PubDate <= chkToDate))
            ));
        
        if (this.filters.PubDateCategory.selectedFilter !== 'none')
        {
            this.setFilterCount();
        }
        this.saveFilters();
        this.rerender();
    }

    saveFilters(){
        this._heroService.saveResouceFilters(this.filterKey, this.filters);
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        var dt = [year, month, day].join('-') + 'T00:00:00';
        return dt;
    }

    formatDateforDatePicker(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [month, day, year].join('/');
    }

    setFilterCount(){
        if (this.filters.PubDateCategory.selectedFilter !== 'states'){
            this.filters.Jurisdictions.forEach((item)  => {
                item.Count = this.information.filter(f => f.JurisdictionId === item.JurisdictionId).length;
            });
        }
        
        if (this.filters.PubDateCategory.selectedFilter !== 'topics'){
            this.filters.Topics.forEach((item)  => {
                item.Count = this.information.filter(f => f.TopicId === item.TopicId).length;
            });
        }

            this.filters.AllTimeTotal = this.informationCounts.length;
            
            var currentDate = this.formatDate(new Date());
            var newFromDate = this.formatDate(new Date().setDate(new Date().getDate() - 45));
            this.filters.NewItems = this.informationCounts.filter(f => f.PubDate >= newFromDate && f.PubDate <= currentDate).length;
            this.filters.Upcoming = this.informationCounts.filter(f => f.PubDate >= currentDate).length;
    }

    clearAll() {

        this.tags = [];
        this._heroService.removeSavedFilters(this.filterKey);
        this.fromDate = null;
        this.toDate = null;
        this.getComplianceDatesData();
    }

    openDetails(information) {
        this.saveFilters();
        var url = this.getRelativeURL(information.FilePath);
        this._router.navigate([url], { relativeTo: this._route });
    }

    getRelativeURL(url : string) : string {
        var index = url.indexOf("/hr/") + 3;
        var relativeURL = url.substring(index, url.length);
        return relativeURL;
    }

    filterTopic(topicId, checked, name) {
        this.filters.Topics.filter(f => f.TopicId == topicId || topicId == 'all').forEach((item) => {
            item.Checked = checked;
        });

        if (topicId !== "all"){
            if (checked) this.tags.push({ Id: topicId, Filter: "Topic", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === topicId && item.Filter === "Topic"));
        }
        this.filters.PubDateCategory.selectedFilter = 'topics';
        this.filterInformation();
    }

    filterState(jurisidictionId, checked, name) {
        this.filters.Jurisdictions.filter(f => f.JurisdictionId == jurisidictionId || jurisidictionId == 'all').forEach((item) => {
            item.Checked = checked;
        });

        if (jurisidictionId !== "all"){
            if (checked) this.tags.push({ Id: jurisidictionId, Filter: "State", Name: name }); else this.tags = this.tags.filter(item => !(item.Id === jurisidictionId && item.Filter === "State"));
        }
        this.filters.PubDateCategory.selectedFilter = 'states';
        this.filterInformation();
    }

    filterByWhen(type: string, checked) {
        this.filters.PubDateCategory.Recent = false;
        this.filters.PubDateCategory.Upcoming = false;
        this.filters.PubDateCategory.All = false;

        if (type === 'recent'){
          this.filters.PubDateCategory.Recent = true;
          this.filters.FromDate = new Date();
          this.filters.FromDate.setDate(new Date().getDate() - 45);
          this.filters.ToDate = new Date();
          this.fromDate = null;
          this.toDate = null;
        }
        else if (type === 'upcoming')
        {
            this.filters.PubDateCategory.Upcoming = true;
            this.filters.FromDate = new Date();
            this.filters.ToDate = null;
            this.fromDate = null;
            this.toDate = null;
        }
        else
        {
            this.filters.PubDateCategory.All = true;
            this.filters.FromDate = null;
            this.filters.ToDate = null;
        }
        this.filters.PubDateCategory.selectedFilter = 'when';
        this.filterInformation();
    }

    filterByDate(fromDate: Date, toDate: Date) {
        this.filters.PubDateCategory.Recent = false;
        this.filters.PubDateCategory.Upcoming = false;
        this.filters.PubDateCategory.All = true;
        this.filters.FromDate = fromDate;
        this.filters.ToDate = toDate;
        this.filters.PubDateCategory.selectedFilter = 'date';
        this.filterInformation();
    }


    clearTag(tag) {
        switch (tag.Filter) {
            case "Topic": this.filterTopic(tag.Id, false, tag.Name); break;
            case "State": this.filterState(tag.Id, false, tag.Name); break;
        }
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
      }

      rerender(): void {
        if(this.dtElement && this.dtElement.dtInstance)
        {           
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.dtTrigger.next();
              });  
            
          }  
          else if (this.dtElement)
          {      
              this.dtTrigger.next();     
          }
        }
}